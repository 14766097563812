import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Skeleton,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Menu,
  MenuItem,
  Divider,
  ListItem,
  Fade,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  FilterAlt,
  Search,
  ArrowUpward,
  ArrowDownward,
  MoreVert,
} from "@mui/icons-material";
import { TabContext, TabPanel } from "@mui/lab";
import { useDispatch } from "react-redux";
import { getAllWatchlist } from "../../redux/wishlists/wishlistSlice";
import { colors } from "../../constants/colors";
import getInitials from "../../utils/getInitailasIMGUtils";
import { useNavigate } from "react-router-dom";
import { getFDById, getFDSchemeInfo } from "../../redux/FD/FDSlice";
import FDInvestModal from "../../components/modals/FDRequestModal";
import MFInvestModal from "../../components/modals/investModal";
import toast from "react-hot-toast";
import { getFundInfo, getMFundById } from "../../redux/MF/MFSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import APIloader from "../../components/AppLoader/APIloader";

interface InputData {
  pageIndex: number;
  pageSize: number;
  query: string;
  type: string;
  tenure: string;
}

interface Plan {
  name: string;
  assetType: string;
  price: any;
  changeInPrice: number;
}

interface SortConfig {
  key: keyof Plan;
  direction: "ascending" | "descending";
}

const WishlistFund = () => {
  const dispatch = useDispatch<any>();
  const [alignment, setAlignment] = useState<string>("1");
  const [loading, setLoading] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = useState<null | HTMLElement>(null);
  const openMenuMD = Boolean(anchorMD);
  const [plans, setPlans] = useState<Plan[]>([]);
  const [searchFund, setSearchFund] = useState<string>();
  const [inputData, setInputData] = useState<InputData>({
    pageIndex: 1,
    pageSize: 100,
    query: "",
    type: "",
    tenure: "",
  });
  const navigate = useNavigate();
  const [sortedPlans, setSortedPlans] = useState<Plan[]>([]);
  const [sortConfig, setSortConfig] = useState<SortConfig>({
    key: "name",
    direction: "ascending",
  });
  const [fundData, setFundData] = useState<any>();
  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);
  const [isFDInvestModalOpen, setFDInvestModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [currentPlan, setCurrentPlan] = useState<Plan | undefined>(undefined);

  const fetchPlans = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getAllWatchlist());

      console.log("response watchlist", response.payload);

      const data: Plan[] = response.payload;

      if (!data || data.length === 0) {
        throw new Error("No plans available");
      }

      setPlans(data);
      setSortedPlans(data);
      setError(null);
      setLoading(false);
    } catch (err: any) {
      setError(err.message);
      setLoading(false);
    } 
  };

  useEffect(() => {
    fetchPlans();
  }, []);

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSort = (key: keyof Plan) => {
    let direction: "ascending" | "descending" = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });

    const sorted = [...plans].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "ascending" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "ascending" ? 1 : -1;
      }
      return 0;
    });

    setSortedPlans(sorted);
  };

  const modalMenuClick = (event: React.MouseEvent<HTMLElement>, plan: Plan) => {
    setAnchorMD(event.currentTarget);
    setCurrentPlan(plan);
  };
  const handleCloseMenuMD = () => {
    setAnchorMD(null);
  };

  const FundTransations = async (obj: any) => {
    console.log("object", obj);
    handleCloseMenuMD();
    try {
      if (obj.assetType === "FD") {
        const response = await dispatch(getFDSchemeInfo({ schemeId: obj.itemId }));
        console.log("response", response);

        if (response.payload) {
          setFundData(response.payload);
          openFDInvestModal();
        } else {
          toast.error("Failed to fetch FD data");
        }
      } else if (obj.assetType === "Mutual Fund") {
        setLoading(true);
        const promises: Promise<any>[] = [];

        if (obj?.fundIsin) {
          promises.push(dispatch(getFundInfo({ isin: obj?.fundIsin })));
        }

        if (obj?.itemId) {
          promises.push(dispatch(getMFundById({ fundId: obj?.itemId })));
        }

        const responses = await Promise.all(promises);

        responses.forEach((response) => {
            const data = unwrapResult(response);
            setFundData(data);
            setLoading(false);
            openMFInvestModal();
        });
      }
    } catch (error) {
      toast.error("An error occurred. Please retry.");
      setLoading(false);
    }
  };

  const openMFInvestModal = () => {
    setIsInvestModalOpen(true);
    setFDInvestModalOpen(false);
  };

  const closeMFInvestModal = () => {
    setIsInvestModalOpen(false);
  };

  const openFDInvestModal = () => {
    setIsInvestModalOpen(false);
    setFDInvestModalOpen(true);
  };

  const closeFDInvestModal = () => {
    setFDInvestModalOpen(false);
  };

  const navigateDetails = (obj: any) => {
    if (obj.assetType === "FD") {
      navigate(`/fd-details/${obj?.itemId}`);
    } else if (obj.assetType === "Mutual Fund") {
      navigate(`/mf-details/${obj?.fundIsin}/${obj?.itemId}`);
    } else if (obj.assetType === "AIF") {
      navigate(`/aif-details/${obj?.itemId}`);
    } else if (obj.assetType === "PMS") {
      navigate(`/pms-details/${obj?.itemId}`);
    }
    // window.location.reload();
  };
  console.log("plans", plans);

  useEffect(() => {
    if (searchFund) {
      const filteredPlans = plans.filter((plan) =>
        plan.name.toLowerCase()?.includes(searchFund.toLowerCase())
      );
      setSortedPlans(filteredPlans);
    } else {
      setSortedPlans(plans);
    }
  }, [searchFund]);

  return (
    <>
      <div className="">
        <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto flex flex-col md:flex-col gap-x-6">
          <h1 className="text-3xl mb-4">Watchlist</h1>
          <div className="border-b-2 border-b-darkBg w-full">
            <ToggleButtonGroup
              value={alignment}
              exclusive
              onChange={handleChange}
              aria-label="Platform"
              sx={{
                gap: "10px",
                "& .Mui-selected": {
                  color: colors.primary,
                  backgroundColor: "#e0e0e000",
                  border: "none",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                  },
                },
              }}
            >
              <div
                style={{
                  border: "none",
                  fontWeight: "600",
                  color: colors.primary,
                  padding: "10px",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "4px",
                }}
              >
                Watchlist 1
              </div>
            </ToggleButtonGroup>
          </div>
          <div>
            <div className="my-8 flex justify-between w-full">
              <TextField
                className="w-[100%]"
                onChange={(e) => {
                  setSearchFund(e.target.value);
                }}
                placeholder="Search and add"
                hiddenLabel
                id="filled-hidden-label-small"
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                }}
              />
              {/* <div className="flex justify-between w-[5%]">
                                <IconButton
                                    sx={{ backgroundColor: colors.secondary, borderRadius: 2 }}
                                    onClick={handleMenuClick}
                                    style={{ color: '#fff' }}
                                >
                                    <FilterAlt />
                                </IconButton>
                            </div> */}
            </div>
            <TabContext value={alignment}>
              <TabPanel value="1" sx={{ padding: "0" }}>
                {loading || !sortedPlans.length ? (
                  // <Skeleton
                  //     variant="rectangular"
                  //     height={200} // Adjust the height according to your needs
                  //     animation="wave"
                  //     sx={{ bgcolor: colors.lightBg }} // Customize the background color
                  // />

                  <div className="flex w-full h-[200px] justify-center items-center bg-lightBg rounded-lg">
                    <Typography variant="subtitle2">No Data Found!!</Typography>
                  </div>
                ) : (
                  <>
                    <div className="control-bar">
                      <div className="md:h-16 flex justify-between items-center bg-lightBg mb-8">
                        <div className="flex-1 flex justify-center items-center">
                          <Button
                            onClick={() => handleSort("name")}
                            sx={{ fontWeight: "bold", color: colors.primary }}
                          >
                            Name{" "}
                            {sortConfig.key === "name" ? (
                              sortConfig.direction === "ascending" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              )
                            ) : null}
                          </Button>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                          <Button
                            sx={{ fontWeight: "bold", color: colors.primary }}
                          >
                            Asset Type
                          </Button>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                          <Button
                            onClick={() => handleSort("price")}
                            sx={{ fontWeight: "bold", color: colors.primary }}
                          >
                            Price{" "}
                            {sortConfig.key === "price" ? (
                              sortConfig.direction === "ascending" ? (
                                <ArrowUpward />
                              ) : (
                                <ArrowDownward />
                              )
                            ) : null}
                          </Button>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                          <Button
                            sx={{ fontWeight: "bold", color: colors.primary }}
                          >
                            % Change
                          </Button>
                        </div>
                        <div className="flex-1 flex justify-center items-center">
                          <Button
                            sx={{ fontWeight: "bold", color: colors.primary }}
                          >
                            Action
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className="control-bar-content">
                      {sortedPlans.map((plan, index) => (
                        <Box
                          key={index}
                          className="p-4 mb-4 border border-lightGrey rounded-md"
                        >
                          <div className="flex justify-between items-center">
                            <div
                              className="flex flex-1 gap-x-4 items-center cursor-pointer "
                              onClick={() => {
                                navigateDetails(plan);
                              }}
                            >
                              <div className="w-12 h-12 mb-3 md:mb-0 md:w-12 md:h-12 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                                {getInitials(plan?.name)}
                              </div>
                              <div className="flex-1 text-left">
                                {plan.name}
                              </div>
                            </div>
                            <div className="flex-1 flex justify-center items-center text-center">
                              <div className="w-1/2 rounded-2xl bg-lightBg p-1 border border-primary">
                                {plan.assetType}
                              </div>
                            </div>
                            <div className="flex-1 text-center">
                              {plan.price}
                            </div>
                            <div className="flex-1 text-center">
                              {plan.changeInPrice}%
                            </div>
                            <div className="flex-1 text-center">
                              <IconButton
                                onClick={(event) => {
                                  modalMenuClick(event, plan);
                                }}
                              >
                                <MoreVert />
                              </IconButton>
                            </div>
                          </div>
                        </Box>
                      ))}
                    </div>
                  </>
                )}
              </TabPanel>
              <TabPanel value="2" sx={{ padding: "0" }}>
                {loading ? (
                  <Skeleton
                    variant="rectangular"
                    height={200} // Adjust the height according to your needs
                    animation="wave"
                    sx={{ bgcolor: colors.lightBg }} // Customize the background color
                  />
                ) : (
                  <div className="flex flex-col gap-y-6">Watch list 2</div>
                )}
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
      {fundData && (
        <FDInvestModal
          open={isFDInvestModalOpen}
          onClose={closeFDInvestModal}
          data={{
            FDInfo: {
              schemeID: fundData?.scheme?.id,
              schemeName: fundData?.scheme?.schemeName,
              maxInvestmentAmount: fundData?.scheme?.maxInvestmentamount,
              minInvestmentAmount: fundData?.scheme?.minInvestmentAmount,
              isHDFC: fundData?.scheme?.isHDFC,
            }, tenure: fundData?.scheme?.tenures, data: fundData?.scheme
          }}
        />
      )}
      {fundData && (
        <MFInvestModal
          open={isInvestModalOpen}
          onClose={closeMFInvestModal}
          fundInfo={fundData}
        />
      )}
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMenuMD}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#255288",
            color: "#fff",
            overflow: "hidden",
            borderRadius: "10px",
            position: "relative",
            width: "12%",
          },
        }}
        className="relative"
      >
        <div className="mx-6">
          <ListItem
            button
            onClick={() => {
              FundTransations(currentPlan);
            }}
            sx={{ marginLeft: "-25px" }}
          >
            <MenuItem>Invest</MenuItem>
          </ListItem>
          {/* <Divider sx={{ backgroundColor: "#fff" }} />
          <ListItem
            button
            onClick={() => {
              FundTransations(currentPlan);
            }}
            sx={{ marginLeft: "-25px" }}
          >
            <MenuItem>Sell</MenuItem>
          </ListItem> */}
          <Divider sx={{ backgroundColor: '#fff' }} />
          <ListItem disabled button onClick={handleCloseMenuMD} sx={{ marginLeft: '-25px' }}>
            <MenuItem>Create Alert</MenuItem>
          </ListItem>
        </div>
        <div
          className="absolute bg-white"
          style={{
            width: "20px",
            height: "20px",
            transform: "rotate(315deg)",
            bottom: "calc(100% - 10px)",
            left: "calc(50% - 10px)",
          }}
        />
      </Menu>
      <APIloader loadingModal={loading} message={""}/>
    </>
  );
};

export default WishlistFund;
