import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  Skeleton,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { colors } from "../../constants/colors";
import { BookmarkBorder, BorderAll, Download } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LineChart, PieChart } from "@mui/x-charts";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { access } from "fs";
import { getFundInfo, getMFundById } from "../../redux/MF/MFSlice";
import dayjs from "dayjs";
import { Dots } from "react-activity";
import Manger from "../../assets/images/manger.png";
import Ellipse from "../../assets/images/Ellipse.png";
import goto from "../../assets/icons/goto.png";
import companyLogo from "../../assets/images/companylogo.png";
import FilterRange from "../../components/Filters/FilterRange";
import InvestModal from "../../components/modals/investModal";
import AssetAllocation from "../../components/AssetAllocation/AssetAllocation";
import RiskRatios from "../../components/RiskRatios/RiskRatios";
import MFCalculator from "../../components/MFCalculator/MFCalculator";
import toast from "react-hot-toast";
import getInitials from "../../utils/getInitailasIMGUtils";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  addToWatchlist,
  removeToWatchlist,
} from "../../redux/wishlists/wishlistSlice";
import { FaBookmark } from "react-icons/fa";
import TimeStamptoExp from "../../utils/timestampConverter";
import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useUserData } from "../../hooks/useUserData";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SuccessfullModal from "../../components/modals/SuccessfullModal";
import { showInterest } from "../../redux/user/userSlice";
import CancelSIPModal from "../../components/modals/CancelSIPModal";

const FDDetailCard = (data: any) => {
  return (
    <>
      <div className=" py-2 flex flex-col min-w-[80px] md:min-w-[100px]">
        <div className="text-lg text-gray-700_01">{data.label}</div>
        <div className="text-lg font-medium text-gray-700_01">{data.value}</div>
      </div>
    </>
  );
};

const useFilterChartData = ({
  fundInfo,
  filter,
}: {
  fundInfo: any;
  filter: number;
}) => {
  const [filterChartData, setFilterChartData] = useState<{
    xAxis: { data: string[] }[];
    series: { data: number[] }[];
  }>({ xAxis: [], series: [] });

  const filterDataByRange = (
    data: { v: string; d: string }[],
    duration: number,
    unit: "days" | "months" | "years" | "all"
  ) => {
    const currentDate = new Date();
    const lastDate = new Date(currentDate);
    if (!data || !Array.isArray(data)) return;

    const sortedData = [...data];

    sortedData.sort(
      (a, b) => new Date(a.d).getTime() - new Date(b.d).getTime()
    );

    const earliestDate = new Date(sortedData[0].d);

    if (unit === "days") {
      lastDate.setDate(currentDate.getDate() - duration);

      while (lastDate.getDay() === 0 || lastDate.getDay() === 6) {
        lastDate.setDate(lastDate.getDate() - 1);
      }
    } else if (unit === "months") {
      lastDate.setMonth(currentDate.getMonth() - duration);
    } else if (unit === "years") {
      lastDate.setFullYear(currentDate.getFullYear() - duration);
    } else {
      lastDate.setTime(earliestDate.getTime());
    }

    if (lastDate < earliestDate) {
      lastDate.setTime(earliestDate.getTime());
    }

    const filteredData = sortedData.filter((item) => {
      const itemDate = new Date(item.d);
      return (
        itemDate >= lastDate &&
        itemDate <= currentDate &&
        itemDate.getDay() !== 0 &&
        itemDate.getDay() !== 60
      );
    });

    // Separate the d and v properties into separate arrays
    const xAxisData: string[] = [];
    const seriesData: number[] = [];

    if (unit === "all") {
      filteredData.forEach((dataPoint, index) => {
        if (
          index % 30 === 0 ||
          index === 0 ||
          index === filteredData.length - 1
        ) {
          const { v, d } = dataPoint;
          const numericalValue = parseFloat(v);
          xAxisData.push(d);
          seriesData.push(numericalValue);
        }
      });
    } else {
      filteredData.forEach((dataPoint) => {
        const { v, d } = dataPoint;
        const numericalValue = parseFloat(v);
        xAxisData.push(d);
        seriesData.push(numericalValue);
      });
    }
    // Find the nearest date in the x-AxisData to the current date

    const maxLength = Math.max(xAxisData.length, seriesData.length);
    xAxisData.length = maxLength;
    seriesData.length = maxLength;

    return { xAxisData, seriesData };
  };

  useEffect(() => {
    if (fundInfo && fundInfo.hitoricalNavs) {
      const historicalNavs = fundInfo.hitoricalNavs;
      const newData =
        filter === 10000
          ? filterDataByRange(historicalNavs, filter, "all")
          : filterDataByRange(historicalNavs, filter, "days");
      if (!newData) return;
      const { xAxisData, seriesData } = newData;

      if (xAxisData.length === seriesData.length && xAxisData.length > 0) {
        setFilterChartData({
          xAxis: [{ data: xAxisData }],
          series: [{ data: seriesData }],
        });
      } else {
        setFilterChartData({ xAxis: [], series: [] });
      }
    }
  }, [fundInfo, filter]);

  return filterChartData;
};

const useMappedXAxisData = (filterChartData: any) => {
  const [mappedXAxisData, setMappedXAxisData] = useState<any>([]);

  useEffect(() => {
    if (filterChartData.xAxis.length > 0) {
      const xAxisData = filterChartData.xAxis[0]?.data || [];
      const temp = xAxisData.map((dateString: string) => new Date(dateString));
      setMappedXAxisData(temp);
    }
  }, [filterChartData.xAxis]);

  return mappedXAxisData;
};

const FDMangerCard = ({ data }: any) => {
  const currentDate = Date.now();
  return (
    <>
      {!data || data.length === 0 ? (
        <Skeleton
          variant="rectangular"
          height={200}
          animation="wave"
          sx={{ bgcolor: colors.lightBg }}
        />
      ) : (
        data.map((manager: any, index: number) => (
          <div
            key={index}
            className="px-2 py-8 gap-y-2 rounded-md justify-between"
          >
            <div className="flex flex-row">
              <div className="mr-4">
                <div className="flex items-center justify-center sm:justify-normal">
                  <span>
                    {manager.imgSrc ? (
                      <img className="w-6" src={manager.imgSrc} alt="fd" />
                    ) : (
                      <div className="sm:w-6 sm:h-6 w-[3rem] h-[3rem] mb-3 md:mb-0 md:w-16 md:h-16 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-2xl font-bold">
                        {getInitials(manager.name)}
                      </div>
                    )}
                  </span>
                </div>
              </div>
              <div className="flex flex-col justify-between">
                <div>
                  <div className="flex items-center">
                    <h2 className="text-2xl font-medium text-primary">
                      {manager.name}
                    </h2>
                  </div>
                </div>
                <div className="flex flex-row py-4">
                  <p className="border-r-2 border-black pr-1  text font-medium">
                    Experience:{" "}
                    {TimeStamptoExp.calculateYearsOfExperience(
                      manager.startDate,
                      currentDate
                    )}
                  </p>
                  <p className="pl-2 font-medium">Role: {manager.role}</p>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

const MFDetails = () => {
  const user = useUserData();
  const { bank } = useUserData() || { bank: [] };
  const { isin, id } = useParams<{ isin: string; id: string }>();
  const dispatch = useDispatch<any>();
  const funds = useSelector((state: any) => state?.MFund.funds);
  const fundInfo = useSelector((state: any) => state.MFund.fundinfo);
  const [investemntAmmount, setInvestemntAmmount] = useState<any>(10000);
  const [MFdata, setMFdata] = useState<any>();
  const [expectedReturn, setExpectedReturn] = useState<any>("15");
  const [timePeriod, setTimePeriod] = useState<any>("5");
  const [loading, setLoading] = useState(false);
  const schemeData = useSelector((state: any) => state.MFund?.MFundbyid);
  const [filter, setFilter] = useState<number>(30);
  const filterChartData = useFilterChartData({ fundInfo, filter });
  const mappedXAxisData = useMappedXAxisData(filterChartData);
  const { state } = useLocation();
  const MDdata = state ? state.data : funds;
  const [interestRateMF, setInterestRateMF] = useState({});
  const [watchList, setWatchList] = useState<any>();
  const [mfDataByiSIN, setMFDataByISIN] = useState<any>();
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const [isCancelSIPModalOpen, setIsCancelSIPModalOpen] = useState(false);

  const checkMF = user?.preferences?.mutualFunds;

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [isin, id, dispatch]);

  const labelToKeyMap = {
    ISIN: "isin",
    "Scheme code": "bseSchemeCode",
    "Inception date": "startData",
    "AUM (Fund size)": "fundSize",
    "Min Investment": "minimumPurchaseAmount",
    "Exit Load": "exitLoad",
    "Expense Ratio": "expenseRatio",
    "Lock-in": "lockInPeriod",
    "Dividend History": "-",
    Benchmark: "-",
  };

  //Filter value for Horizontall Chart
  const lineOptions = [
    // {label: "1D", value: 1},
    { label: "7D", value: 10 },
    { label: "1M", value: 30 },
    { label: "1Y", value: 365 },
    { label: "All", value: 10000 },
  ];
  const fundDetails = MFdata;

  //MF fund summary bar

  const keysToDisplay = ["currentNAV", "riskLevel", "returnRate1Yrs"];
  const customLabels: { [key: string]: string } = {
    currentNAV: "Current NAV",
    returnRate1Yrs: "Return Rate (1 Year)",
    riskLevel: "Risk Level",
  };
  // mfRank: "Rank",
  const filteredDetails = fundDetails
    ? Object.entries(fundDetails)
        .filter(([key]) => keysToDisplay?.includes(key))
        .map(([key, value]) => {
          if (key === "returnRate1Yrs") {
            return {
              label: customLabels[key],
              value: Number(value).toFixed(2) + "%",
            };
          } else {
            return {
              label: customLabels[key],
              value: value as string, // Assume value is string if it's not returnRate3Yrs
            };
          }
        })
    : [];

  // two APi call parallel get by iD and Get by ISIN
  const fetchData = async () => {
    try {
      const promises: Promise<any>[] = [];

      if (isin) {
        promises.push(dispatch(getFundInfo({ isin: isin })));
      }

      if (id) {
        promises.push(dispatch(getMFundById({ fundId: id })));
      }

      const responses = await Promise.all(promises);

      responses.forEach((response) => {
        try {
          const data = unwrapResult(response);
          if (data?.returns) {
            setInterestRateMF(fundInfo.returns);
            setMFDataByISIN(data);
          } else {
            setMFdata(data);
            setWatchList(data?.isInWishlist);
          }
        } catch (error) {
          setOpen(true);
          setErrorMessage("Please Refresh page");
        }
      });
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const SchemeDetailsData = () => {
    return (
      <>
        {!schemeData || schemeData.length === 0 ? (
          <Skeleton
            variant="rectangular"
            height={200}
            animation="wave"
            sx={{ bgcolor: colors.lightBg }}
          />
        ) : (
          <div className="flex flex-wrap gap-4 justify-between">
            {Object.entries(labelToKeyMap).map(([label, key]) => {
              const value = schemeData[key] || "-";
              return (
                <div
                  key={label}
                  className="flex flex-row justify-between w-full h-auto border-b-2 border-gray-200 items-center p-4 hover:bg-lightBg"
                >
                  <h3 className="font-bold">{label}</h3>
                  <span className="sm:text-sm text-right sm:text-justify">{value}</span>
                </div>
              );
            })}
          </div>
        )}
      </>
    );
  };

  const [isInvestModalOpen, setIsInvestModalOpen] = useState(false);

  //model Open
  const openInvestModal = () => {
    // if (user?.onboardStatus !== 3) {
    //   setOpen(true);
    //   setErrorMessage(
    //     "Please wait for 24 hours. You are not allowed to invest now."
    //   );
    //   return;
    // }
    setIsInvestModalOpen(true);
  };

  //model Close
  const closeInvestModal = () => {
    setIsInvestModalOpen(false);
    fetchData();
  };

  //Watchlist ad and Remove Funtion

  const Watchlist = async (id: number) => {
    if (watchList === 1) {
      const data = { itemId: id, itemType: 2 };
      const response = await dispatch(removeToWatchlist(data));
      if (response.payload) {
        setWatchList(0);
        setOpen(true);
        setMessage("Scheme removed from watchlist.");
      } else {
        setOpen(true);
        setErrorMessage("Failed to Remove to watchlist");
      }
    } else if (watchList === 0) {
      const data = { itemId: id, itemType: 2 };
      const response = await dispatch(addToWatchlist(data));
      if (response.payload) {
        setWatchList(1);
        setOpen(true);
        setMessage("Scheme added to watchlist");
      } else {
        setOpen(true);
        setErrorMessage("Failed to add to watchlist");
      }
    }
  };

  //vertical Table

  const plans = (mfISIN: any, mfID: any) => [
    {
      FundReturns: {
        name: "Fund Returns",
        absoluteRank1Year: `${parseFloat(
          mfISIN?.returns["DP-Return1Yr"] || 0
        ).toFixed(2)}`,
        absoluteRank2Year: `${parseFloat(
          mfISIN?.returns["DP-Return2Yr"] || 0
        ).toFixed(2)}`,
        absoluteRank3Year: `${parseFloat(
          mfISIN?.returns["DP-Return3Yr"] || 0
        ).toFixed(2)}`,
        absoluteRank4Year: `${parseFloat(
          mfISIN?.returns["DP-Return4Yr"] || 0
        ).toFixed(2)}`,
        absoluteRank5Year: `${parseFloat(
          mfISIN?.returns["DP-Return5Yr"] || 0
        ).toFixed(2)}`,
        absoluteRank7Year: `${parseFloat(
          mfISIN?.returns["DP-Return7Yr"] || 0
        ).toFixed(2)}`,
        absoluteRank10Year: `${parseFloat(
          mfISIN?.returns["DP-Return10Yr"] || 0
        ).toFixed(2)}`,
        inception: "-",
      },
      CategoryAverage: {
        categoryReturn1Year: `${MFdata.ratios[0]?.alpha1Year && MFdata.ratios[0]?.alpha1Year!=="-"?parseFloat(
          MFdata.ratios[0]?.alpha1Year).toFixed(2):"-"}`,
        categoryReturn2Year: `${MFdata.ratios[0]?.alpha2Year && MFdata.ratios[0]?.alpha2Year!=="-"?parseFloat(
          MFdata.ratios[0]?.alpha2Year).toFixed(2):"-"}`,
        categoryReturn3Year: `${MFdata.ratios[0]?.alpha3Year && MFdata.ratios[0]?.alpha3Year!=="-"?parseFloat(
          MFdata.ratios[0]?.alpha3Year).toFixed(2):"-"}`,
        categoryReturn4Year: `${MFdata.ratios[0]?.alpha4Year && MFdata.ratios[0]?.alpha4Year!=="-"?parseFloat(
          MFdata.ratios[0]?.alpha4Year).toFixed(2):"-"}`,
        categoryReturn5Year: `${MFdata.ratios[0]?.alpha5Year && MFdata.ratios[0]?.alpha5Year!=="-"?parseFloat(
          MFdata.ratios[0]?.alpha5Year).toFixed(2):"-"}`,
        categoryReturn7Year: `${MFdata.ratios[0]?.alpha7Year && MFdata.ratios[0]?.alpha7Year!=="-"?parseFloat(
          MFdata.ratios[0]?.alpha7Year).toFixed(2):"-"}`,
        categoryReturn10Year: `${MFdata.ratios[0]?.alpha10Year && MFdata.ratios[0]?.alpha10Year!=="-"?parseFloat(
          MFdata.ratios[0]?.alpha10Year).toFixed(2):"-"}`,
        inception: "-",
      },
      RankWithinCategory: {
        categoryReturn1Year: `${parseFloat(
          MFdata.ranks[0]?.categoryReturn1Year || 0
        ).toFixed(2)}`,
        categoryReturn2Year: `${parseFloat(
          MFdata.ranks[0]?.categoryReturn2Year || 0
        ).toFixed(2)}`,
        categoryReturn3Year: `${parseFloat(
          MFdata.ranks[0]?.categoryReturn3Year || 0
        ).toFixed(2)}`,
        categoryReturn4Year: `${parseFloat(
          MFdata.ranks[0]?.categoryReturn4Year || 0
        ).toFixed(2)}`,
        categoryReturn5Year: `${parseFloat(
          MFdata.ranks[0]?.categoryReturn5Year || 0
        ).toFixed(2)}`,
        categoryReturn7Year: `${parseFloat(
          MFdata.ranks[0]?.categoryReturn7Year || 0
        ).toFixed(2)}`,
        categoryReturn10Year: `${parseFloat(
          MFdata.ranks[0]?.categoryReturn10Year || 0
        ).toFixed(2)}`,
        inception: "",
      },
    },
  ];

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Investments", href: "/mf-listing" },
    { label: "Mutual Fund" },
  ];

  //Return Rate Keys value for finding
  const returnRateTable = [
    "DP-Return1Yr",
    "DP-Return2Yr",
    "DP-Return3Yr",
    "DP-Return4Yr",
    "DP-Return5Yr",
    "DP-Return7Yr",
    "DP-Return10Yr",
    "DP-inception",
  ];

  const theme = useTheme();
  // Detect screen size using useMediaQuery
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  // Dynamically adjust height based on screen size
  let chartHeight = 500; // default height for large screens
  if (isLargeScreen) {
    chartHeight = 500; // Keep it 500 for large screens
  } else if (isMediumScreen) {
    chartHeight = 400; // Slightly reduce the height for medium screens
  } else if (isSmallScreen) {
    chartHeight = 250; // Further reduce the height for small screens
  }

  const handleClose=()=>{
    setOpen(false);
  }

  const showingInterest = async () => {
    try {
      const response = await dispatch(
        showInterest({ id: id, holdingType: 2 })
      );

      if (response?.payload?.statusCode === 200) {
        setOpen(true);
        setMessage("Your interest is submitted successfully to RM.");
      } else {
        setOpen(true);
        setErrorMessage("Something wents wrong.Please try again later!");
        console.warn("Unexpected response structure:", response);
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };
  const handleOpenCancelSIPModal =()=>{
    setIsCancelSIPModalOpen(true);
  }
  const handleCancelSIPModalModal =()=>{
    setIsCancelSIPModalOpen(false);
  }

  return (
    <div>
      {fundDetails ? (
        <div>
          <div className="bg-lightBg">
            <div className="px-4 sm:px-8 pb-8 md:px-[60px] md:pb-[60px] md:pt-[30px] max-w-[1440px] w-full mx-auto ">
              <div className="py-2 sm:pb-4 border-b border-lightGrey sm:mb-8 mb-4">
                <DynamicBreadcrumbs items={breadcrumbItems} />
              </div>
              <div className="flex flex-row sm:flex-row gap-x-6">
                <div className="w-18 h-14 sm:w-14 sm:h-14 mb-3 md:mb-0 md:w-32 md:h-32 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-4xl font-bold">
                  {getInitials(fundDetails.schemeName)}
                </div>
                <div className="flex flex-col gap-y-3 flex-1 max-w-[800px]">
                  <h3 className="text-2xl font-medium text-primary">
                    {fundDetails.schemeName}
                  </h3>
                  <div className="sm:flex gap-5 mb-5 justify-center md:justify-start hidden">
                    {filteredDetails.map((detail) => (
                      <FDDetailCard
                        key={detail.label}
                        label={detail.label}
                        value={detail.value}
                      />
                    ))}
                  </div>
                  <div className="sm:flex flex-wrap items-center gap-4 hidden">
                    {/* Buttons and other components */}
                   {bank?.length > 0 ?( <Button
                      sx={{
                        backgroundColor: colors.white,
                        borderRadius: 1,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        opacity: checkMF === 1 ? 1 : 0.5,
                        cursor: checkMF === 1 ? "pointer" : "not-allowed",
                        fontWeight: "600",
                        border: `1px solid ${colors.primary}`,
                        "&:hover": {
                          backgroundColor: colors.primary,
                          color: colors.white,
                        },
                      }}
                      onClick={checkMF === 1 ? openInvestModal : () => {}}
                    >
                      Invest Now
                    </Button>):( <Button
                      sx={{
                        backgroundColor: colors.white,
                        borderRadius: 1,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        opacity: checkMF === 1 ? 1 : 0.5,
                        cursor: checkMF === 1 ? "pointer" : "not-allowed",
                        fontWeight: "600",
                        border: `1px solid ${colors.primary}`,
                        "&:hover": {
                          backgroundColor: colors.primary,
                          color: colors.white,
                        },
                      }}
                      onClick={showingInterest}
                    >
                      Show Interest
                    </Button>)}
                    <InvestModal
                      open={isInvestModalOpen}
                      onClose={closeInvestModal}
                      fundInfo={fundDetails}
                    />
                    {/* <Button
                      sx={{
                        backgroundColor: colors.white,
                        borderRadius: 1,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        fontWeight: '600',
                        // fontSize: '14px',
                        '&:hover': {
                          backgroundColor: colors.primary,
                          color: colors.white
                        },
                      }}
                      variant="outlined"
                    >
                      <Download />
                      Download Scheme Document
                    </Button> */}
                    {MFdata.mySIP && MFdata.mySIP?.length>0 && (
                      <Button
                      sx={{
                        backgroundColor: colors.white,
                        borderRadius: 1,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        opacity: checkMF === 1 ? 1 : 0.5,
                        cursor: checkMF === 1 ? "pointer" : "not-allowed",
                        fontWeight: "600",
                        border: `1px solid ${colors.primary}`,
                        "&:hover": {
                          backgroundColor: colors.primary,
                          color: colors.white,
                        },
                      }}
                      onClick={handleOpenCancelSIPModal}
                    >
                      Cancel SIP
                    </Button>
                    )}
                     <CancelSIPModal open={isCancelSIPModalOpen} onClose={handleCancelSIPModalModal} mySIP={MFdata?.mySIP} schemeName={fundDetails?.schemeName}/>
                    <Button
                      sx={{
                        borderRadius: 2,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        border: `1px solid ${colors.primary}`,
                      }}
                      variant="outlined"
                      onClick={() => Watchlist(MFdata?.id)}
                    >
                      {!watchList ? (
                        <>
                          <BookmarkBorder />
                          Add to wishlist
                        </>
                      ) : (
                        <>
                          <FaBookmark
                            style={{ fontSize: "20px", marginRight: "5px" }}
                          />
                          Remove from wishlist
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </div>
              <div className="flex gap-5 mb-5 justify-center md:justify-start sm:hidden">
                {filteredDetails.map((detail) => (
                  <FDDetailCard
                    key={detail.label}
                    label={detail.label}
                    value={detail.value}
                  />
                ))}
              </div>
              <div className="flex justify-center items-center gap-4 my-4 sm:hidden">
                    {/* Buttons and other components */}
              {bank?.length > 0 ? (  <Button
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: 1,
                  textTransform: "none",
                  padding: "0.5rem 1rem",
                  color: colors.primary,
                  opacity: checkMF === 1 ? 1 : 0.5,
                  cursor: checkMF === 1 ? "pointer" : "not-allowed",
                  fontWeight: '600',
                  // fontSize: '14px',
                  '&:hover': {
                    backgroundColor: colors.primary,
                    color: colors.white
                  },
                }}
                onClick={checkMF === 1 ? openInvestModal :  () => {}}
              >
                Invest Now
              </Button>):(  <Button
                sx={{
                  backgroundColor: colors.white,
                  borderRadius: 1,
                  textTransform: "none",
                  padding: "0.5rem 1rem",
                  color: colors.primary,
                  opacity: checkMF === 1 ? 1 : 0.5,
                  cursor: checkMF === 1 ? "pointer" : "not-allowed",
                  fontWeight: '600',
                  // fontSize: '14px',
                  '&:hover': {
                    backgroundColor: colors.primary,
                    color: colors.white
                  },
                }}
                onClick={showingInterest}
              >
                Show Interest
              </Button>)}
                    <InvestModal open={isInvestModalOpen} onClose={closeInvestModal} fundInfo={fundDetails} />
                    {/* <Button
                      sx={{
                        backgroundColor: colors.white,
                        borderRadius: 1,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        fontWeight: '600',
                        // fontSize: '14px',
                        '&:hover': {
                          backgroundColor: colors.primary,
                          color: colors.white
                        },
                      }}
                      variant="outlined"
                    >
                      <Download />
                      Download Scheme Document
                    </Button> */}
                    <Button
                      sx={{
                        borderRadius: 2,
                        textTransform: "none",
                        padding: "0.5rem 1rem",
                        color: colors.primary,
                        border: `1px solid ${colors.primary}`,
                      }}
                      variant="outlined"
                      onClick={() => Watchlist(MFdata?.id)}
                    >
                      {!watchList ? (
                        <>
                          <BookmarkBorder />
                          Add to wishlist
                        </>
                      ) : (
                        <>
                          <FaBookmark style={{ fontSize: '20px', marginRight: '5px' }} />
                          Remove from wishlist
                        </>
                      )}
                    </Button>
                  </div>

              <div>
                <div className="flex justify-end">
                  <ToggleButtonGroup
                    color="primary"
                    value={filter}
                    exclusive
                    onChange={(_, value) => setFilter(value)}
                    aria-label="text alignment"
                    sx={{
                      paddingVertical: "2px",
                      height: "40px",
                      "& .MuiToggleButton-root.Mui-selected": {
                        backgroundColor: colors.primary,
                        color: "white",
                        fontWeight: "bold",
                      },
                    }}
                  >
                    {lineOptions.map((option) => (
                      <ToggleButton
                        key={option.value}
                        value={option.value}
                        aria-label="alignment"
                      >
                        {option.label}
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </div>
                <Box sx={{ width: "100%", overflow: "auto",marginX:"auto" }}>
                  {mappedXAxisData.length ===
                  filterChartData.series[0]?.data.length ? (
                    <LineChart
                      xAxis={[
                        {
                          label: "Date",
                          data: mappedXAxisData,
                          tickInterval: "auto",
                          scaleType: "time",
                          valueFormatter: (date) =>
                            dayjs(date).format("DD/MM/YYYY"),
                        },
                      ]}
                      series={[{ data: filterChartData.series[0]?.data }]}
                      height={chartHeight}
                      slotProps={{
                        mark: {
                          display: "none",
                        },
                      }}
                      // width={1600}
                    />
                  ) : (
                    <Dots />
                  )}
                </Box>
              </div>
            </div>
          </div>
          <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto ">
            <div className="my-5">
              <div className="my-5">
                <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      backgroundColor: colors.lightBg,
                      borderRadius: "5px",
                      borderColor: colors.primary,
                      borderWidth: "1px",
                      borderStyle: "solid",
                    }}
                  >
                    <h6 className="font-semibold text-lg text-primary">
                      Returns & Ranking
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails sx={{ margin: "1rem 0" }}>
                    <TableContainer component={Card}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead
                          sx={{
                            backgroundColor: colors.bgColor,
                            boxShadow: "none",
                          }}
                        >
                          <TableRow className="border border-primary">
                            <TableCell
                              sx={{ border: "none", color: colors.primary }}
                            ></TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "none", color: colors.primary }}
                            >
                              1Y
                            </TableCell>
                            <TableCell
                              sx={{
                                border: "none",
                                color: colors.primary,
                                textAlign: "center",
                              }}
                            >
                              2Y
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "none", color: colors.primary }}
                            >
                              3Y
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "none", color: colors.primary }}
                            >
                              4Y
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "none", color: colors.primary }}
                            >
                              5Y
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "none", color: colors.primary }}
                            >
                              7Y
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "none", color: colors.primary }}
                            >
                              10Y
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{ border: "none", color: colors.primary }}
                            >
                              Since Inception
                            </TableCell>
                            <TableCell
                              sx={{ border: "none", color: colors.primary }}
                            ></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <React.Fragment>
                            <TableRow
                              sx={
                                {
                                  // "&:last-child td, &:last-child th": { border: 0 },
                                }
                              }
                              className="border-r border-primary"
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                className="border-x border-primary bg-bgColor border-0"
                              >
                                Fund Returns
                              </TableCell>
                              {returnRateTable.map((rate, index) => (
                                <TableCell key={index} align="center">
                                  {mfDataByiSIN.returns[rate]
                                    ? `${Number(
                                        mfDataByiSIN.returns[rate]
                                      ).toFixed(2)}%`
                                    : "-"}
                                </TableCell>
                              ))}
                            </TableRow>
                          </React.Fragment>

                          {plans(fundInfo, MFdata).map((plan, index) => (
                            <React.Fragment key={index}>
                              <TableRow
                                sx={
                                  {
                                    // "&:last-child td, &:last-child th": { border: 0 },
                                  }
                                }
                                className="border-r border-primary"
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  className="border-x border-primary bg-bgColor"
                                >
                                  Category Return
                                </TableCell>
                                <TableCell align="center">
                                  {plan.RankWithinCategory.categoryReturn1Year}
                                </TableCell>
                                <TableCell align="center">
                                  {plan.RankWithinCategory.categoryReturn2Year}
                                </TableCell>
                                <TableCell align="center">
                                  {plan.RankWithinCategory.categoryReturn3Year}
                                </TableCell>
                                <TableCell align="center">
                                  {plan.RankWithinCategory.categoryReturn4Year}
                                </TableCell>
                                <TableCell align="center">
                                  {plan.RankWithinCategory.categoryReturn5Year}
                                </TableCell>
                                <TableCell align="center">
                                  {plan.RankWithinCategory.categoryReturn7Year}
                                </TableCell>
                                <TableCell align="center">
                                  {plan.RankWithinCategory.categoryReturn10Year}
                                </TableCell>
                                <TableCell align="center">
                                  {plan.RankWithinCategory.inception || "-"}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={
                                  {
                                    // "&:last-child td, &:last-child th": { border: 0 },
                                  }
                                }
                                className="border-b border-r border-primary"
                              >
                                <TableCell
                                  sx={{ borderBottom: "none" }}
                                  component="th"
                                  scope="row"
                                  className="border-x  border-primary bg-bgColor"
                                >
                                  Alpha Return
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "none" }}
                                >
                                  {plan.CategoryAverage.categoryReturn1Year}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "none" }}
                                >
                                  {plan.CategoryAverage.categoryReturn2Year}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "none" }}
                                >
                                  {plan.CategoryAverage.categoryReturn3Year}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "none" }}
                                >
                                  {plan.CategoryAverage.categoryReturn4Year}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "none" }}
                                >
                                  {plan.CategoryAverage.categoryReturn5Year}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "none" }}
                                >
                                  {plan.CategoryAverage.categoryReturn7Year}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "none" }}
                                >
                                  {plan.CategoryAverage.categoryReturn10Year}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  sx={{ border: "none" }}
                                >
                                  {plan.CategoryAverage.inception || "-"}
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </AccordionDetails>
                </Accordion>
              </div>

              <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    backgroundColor: colors.lightBg,
                    borderRadius: "5px",
                    borderColor: colors.primary,
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <h6
                    className="font-semibold text-lg"
                    style={{ color: colors.primary }}
                  >
                    About{" "}
                    {fundDetails.schemeName &&
                    fundDetails.schemeName?.includes("FUND")
                      ? fundDetails.schemeName.split("FUND")[0]
                      : fundDetails.schemeName}
                  </h6>
                </AccordionSummary>
                <AccordionDetails sx={{ margin: "1rem 0" }}>
                  <Typography className="text-textGrey font-inter">
                    Trusted by millions of customers, Shriram Fixed Deposit is
                    the safest investment option with attractive interest rates.
                    Rated "[ICRA]AA+ (Stable)" by ICRA and "IND AA+/Stable" by
                    India Ratings and Research.
                  </Typography>
                  <div className="flex sm:flex-row flex-col gap-5 mb-5 justify-center sm:justify-between pt-2 ">
                    <FDMangerCard data={fundDetails?.managers} />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="my-5">
              <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    backgroundColor: colors.lightBg,
                    borderRadius: "5px",
                    borderColor: colors.primary,
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <h6 className="font-semibold text-lg text-primary">
                    Scheme Details
                  </h6>
                </AccordionSummary>
                <AccordionDetails sx={{ margin: "2rem 0.5rem", padding: "0" }}>
                  <div className="flex flex-wrap gap-5 border border-lightGrey rounded-md  p-4">
                    <SchemeDetailsData />
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>

            <div className="my-5">
              <Accordion defaultExpanded sx={{ boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    backgroundColor: colors.lightBg,
                    borderRadius: "5px",
                    borderColor: colors.primary,
                    borderWidth: "1px",
                    borderStyle: "solid",
                  }}
                >
                  <h6 className="font-semibold text-lg text-primary">
                    Return Calculator
                  </h6>
                </AccordionSummary>

                <AccordionDetails sx={{ padding: "0" }} className="mt-8">
                  <MFCalculator
                    interestRateMF={interestRateMF}
                    fundDetails={fundDetails}
                    setIsInvestModalOpen={setIsInvestModalOpen}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
            <div className="my-5">
              <AssetAllocation DataBYID={MFdata} DataBYiSIN={fundInfo} />
            </div>

            <div className="my-5">
              <RiskRatios />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="bg-lightBg h-full">
            <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto ">
              <div className="flex flex-col md:flex-row gap-x-6">
                <div className="w-14 h-14 mb-3 md:mb-0 md:w-32 md:h-32 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-4xl font-bold">
                  <Skeleton variant="circular" width={120} height={120} />
                </div>
                <div className="flex flex-col gap-y-3 flex-1 w-full">
                  <h3 className="text-2xl font-medium text-primary">
                    <Skeleton variant="text" width={200} />
                  </h3>
                  <div className="flex flex-wrap gap-5 mb-1 justify-center md:justify-start">
                    {[1, 2, 3, 4, 5].map((index) => (
                      <Skeleton
                        key={index}
                        variant="rectangular"
                        width={300}
                        height={50}
                      />
                    ))}
                  </div>
                  <div className="flex flex-wrap items-center gap-4">
                    {[1].map((index) => (
                      <Skeleton key={index} width="100%" height={500} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <SuccessfullModal
        open={Open}
        message={message!==""?message:errormessage}
        handleClose={handleClose}
      />
    </div>
  );
};

export default MFDetails;
