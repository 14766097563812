import React, { useCallback, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Search, Close } from "@mui/icons-material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GetAppIcon from "@material-ui/icons/GetApp";
import { FiUpload } from "react-icons/fi";
import toast  from "react-hot-toast";
import { useDispatch } from "react-redux";
import { uploadHoldingsData } from "../../redux/asset_Holdings/assetSlice";

interface UploadModalProps {
  open: boolean;
  handleClose: () => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ open, handleClose }) => {
  const dispatch=useDispatch<any>();
  const [uploadType, setUploadType] = useState("file");
  const [assetType, setAssetType] = useState("AIF");
  const [settlementDate, setSettlementDate] = useState(null);

  const [file, setFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);

  const handleDrop = useCallback((e: any) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    if (droppedFile && droppedFile.type === "text/csv") {
      setFile(droppedFile);
      console.log("Dropped file:", droppedFile);
      // Handle the dropped file (e.g., validate, process, etc.)
    } else {
      alert("Please drop a valid CSV file.");
    }
  }, []);

  const handleDragOver = useCallback((e: any) => {
    e.preventDefault();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  const handleSubmit = async () => {
    if (file && assetType) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("holdingType", assetType);
      try {
        const response = await dispatch(uploadHoldingsData(formData));
        if(response?.payload?.statusCode===400){
          toast.error(response?.payload?.message);
          setFile(null);
          setFileName(null);
          handleClose();
        }
        else{
          toast.success("File uploaded successfully.");
          setFile(null);
          setFileName(null);
          handleClose();
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        toast.error("File not uploading due to server issues.Try after sometimes!");
      }
    }
  };

  const handleDownload = () => {
    let templateFile = "/assets/Aif_Template.csv";  // Default file path
    let templateName = "Template.csv";  // Default file name
  
    // Adjust file path and name based on the selected asset type
    if (assetType === "AIF") {
      templateFile = "/assets/Aif_Template.csv";
      templateName = "Aif_Template.csv";
    }
    else if (assetType === "PMS") {
      templateFile = "/assets/Pms_Template.csv";
      templateName = "Pms_Template.csv";
    }
    else if (assetType === "BOND") {
      templateFile = "/assets/Bond_Template.csv";
      templateName = "Bond_Template.csv";
    }
     else if (assetType === "STOCK") {
      templateFile = "/assets/Stock_Template.csv";
      templateName = "Stock_Template.csv";
    }
    else if (assetType === "FD") {
      templateFile = "/assets/Fd_Template.csv";
      templateName = "Fd_Template.csv";
    }
    const tempFile=document.createElement("a");
    tempFile.href=templateFile;
    tempFile.setAttribute("download",templateName);
    document.body.appendChild(tempFile);
    tempFile.click();
    document.body.removeChild(tempFile);  
  };
  
  
  

  return (
    <>
    <Modal open={open} onClose={handleClose} className="overflow-hidden">
      <Box
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 max-w-4xl bg-lightBg p-8 rounded-lg shadow-lg overflow-y-auto"
        style={{ maxHeight: "95vh" }}
      >
        <div className="flex justify-between items-center mb-4">
          <Typography
            variant="h6"
            className="text-primary"
            style={{ fontWeight: "bolder" }}
          >
            Upload Data
          </Typography>

          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
        <div className="flex flex-col gap-4 p-5 bg-white">
          <Typography variant="subtitle1" className="text-black">
            Upload Type
          </Typography>
          <RadioGroup
            row
            value={uploadType}
            onChange={(e) => setUploadType(e.target.value)}
          >
            {/* <FormControlLabel
              value="manual"
              control={<Radio />}
              label="Manual"
              className="text-darkGrey"
            /> */}
            <FormControlLabel
              value="file"
              control={<Radio />}
              label="File"
              className="text-darkGrey"
            />
          </RadioGroup>

          {uploadType === "manual" && (
            <div className="flex flex-col gap-4 p-5 bg-lightBg">
              <Typography variant="subtitle1" className="text-black">
                Choose Asset Type
              </Typography>
              <RadioGroup
                row
                value={assetType}
                onChange={(e) => setAssetType(e.target.value)}
              >
                {/* <FormControlLabel
                  value="Mutual Funds"
                  control={<Radio />}
                  label="Mutual Funds"
                  className="text-darkGrey"
                /> */}
                <FormControlLabel
                  value="AIF"
                  control={<Radio />}
                  label="Aif"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="PMS"
                  control={<Radio />}
                  label="Pms"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="BOND"
                  control={<Radio />}
                  label="Bonds"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="STOCK"
                  control={<Radio />}
                  label="Equity"
                  className="text-darkGrey"
                />
                <FormControlLabel
                  value="FD"
                  control={<Radio />}
                  label="Fixed Deposit"
                  className="text-darkGrey"
                />
              </RadioGroup>
              <Divider className="bg-primary" />
              <div className="relative mt-4">
                <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-primary" />
                <input
                  type="text"
                  placeholder="Search Company Name"
                  className="pl-10 pr-4 py-2 border rounded w-full"
                />
              </div>
              <div className="flex items-center gap-4 mt-4">
                <div className="w-10 h-20 rounded-full  bg-gray-200 flex items-center justify-center"></div>
                <Typography
                  variant="body1"
                  className="text-primary"
                  style={{ fontWeight: "bolder" }}
                >
                  Company Name
                </Typography>
              </div>
              <div className="flex gap-4 mt-4">
                <div className="w-1/2">
                  <label
                    htmlFor="quantity"
                    className="block mb-1 text-darkGrey"
                  >
                    Quantity (in units)
                  </label>
                  <TextField
                    select
                    variant="outlined"
                    className="w-full bg-white"
                    id="quantity"
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </TextField>
                </div>
                <div className="w-1/2">
                  <label htmlFor="price" className="block mb-1 text-darkGrey">
                    Price Purchased At (in INR) / Yield
                  </label>
                  <TextField
                    variant="outlined"
                    type="number"
                    className="w-full bg-white"
                    id="price"
                  />
                </div>
              </div>

              <div className="w-1/2 mt-4 pr-2">
                <label
                  htmlFor="settlementDate"
                  className="block mb-1 text-darkGrey"
                >
                  Settlement Date
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker format="DD/MM/YYYY" className="w-full bg-white" />
                </LocalizationProvider>
              </div>

              <button className="bg-darkGrey text-white py-2 px-4 rounded hover:bg-primary mt-4 w-36">
                Upload
              </button>
            </div>
          )}

          {uploadType === "file" && (
            <div className="flex flex-col gap-4 p-5 bg-lightBg">
              <Typography variant="subtitle1" className="text-darkGrey flex">
                Choose Asset Type <p className="text-red-600 text-2xl ml-1">*</p>
              </Typography>
              <RadioGroup
                row
                value={assetType}
                onChange={(e) => setAssetType(e.target.value)}
              >
                {/* <FormControlLabel
                  value="Mutual Funds"
                  control={<Radio />}
                  label="Mutual Funds"
                /> */}
                <FormControlLabel value="AIF" control={<Radio />} label="Aif" />
                <FormControlLabel value="PMS" control={<Radio />} label="Pms" />
                <FormControlLabel
                  value="BOND"
                  control={<Radio />}
                  label="Bonds"
                />
                <FormControlLabel
                  value="STOCK"
                  control={<Radio />}
                  label="Equity"
                />
                <FormControlLabel
                  value="FD"
                  control={<Radio />}
                  label="Fixed Deposit"
                />
              </RadioGroup>
              <Divider className="bg-primary" />
              <Typography
                variant="subtitle1"
                className="text-primary mt-4"
                style={{ fontWeight: "bolder" }}
              >
                Download Form Template
              </Typography>
              <div className="flex items-center gap-5">
                <Typography
                  variant="subtitle1"
                  className="text-primary"
                  style={{ fontWeight: "normal" }}
                >
                  {assetType}_Template.csv
                </Typography>
                <button
                  className="bg-primary text-white py-2 rounded hover:bg-primary flex items-center justify-center"
                  style={{ width: "15%" }}
                  onClick={handleDownload}
                >
                  Download
                  <GetAppIcon className="text-white" />
                </button>
              </div>
              <label htmlFor="settlementDate" className="block text-darkGrey">
                Upload File
              </label>
              <div>
          <div
            className={`w-1/2 bg-white flex flex-col items-center justify-center cursor-pointer`}
          >
            <div className="flex w-full px-4 py-1 border-2 rounded" >
              <label
                htmlFor="dropzone-file"
                className="flex flex-row items-center justify-between w-full cursor-pointer"

              >
                
                
                    <span className="text-gray-700 text-md">Choose files to upload</span>
                
                
                <FiUpload className="text-gray-700" />
                <input id="dropzone-file" type="file" accept=".csv" className="hidden" onChange={handleChange} />
              </label>
            </div>
          </div>
          {fileName && (
            <div className="mt-4 text-gray-700">
              <strong>Selected File:</strong> {fileName}
            </div>
          )}
        </div>
              {/* <TextField
                type="file"
                className="w-1/2 bg-white"
                InputProps={{
                  endAdornment: <CloudUploadIcon color="primary" />,
                  inputProps: {
                    accept: ".csv", // Specify accepted file types
                    style: {
                      cursor: "pointer",
                      paddingLeft: "10px", // Adjust padding if needed
                      padding: 0, // Reset padding
                    },
                  },
                }}
                InputLabelProps={{
                  shrink: true, // To shrink the label when focused or filled
                }}
                sx={{
                  "&::-webkit-file-upload-button": {
                    display: "none", // Hide the default "Choose File" button
                  },
                }}
                value="" // Make sure this is empty to keep the placeholder text
                placeholder="Choose files to upload"
              /> */}

              <div>
                <Box
                  border={1}
                  borderColor="grey.300"
                  borderRadius="10px"
                  p={2}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  height={150}
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  style={{ cursor: "pointer" }}
                  sx={{
                    mt: 2,
                    backgroundColor: "white",
                    width: "50%",
                    borderStyle: "dashed",
                  }}
                >
                  {file ? (
                    <Typography
                      variant="body2"
                      className="text-darkGrey"
                      style={{ marginLeft: 10 }}
                    >
                      {file.name}
                    </Typography>
                  ) : (
                    <>
                      {/* <CloudUploadIcon color="primary" fontSize="large" /> */}
                      <Typography
                        variant="body2"
                        className="text-darkGrey text-md"
                        style={{ marginLeft: 10 }}
                      >
                        Drag and drop files here (.csv format)
                      </Typography>
                    </>
                  )}
                </Box>
              </div>
              <button className={`bg-darkGrey text-white py-2 px-4 rounded mt-4 w-36 ${!file || !assetType ? "opacity-50 cursor-not-allowed":" hover:bg-primary"}`} disabled={!file || !assetType} onClick={handleSubmit}>
                Upload
              </button>
            </div>
          )}
        </div>
      </Box>
    </Modal>
    </>
  );
};

export default UploadModal;
