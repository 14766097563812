import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Link,
  Modal,
  Paper,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import FilterAlt from "@mui/icons-material/FilterAltOutlined";
import { Add, Close, Search } from "@mui/icons-material";
import { colors } from "../../constants/colors";
import { LineChart } from "@mui/x-charts";
import Elipse from "../../assets/images/Ellipse.png";
import riskometer from "../../assets/icons/new-riskometer.png";
import { BookmarkBorder, Download } from "@mui/icons-material";
import LineChartComponent from "../../components/LineChart/lineChart";

import Doubtnut from "../../components/AllCharts/pieChart";

import Transactions from "../../components/Transactions/Transactions";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch } from "react-redux";
import { fetchDashboardData } from "../../redux/Dashboard/DashboardSlice";
import toast, { LoaderIcon } from "react-hot-toast";

import getInitials from "../../utils/getInitailasIMGUtils";
import { importFunds, mfSendOTP, mfVerifyOTP } from "../../redux/MF/MFSlice";
import VerifyOTPModal from "../../components/modals/VerifyOTPModal";
import AppLoader from "../../components/AppLoader/AppLoader";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import ConfirmationModal from "../../components/modals/ConfirmationModal2";
import HorizontalLine from "../../components/AllCharts/HorizontalLineChart";
import HallowChart from "../../components/AllCharts/HollowChart";
import { getUserById } from "../../redux/user/userSlice";
import ChatbotMain from "../../components/Chatbot/Chatbot";
import dayjs from "dayjs";
import DoubleDonutComponent from "../../components/AllCharts/DoubleDonutChart";
import { MFAssetHoldings } from "../../redux/Reports/ReportSlice";
import PieChartSummary from "../../components/PieChart/PieChartSummary";
import { BondSummary as BondSum } from "../../redux/Bond/BONDSlice";
import PieSummary from "../../components/PieChart/PieSummary";
import BondsSummaryDetails from "./BondsSummaryDetails";

interface MutualFundTransaction {
  id: number;
  schemeName: string;
  orderType: string;
  amount: number;
  units: number;
  purchaseNAV: number;
  currentValue: number;
  isBuy: number;
  orderStatus: string;
  paymentStatus: string | null;
  createdAt: string;
  transactionDate:string;
  investedAmount:number;
}

interface DashboardData {
  AMC: any[];
  summary: any;
  totalValueInvestedMF: number;
  currentValueMF: number;
  unrealisedGainLossMF: number;
  rioMF: number;
  totalValueInvestedFD: number;
  totalValueInvestedMFAumCapital: number;
  currentValueMFAumCapital: number;
  roiMFAumCapital: number;
  unrealisedGainLossMFAumCapital: number;
  totalFinalValueFD: number;
  unrealisedGainLossFD: number;
  roiFD: number;
  totalValueAtCost: number;
  recentTransaction: MutualFundTransaction[];
}

interface Data {
  title: string;
  totalAmount: string;
  profitAmount: string;
  unrealised: string;
  roi: string;
}

interface Holding {
  id: number;
  schemeName: string;
  grossPurchase: number;
  units: number;
}

const BondSummary = () => {
  const user = useUserData();
  const { todayImportDone } = useUserData();
  const [dashboard, setMDData] = useState<DashboardData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [tabState, setTabState] = useState<"AumCapital" | "Consolidated">(
    "AumCapital"
  );
  const [finalData, setFinalData] = useState<Data[]>([]);
  const dispatch = useDispatch<any>();

  const [DataTransfer, setDataTransfer] = useState({
    riskmeter: "Moderately High",
    portfolioSummary: "Portfolio Summary",
    totalValueCost: "1,24,00,750.00",
    UnrealisedGL: "2,23,00,250.00",
    PortfolioValue: "3,48,00,500.00",
    ROI: "23",
  });
  const [OTPData, setOTPData] = useState({
    otpRef: "",
    clientRefNo: "",
    reqId: 0,
  });
  const [showSummary, setShowSummary] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);
  const [otp, setOtp] = useState("");
  const [holdings, setHoldings] = useState<Holding[]>([]);
  const [piechart, setPiechart] = useState<Holding[]>([]);
  const [history, setHistory] = useState<any>([]);

  const handleOtpChange = (event: any) => {
    setOtp(event.target.value);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await dispatch(BondSum({}));
      if (response?.payload?.data) {
        const data = response.payload.data.data;
        setMDData(data);
        setHoldings(data.investedFunds);
        setPiechart(data)
        setHistory(data.history);
      } else {
        throw new Error("Invalid dashboard data structure");
      }
    } catch (error) {
      console.error("Fetch dashboard data failed:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchAllData = async () => {
      await Promise.all([fetchData()]);
    };

    fetchAllData();
  }, []);

  const handleSubmit = async () => {
    if (OTPData && otp) {
      const updatedOTPData = { ...OTPData, otp: otp };
      setOTPData(updatedOTPData);

      if (updatedOTPData) {
        try {
          const verifyResponse = await dispatch(mfVerifyOTP(updatedOTPData));
          if (verifyResponse.payload.data.success === 400) {
            toast.error("Invalid OTP");
          }

          if (verifyResponse.payload.data.success === 200) {
            setShowSummary(false);
            setLoadingModal(true);
            let fetechImport;
            const importData = {
              clientRefNo: updatedOTPData.clientRefNo,
              reqId: updatedOTPData.reqId,
              isRefresh: 0,
            };

            const fetchImportData = async () => {
              fetechImport = await dispatch(importFunds(importData));

              if (
                fetechImport?.payload.success === 400 &&
                fetechImport.payload.message ===
                  "We are in process of generating the CAS. Please visit after sometime."
              ) {
                toast.success(
                  "We are in process of generating the CAS. Please refresh after 20 seconds."
                );
                dispatch(getUserById({}));
                fetchData();
              } else if (fetechImport?.payload.data.success === 200) {
                toast.success("Mutual fund Data Sync Request submitted");
                dispatch(getUserById({}));
                fetchData();
                setLoadingModal(false);
              } else {
                toast.error("Request Failed");
              }
            };

            setTimeout(fetchImportData, 20000);
            setOTPData({
              otpRef: "",
              clientRefNo: "",
              reqId: 0,
            });
            setOtp("");
          } else {
            toast.error("Request Failed");
          }
        } catch (error) {
          toast.error("An error occurred while processing your request.");
        }
      }
    } else {
      toast.error("Please enter the OTP.");
    }
  };

  useEffect(() => {
    const sendOtp = async () => {
      if (todayImportDone === 1) {
        fetchData();
      } else if (todayImportDone === 0) {
        try {
          setShowSummary(true);
          const response = await dispatch(mfSendOTP());

          if (response.payload.reqId) {
            toast.success("OTP Sent Successfully");
            setOTPData(response.payload);
          } else {
            toast.error("No mutual fund found on provided pan card and mobile number.");
            setShowSummary(false);
          }
        } catch (error) {
          toast.error("OTP Send Failed");
          setShowSummary(false);
        }
      }
    };

    sendOtp();
  }, [dispatch, todayImportDone]);

  const currentDate = new Date();
  const formattedDate = currentDate
    .toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "long",
      year: "numeric",
    })
    .toUpperCase();

  const formattedDateWithMonthInCaps = formattedDate.replace(
    /\b\w+\b/,
    (month) => month.toUpperCase()
  );

  useEffect(() => {
    const verticalBar: Data[] = [
      {
        title: "FD",
        totalAmount: (dashboard?.totalValueInvestedFD ?? 0).toString(),
        profitAmount: (dashboard?.totalFinalValueFD ?? 0).toString(),
        unrealised: (dashboard?.unrealisedGainLossFD ?? 0).toString(),
        roi: (dashboard?.roiFD ?? 0).toString(),
      },
      {
        title: "AIF",
        totalAmount: (0).toString(),
        profitAmount: (0).toString(),
        unrealised: (0).toString(),
        roi: (0).toString(),
      },
      {
        title: "PMS",
        totalAmount: (0).toString(),
        profitAmount: (0).toString(),
        unrealised: (0).toString(),
        roi: (0).toString(),
      },
      {
        title: "BONDS",
        totalAmount: (0).toString(),
        profitAmount: (0).toString(),
        unrealised: (0).toString(),
        roi: (0).toString(),
      },
    ];

    let data: Data[] = [];
    if (tabState === "AumCapital") {
      data = [
        {
          title: "Mutual fund",
          totalAmount: (
            dashboard?.totalValueInvestedMFAumCapital ?? 0
          ).toString(),
          profitAmount: (dashboard?.currentValueMFAumCapital ?? 0).toString(),
          unrealised: (
            dashboard?.unrealisedGainLossMFAumCapital ?? 0
          ).toString(),
          roi: (dashboard?.roiMFAumCapital ?? 0).toString(),
        },
      ];
    } else {
      data = [
        {
          title: "Mutual fund",
          totalAmount: (dashboard?.totalValueInvestedMF ?? 0).toString(),
          profitAmount: (dashboard?.currentValueMF ?? 0).toString(),
          unrealised: (dashboard?.unrealisedGainLossMF ?? 0).toString(),
          roi: (dashboard?.rioMF ?? 0).toString(),
        },
      ];
    }

    setFinalData([...data, ...verticalBar]);
  }, [tabState, dashboard]);


  return (
    <>
      {loading ? (
         <div className="flex justify-center items-center h-[80vh] w-full">
         <LoaderIcon style={{ width: "60px", height: "60px" }}/>
       </div>
      ) : (
        <div className="pb-20">
          <div className="bg-lightBg">
            <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto">
            <div className="mb-10">
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link color="inherit" href="/" style={{ textDecoration: 'none' }}>
                    Home
                  </Link>
                  <Typography color="textPrimary">BOND Summary</Typography>
                </Breadcrumbs>
              </div>
              <div className="flex flex-col md:flex-row gap-x-16 items-center">
                <div className="bg-white p-4 rounded-xl items-center border border-gray-500">
                  {/* Header Section */}
                  <div className="flex flex-row gap-4 border-b border-blue-300 pb-3 justify-start items-center">
                    <div className="bg-[#515151] rounded-full p-2 w-20 h-20 flex items-center justify-center text-white mr-2 text-3xl">
                      {getInitials(user?.firstName || "U")}
                    </div>
                    <div className="flex flex-col">
                      <span className="font-bold text-sm">
                        Welcome, {user?.firstName || "User"}
                      </span>
                      <span className="text-sm">Head of the family</span>
                    </div>
                  </div>
                  {/* Riskometer Section */}
                  <div className="flex flex-row gap-4 pt-4 items-center">
                    <img
                      className="mb-3 md:mb-0"
                      src={riskometer}
                      alt="Riskometer"
                    />
                    <div className="flex flex-col">
                      <span className="font-bold text-sm pb-1">
                        Riskometer:{" "}
                        <span className="text-orange">
                          {DataTransfer.riskmeter || "Moderately High"}
                        </span>
                      </span>
                      <Button
                        sx={{
                          borderRadius: "40px",
                          textTransform: "none",
                          padding: "5px 1rem",
                          width: "50%",
                          backgroundColor: colors.primary,
                          color: colors.white,
                        }}
                        variant="contained"
                      >
                        Change
                      </Button>
                    </div>
                  </div>
                </div>
                {/* Investment Summary */}
                <div className="flex flex-col gap-y-3 flex-1 w-full ">
                  <div className="border-b-2 border-lightblueBorder pb-3">
                    <h5 className="text-3xl font-medium text-primary">
                      BOND Summary
                    </h5>
                    <span className="font-semibold">
                      As on {formattedDateWithMonthInCaps}
                    </span>
                  </div>

                  <div className="flex flex-wrap w-full items-center gap-4 my-3 justify-between ">
                    <div className="flex flex-col">
                      <span className="text-gray-700_01 text-xl">
                        Your Investment
                      </span>
                      <span className="text-gray-700_01 font-bold">
                        {dashboard?.summary.costValue.toFixed(2) || "-"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-gray-700_01 text-xl">
                        Current Value
                      </span>
                      <span className="text-gray-700_01 font-bold">
                        {dashboard?.summary.currentMktValue.toFixed(2) || "-"}
                      </span>
                    </div>

                    <div className="flex flex-col">
                      <span className="text-gray-700_01 text-xl">
                        Unrealised Gain / Loss
                      </span>
                      <span className="text-gray-700_01 font-bold">
                        {dashboard?.summary.gainLoss.toFixed(2) || "-"}
                      </span>
                    </div>
                    <div className="flex flex-col">
                      <span className="text-gray-700_01 text-xl">ROI</span>
                      <span className="text-gray-700_01 font-bold">
                        {dashboard?.summary.gainLossPercentage.toFixed(2) || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {history && history?.length >= 10 && (
            <div className="flex justify-center">
              <Box sx={{ width: 1300, overflow: "auto" }}>
                <LineChart
                  sx={{padding:'5px'}}
                  xAxis={[
                    {
                      data: history.map((item: any) => new Date(item.holdingDate)),
                      valueFormatter: (value)=>
                        dayjs(value).format('DD/MM/YYYY'
                      )
                    },
                  ]}
                  series={[
                    {
                      data: history.map((item: any) => item.holdingValue)
                    },
                  ]}
                  grid={{ horizontal: true }} // Enable horizontal grid lines
                  height={500} // Set the height of the chart
                />
              </Box>
            </div>
            )}
          </div>
          {/* Tab Button Section */}
          <div className="px-8 md:px-[60px] max-w-[1440px] w-full mx-auto pt-8">
            <div className="flex flex-col sm:flex-row md:flex-row h-full">
              <div className="sm:w-2/5 md:w-1/2 flex flex-col border border-gray-300 rounded-lg my-4 p-4 overflow-auto">
                <h1 className="text-primary font-bold text-2xl text-left mb-2">
                  Asset Allocation
                </h1>
                <PieSummary data={piechart} />
              </div>
              <div className="flex flex-col border border-gray-300 rounded-lg my-4 w-full ml-4 p-4 overflow-auto">
                <div className="flex items-center w-full justify-between">
                  <h1 className="text-primary font-bold text-2xl">
                    Investment Funds
                  </h1>
                </div>
                <div>
                  <BondsSummaryDetails holdingsData={holdings} />
                </div>
              </div>
            </div>
            {dashboard?.recentTransaction && dashboard?.recentTransaction?.length>0 &&(<div className="mt-20 ">
              <div className="w-2/5 mb-8">
                <h1 className="text-primary font-bold text-2xl">
                  Transactions
                </h1>
              </div>
              <Transactions
                data={[dashboard?.recentTransaction || []]} // Wrap it in an additional array
              />
            </div>)}
          </div>
        </div>
      )}
      {/* Verify OTP Modal */}
      <VerifyOTPModal
        title="Verify OTP Sent to your mobile and email"
        showModal={showSummary}
        setShowModal={setShowSummary}
        otp={otp}
        setOtp={setOtp}
        onClick={handleSubmit}
      />

      {/* Loading Modal */}
      <Modal
        open={loadingModal}
        aria-labelledby="loading-modal-title"
        aria-describedby="loading-modal-description"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <div className="w-[30%] h-[30%] flex flex-col items-center justify-center bg-lightBg rounded-lg">
            <Typography
              id="loading-modal-title"
              variant="subtitle1"
              component="h2"
              marginBottom={5}
            >
              {`MF Report generation can take up to 5 minutes, please wait`}
            </Typography>
            <CircularProgress />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default BondSummary;
