import React, { useState } from 'react';
import { Modal, Box, Typography, IconButton, TextField, InputAdornment, List, ListItem, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { colors } from '../../constants/colors';
import getInitials from '../../utils/getInitailasIMGUtils';
import { getFundInfo, getMFundById } from '../../redux/MF/MFSlice';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../redux/loader/loaderSlice';
import { unwrapResult } from '@reduxjs/toolkit';
interface FundData {
    id: number;
    isin: string;
    schemeType: string;
    schemePlan: string;
    schemeName: string;
    purchaseAllowed: string;
    purchaseTransactionMode: string;
    minimumPurchaseAmount: string;
    rtaAgentCode: string;
    sipFlag: string;
    stpFlag: string;
    swpFlag: string;
    switchFlag: string;
    startData: string;
    endDate: string;
    fundSize: string;
    mfRank: string;
    returnRate3Yrs: string;
    currentNAV: number;
    exitLoad: string;
    expenseRatio: string;
    lockInPeriod: string;
    riskLevel: string;
    isInWishlist: number;
    icon?: string;
}
const FundSelectorModal = ({ open, onClose, recentSearches, onSearch, fundData = [], setSelectedFund, selectedFund }: any) => {
    const [MFData, setmfData] = useState(fundData)
    const [searchQuery, setSearchQuery] = useState('');
    const dispatch = useDispatch<any>();

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setSearchQuery(newValue);
        onSearch(newValue);
        // searchFund(newValue);
    };

    const handleSearchSubmit = (e: any) => {
        e.preventDefault();

        // onSearch(searchQuery);
        setSearchQuery('');
    };


    const searchFund = (query: string) => {
        const filteredData = fundData.filter((fund: any) =>
            fund.schemeName.toLowerCase()?.includes(query.toLowerCase())
        );
        setmfData(filteredData);
    };

    const titleSlicer = (title: string) => {
        return title.length > 20 ? title.slice(0, 20) + '...' : title;
    }


    const selectFund = async (fund: FundData) => {
        setSelectedFund((prevSelected: any) => {
            const alreadySelected = Object.values(prevSelected).some((f: any) => f?.fund?.id === fund.id);

            if (alreadySelected) {
                const newSelected = { ...prevSelected };
                for (let key in newSelected) {
                    if (newSelected[key]?.fund?.id === fund.id) {
                        newSelected[key] = undefined;
                        break;
                    }
                }
                return newSelected;
            }

            return prevSelected;
        });

        const availableIndex = Object.keys(selectedFund).find(key => selectedFund[Number(key)] === undefined);

        console.log('Avaiable Index', availableIndex);


        if (availableIndex) {
            const dataByID = await fetchData(fund.id, fund.isin);
            // console.log('responseData lv4', dataByID, selectedFund);

            if (dataByID) {
                setSelectedFund((prevSelected: any) => ({
                    ...prevSelected,
                    [availableIndex]: { fund, fundData: dataByID }
                }));
            }
        }
    };

    // const getInfAboutFund = async (id: number) => {
    //     try {
    //         dispatch(showLoading());
    //         const response = await dispatch(getMFundById({ fundId: id }));
    //         console.log('response by id', response);

    //         if (response.payload) {
    //             dispatch(hideLoading());
    //             return response.payload;
    //         } else {
    //             toast.error('Error! Try Again later');
    //             dispatch(hideLoading());
    //             return null;
    //         }
    //     } catch (err) {
    //         console.error('Error:', err);
    //         dispatch(hideLoading());
    //         return null;
    //     }
    // };

    const fetchData = async (id: number, isin: string) => {
        try {
            dispatch(showLoading());
            const promises: Promise<any>[] = [];

            if (isin) {
                promises.push(dispatch(getFundInfo({ isin: isin })));
            }

            if (id) {
                promises.push(dispatch(getMFundById({ fundId: id })));
            }

            const responses = await Promise.all(promises);
            let responseData: any = {};
            // console.log('responses', responses);

            responses.forEach(response => {
                try {
                    const data = unwrapResult(response);
                    // console.log('responses', data);
                    if (data) {
                        if (data.returns) {
                            responseData.fundINFO = data;
                        } else {
                            responseData.fundInfoByID = data;
                        }
                    }
                } catch (error) {
                    toast.error('Please Refresh page');
                }
            });

            // console.log('responseData lv3', responseData);

            if (responseData.fundINFO && responseData.fundInfoByID) {
                dispatch(hideLoading());
                return responseData;
            }
        } catch (error) {
            dispatch(hideLoading());
            console.error("API Error:", error);
        }
    };











    const renderFundItem = (fund: FundData) => {
        const isSelected = Object.values(selectedFund).some((f: any) => f?.fund?.id === fund.id);

        return (
            <Box
                component="button"
                onClick={() => selectFund(fund)}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 2,
                    mb: 2,
                    borderRadius: 1,
                    boxShadow: 1,
                    width: '100%',
                    cursor: 'pointer',
                    bgcolor: isSelected ? '#d1e7ff' : colors.white,
                    '&:hover': {
                        backgroundColor: isSelected ? '#b6d4fe' : '#f0f0f0',
                    },
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis', flexGrow: 1 }}>
                    {fund.icon ? (
                        <img
                            src={fund.icon}
                            alt={fund.schemeName}
                            style={{
                                width: 40,
                                height: 40,
                                marginRight: 16,
                                borderRadius: '50%',
                                objectFit: 'cover',
                            }}
                        />
                    ) : (
                        <span className='flex w-12 h-12 items-center justify-center bg-lightGrey p-4 rounded-[100%] mr-4 text-primary font-semibold'>
                            {getInitials(fund.schemeName)}
                        </span>
                    )}
                    <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>
                        {titleSlicer(fund.schemeName)}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        sx={{ marginLeft: 'auto', minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis' }}
                    >
                        {fund.endDate}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" sx={{ marginLeft: 'auto', minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis', marginRight: 10 }}>
                        Rs.{fund.fundSize}
                    </Typography>
                </Box>
                <IconButton>
                    <ArrowForwardIosIcon sx={{ color: colors.primary }} />
                </IconButton>
            </Box>
        );
    };

    // console.log('selectedFund', selectedFund);


    return (
        <Modal open={open} onClose={onClose} aria-labelledby="fund-selector-modal" aria-describedby="modal-to-select-funds">
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%', // Adjust width as needed
                maxWidth: 1200,
                maxHeight: '90vh', // Limit height to 90% of viewport height
                bgcolor: colors.lightBg,
                boxShadow: 24,
                p: 4,
                outline: 0,
                borderRadius: 2,
                overflowY: 'auto',
            }}>
                <IconButton
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'grey.300',
                        color: 'grey.800',
                        '&:hover': {
                            backgroundColor: 'grey.400',
                        },
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Typography variant="h6" component="h2" gutterBottom sx={{ color: colors.primary, fontWeight: '600', fontSize: '30px' }}>
                    Choose Fund
                </Typography>
                <form onSubmit={handleSearchSubmit}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search for funds..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        sx={{ mb: 3, backgroundColor: colors.white }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
                <Typography variant="subtitle1" gutterBottom sx={{ color: colors.darkGrey, fontWeight: '600', fontSize: '20px' }}>
                    Recent Searches
                </Typography>
                {/* {searchQuery ?
                    <List >
                        {MFData.map((fund: any) => (
                            <ListItem button key={fund.id} >
                                {renderFundItem(fund)}
                            </ListItem>
                        ))}
                    </List>
                    : */}
                    <List>
                        {fundData.map((fund: any) => (
                            <ListItem button key={fund.id}>
                                {renderFundItem(fund)}
                            </ListItem>
                        ))}
                    </List>
                    {/* } */}
                {/* <Grid container spacing={2}>
                    {fundData
                        .filter((fund: any) => fund.name.toLowerCase()?.includes(searchQuery.toLowerCase()))
                        .map((fund: any) => (
                            <Grid item xs={12} key={fund.id}>
                                {renderFundItem(fund, onClickFund)} 
                            </Grid>
                        ))}
                </Grid>  */}
            </Box>
        </Modal>
    );
};

export default FundSelectorModal;
