import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import FamilyService from "./FamilyService";

export const searchPanNo = createAsyncThunk(
  "user/searchFamily",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await FamilyService.searchPanNo(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const addFamilyMember = createAsyncThunk(
  "user/addMember",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await FamilyService.addFamilyMember(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getFamilyMember = createAsyncThunk(
  "user/getFamily",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await FamilyService.getFamilyMember(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const deleteFamilyMember = createAsyncThunk(
  "user/getFamily",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await FamilyService.deleteFamilyMember(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const deleteFromFamily = createAsyncThunk(
  "user/deleteFamily",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await FamilyService.deleteFromFamily(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  FamilyData: [],
  paymentStatus: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const FamilySlice = createSlice({
  name: "Family",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchPanNo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(searchPanNo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.FamilyData = action.payload.data;
      })
      .addCase(searchPanNo.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(addFamilyMember.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFamilyMember.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.FamilyData = action.payload.data;
      })
      .addCase(addFamilyMember.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getFamilyMember.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFamilyMember.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.FamilyData = action.payload.data;
      })
      .addCase(getFamilyMember.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(deleteFamilyMember.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFamilyMember.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.FamilyData = action.payload.data;
      })
      .addCase(deleteFamilyMember.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(deleteFromFamily.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteFromFamily.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.FamilyData = action.payload.data;
      })
      .addCase(deleteFromFamily.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
  },
});

export const { resetState } = FamilySlice.actions;
export const { reducer: FamilySliceReducer } = FamilySlice;
