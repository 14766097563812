import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Search } from "@mui/icons-material";
import { colors } from "../../constants/colors";
import FDTable from "../../components/FDTable/FDTable";
import dropdown from '../../assets/icons/dropdown.svg'
import { useDispatch } from "react-redux";
import { getFDinfo } from "../../redux/FD/FDSlice";
import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";

const filterOps = [
  {
    name: "Tenure",
    options: [
      { name: "1Y", value: 1 * 365, isChecked: false },
      { name: "3Y", value: 3 * 365, isChecked: false },
      { name: "5Y", value: 5 * 365, isChecked: false },
      { name: "7Y", value: 7 * 365, isChecked: false },
      { name: "8Y", value: 8 * 365, isChecked: false },
      { name: "11Y", value: 11 * 365, isChecked: false },
    ],
  },
  {
    name: "Special Rate",
    options: [
      { name: "Senior citizen", value: "senior citizen", isChecked: false },
      { name: "Woman", value: "woman", isChecked: false },
    ],
  },
  {
    name: "Interest Rate",
    options: [
      { name: "0-5%", value: "0-5", isChecked: false },
      { name: "5-15%", value: "5-15", isChecked: false },
      { name: "15-25%", value: "15-25", isChecked: false },
    ],
  },
];

const FDListing = () => {
  const dispatch = useDispatch<any>();
  const [totalFound, setTotalFound] = useState(28);
  const [filterOptions, setFilterOptions] = useState(filterOps);
  const [checked, setChecked] = React.useState([true, false]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [loader, setLoader] = useState<boolean>(false);
  const [fdData, setFDdata] = useState<any>();
  const [searchFund, setSearchFund] = useState<string>()
  const [inputData, setInputData] = useState({
    pageIndex: 1,
    pageSize: 10000,
    minInvest: 0,
    minTenure: "",
    minInterest: 0,
    maxInterest: 0,
    search: ""
  });


  useEffect(() => {
    setLoader(true);
    fetchData();
  }, []);

   // Fetch FD data on component mount and when search input changes

  useEffect(() => {
    if (inputData.search === "") {
      fetchData();
    }
  }, [inputData.search])

  // Update inputData based on selected filters
  useEffect(() => {
    filterOptions.map((filter: any) => {
      if (filter.name === 'Tenure') {
        const checkedOptions = filter.options.filter((option: any) => option.isChecked)
        if (checkedOptions.length > 0) {
          const checkedValues = checkedOptions.map((option: any) => option.value).join(",")
          // console.log(checkedValues, 'checkedValues')
          setInputData({ ...inputData, minTenure: checkedValues })
        }
      }
      if (filter.name === 'Interest Rate') {
        const checkedOptions = filter.options.filter((option: any) => option.isChecked);
        if (checkedOptions.length > 0) {
          const minInterest = parseInt(checkedOptions[0].value.split('-')[0]);
          const maxInterest = parseInt(checkedOptions[checkedOptions.length - 1].value.split('-')[1]);
          setInputData({ ...inputData, minInterest, maxInterest })
        }
      }
    })
  }, [filterOptions])



  // Fetch data from the API
  const fetchData = async () => {
    try {
      const response = await dispatch(getFDinfo(inputData));
      if (response) {
        setFDdata(response?.payload || []);
        console.log(response?.payload,"FD List");
        setTotalFound(response.payload?.fdList?.length || 0);
        dispatch()
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoader(false);
    }
  };
  // Handle filter menu open and close
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle changes in filter checkboxes
  const handleChange1 = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterIndex: number
  ) => {
    const { checked } = event.target;
    const updatedOptions = [...filterOptions];
    updatedOptions[filterIndex].options.map(
      (option: any) => (option.isChecked = checked)
    );
    // console.log(updatedOptions, event.target.checked, "options");
    setFilterOptions(updatedOptions);
  };

  const handleChange2 = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterIndex: number,
    optionIndex: number
  ) => {
    const { checked } = event.target;
    const updatedOptions = [...filterOptions];
    updatedOptions[filterIndex].options[optionIndex].isChecked = checked;
    setFilterOptions(updatedOptions);
  };

  // Apply filters and close menu
  const handleFilter = () => {
    handleClose();
    fetchData();
  }

  // Filter FD data based on search input
  useEffect(() => {
    if (searchFund) {
      const filteredPlans = fdData.filter((plan: any) =>
        plan.bankName.toLowerCase()?.includes(searchFund.toLowerCase())
      );
      setFDdata(filteredPlans);
    } else {
      fetchData();
      // toast.error('Not Found')
    }
  }, [searchFund])

  // Breadcrumb items
  const breadcrumbItems = [
    { label: 'Home', href: '/' }, 
    { label: 'Investment', href: '/' },// Breadcrumb item with link
    { label: 'Corporate FD', }, // Current page, no link
  ];

  return (
    <div className="">
      <div className="bg-lightBg">
        <div className="md:py-[30px] md:px-[30px] max-w-[1440px] justify-center items-center mx-auto">
          <div className="pb-4 border-b border-lightGrey mb-8">
            <DynamicBreadcrumbs items={breadcrumbItems} />
          </div>
          <div className="w-full flex md:flex-row gap-x-6 ">
            <div className="hidden sm:flex md:w-[400px] md:h-[150px] md:bg-white items-center justify-center align-middle relative rounded-full">
              {/* Center image */}
              <div className="flex items-center justify-center w-full h-full">
                <img
                  className="w-[80px]"
                  src={dropdown}
                  alt="center-image"
                />
              </div>
            </div>

            <div className="flex flex-col gap-y-3 ">
              <div className="flex gap-x-8 items-center border-b-2">
                <div className="flex flex-row w-full justify-between pb-4">
                  <div>
                    <h3 className="text-3xl font-medium text-primary">Corporate FD</h3>
                  </div>
                </div>
              </div>
              <p className="mt-1 text-lg text-stone-500 max-md:max-w-full">
              Corporate FD is a term deposit held over a fixed period at fixed interest rates. Company Fixed Deposits are offered by Financial and Non-Banking financial companies (NBFCs). The maturities of various company fixed deposits can range from a few months to a few years.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[1440px] w-full mx-auto p-8">
        <div className="w-full my-8 flex justify-between gap-x-3">
          <TextField
            className="w-full"
            onChange={(e) => {
              setSearchFund(e.target.value);
            }}
            placeholder="Search corporate FD funds"
            hiddenLabel
            id="filled-hidden-label-small"
            size="small"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div>
          <FDTable fdData={fdData} loader={loader} />
        </div>
      </div>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <Box>
          <div className="flex items-center justify-between bg-gray-700_01 px-5 py-3 -mt-2 sticky">
            <Typography variant="subtitle2" color="white">
              Filter
            </Typography>
            <IconButton onClick={handleClose} sx={{ color: colors.white }}>
              <Close fontSize="small" />
            </IconButton>
          </div>

          <Box sx={{ padding: "1rem 2rem", width: "26rem" }} >
            <h6 className="text-md font-bold mb-2 text-primary">Fund Type</h6>
            {filterOptions.map((filter: any, index: number) => (
              <Accordion key={index}>
                <AccordionSummary
                  expandIcon={<Add />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  style={{
                    backgroundColor: "#F3F8FF"
                  }}
                >
                  <FormControlLabel

                    label={filter.name}
                    control={
                      <Checkbox
                        checked={filter.options.every(
                          (option: any) => option.isChecked
                        )}
                        indeterminate-={true}
                        onChange={(event) => handleChange1(event, index)}

                      />
                    }
                  />
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                    {filter.options.map((option: any, j: number) => (
                      <FormControlLabel
                        key={j}
                        label={option.name}
                        control={
                          <Checkbox
                            checked={option.isChecked}
                            onChange={(event) => handleChange2(event, index, j)}
                          />
                        }
                      />
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
            <Button
              sx={{
                backgroundColor: colors.bgGrey,
                borderRadius: 2,
                textTransform: "none",
                padding: "0.5rem 2rem",
                color: "#000",
                marginTop: "1rem",
              }}
              onClick={handleFilter}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Menu>
    </div>
  );
};

export default FDListing;
