import React, { useEffect, useState } from "react";
import {
  Button,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Modal,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  InputAdornment,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SuccessfullModal from "../../components/modals/SuccessfullModal";
import { SelectChangeEvent } from "@mui/material";
import {
  addFamilyMember,
  deleteFamilyMember,
  deleteFromFamily,
  getFamilyMember,
  searchPanNo,
} from "../../redux/Family/FamilySlice";
import { useDispatch } from "react-redux";
import api from "../../config/apiConfig";
import VerifyOTPModal from "../../components/modals/VerifyOTPModal";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from "@mui/icons-material/Delete";
import { colors } from "../../constants/colors";
import { LoaderIcon } from "react-hot-toast";
import ConfirmationModal2 from "../../components/modals/ConfirmationModal2";
import { getUserById } from "../../redux/user/userSlice";
import {  TextField } from "@mui/material";

// Enum for relationship options
const relationshipOptions = [
  { label: "AUNT", value: 1 },
  { label: "BROTHER-IN-LAW", value: 2 },
  { label: "BROTHER", value: 3 },
  { label: "DAUGHTER", value: 4 },
  { label: "DAUGHTER-IN-LAW", value: 5 },
  { label: "FATHER", value: 6 },
  { label: "FATHER-IN-LAW", value: 7 },
  { label: "GRAND DAUGHTER", value: 8 },
  { label: "GRAND FATHER", value: 9 },
  { label: "GRAND MOTHER", value: 10 },
  { label: "GRAND SON", value: 11 },
  { label: "MOTHER-IN-LAW", value: 12 },
  { label: "MOTHER", value: 13 },
  { label: "NEPHEW", value: 14 },
  { label: "NIECE", value: 15 },
  { label: "SISTER", value: 16 },
  { label: "SISTER-IN-LAW", value: 17 },
  { label: "SON", value: 18 },
  { label: "SON-IN-LAW", value: 19 },
  { label: "SPOUSE", value: 20 },
  { label: "UNCLE", value: 21 },
  { label: "OTHERS", value: 22 },
  { label: "COURT APPOINTED LEGAL GUARDIAN", value: 23 },
];

// // Regular expression for PAN number validation
const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;

const AddFamilyMember: React.FC = () => {
  const dispatch = useDispatch<any>();
  const [data, setData] = useState<any[]>([]);
  const [AccessMemberData, setAccessMemberData] = useState<any[]>([]);
  const [addMode, setAddMode] = useState(false);
  const [panNumber, setPanNumber] = useState("");
  const [isValidPan, setIsValidPan] = useState(false);
  const [searchResults, setSearchResults] = useState<
    {
      firstName: string;
      isFamilyMember: number;
      id: number;
    }[]
  >([]);
  const [selectedRelationship, setSelectedRelationship] = useState<
    number | null
  >(null);
  const [memberID, setMemberID] = useState<number | null>(null);
  const [familyID, setFamilyID] = useState<number | null>(null);
  const [memberName, setMemberName] = useState<String | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFamilyMember, setSelectedFamilyMember] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSummary, setShowSummary] = useState(false);
  const [Open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [loadingModal, setLoadingModal] = useState(false);
  const [ConfirmOpen, setConfirmOpen] = useState(false);
  const [Accessloading, setAccessLoading] = useState<boolean>(false);
  const [accessCheck, setAccessCheck] = useState(false);

  const fetchAllMembers = async () => {
    setLoading(true);
    try {
      const Response = await dispatch(getFamilyMember({}));
      setData(Response?.payload?.data?.data);
    } catch (error) {
      setShowSummary(false);
      setOpen(true);
      setErrorMessage(
        "Failed due to server issues. Please try again after some time!"
      );
    } finally {
      setLoading(false);
    }
  };

  const fetchAccessMembers = async () => {
    setAccessLoading(true);
    try {
      const Response = await dispatch(getUserById({}));
      setAccessMemberData(Response?.payload.data[0]?.myFamilies);
    } catch (error) {
      setOpen(true);
      setErrorMessage(
        "Failed due to server issues. Please try again after some time!"
      );
    } finally {
      setAccessLoading(false);
    }
  };

  useEffect(() => {
    fetchAllMembers();
    fetchAccessMembers();
  }, [dispatch]);

  const handleAddMemberClick = () => {
    setAddMode(true);
  };

  const handlePanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim().toUpperCase(); // Ensure the input is uppercase and trimmed
    setPanNumber(value);

    // Check if the PAN number is valid
    if(value?.length===10){
    const isValid = panRegex.test(value);
    setIsValidPan(isValid);

    if (isValid) {
      setErrorMessage("");
      searchPan(value); // Call searchPan immediately when PAN is valid
    } else {
      setErrorMessage("Please enter a valid PAN number.");
      setSearchResults([]); // Clear search results when PAN is invalid
    }
  }

    // Reset family member and relationship fields if PAN is deleted
    if (value === "") {
      setSelectedFamilyMember("");
      setSelectedRelationship(null);
    }
  };

  // Function to search for PAN number via API
  const searchPan = async (pan: string) => {
    try {
      const response = await dispatch(searchPanNo({ pancard: pan }));

      if (response.payload.data && response.payload.data.data) {
        const resultData = response.payload.data.data;

        // Since resultData is a single object, not an array, handle it directly
        const searchResults = resultData
          ? [
              {
                firstName: resultData.firstName,
                isFamilyMember: resultData.isFamilyMember,
                id: resultData.id,
              },
            ]
          : [];

        setSelectedFamilyMember(searchResults[0]?.firstName);

        if (searchResults.length === 0) {
          setOpen(true);
          setErrorMessage("No data found for this PAN number.");
        } else if (searchResults[0]?.isFamilyMember === 1) {
          setOpen(true);
          setErrorMessage("This member is already exixts in your account.");
        }
        setSearchResults(searchResults);
      } else {
        setOpen(true);
        setErrorMessage("No data found for this PAN number.");
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("An error occurred while searching.");
    } finally {
      setLoading(false);
    }
  };

  // Handle relationship select
  const handleRelationshipChange = (event: SelectChangeEvent<number>) => {
    setSelectedRelationship(event.target.value as number);
  };

  // Handle Add button click
  const handleAddClick = async () => {
    if (selectedFamilyMember && selectedRelationship !== null) {
      setLoadingModal(true);
      const accessToken = localStorage.getItem("accessToken");
      try {
        const response = await api.post(
          "user/sendOTP",
          { memberId: searchResults[0]?.id, otpFor: 2 },
          {
            headers: {
              Authorization: accessToken,
            },
          }
        );
        setLoadingModal(false);
        setShowSummary(true);
      } catch (error: any) {
        setLoadingModal(false);
        setOpen(true);
        console.error(
          "Error in API call:",
          error.response || error.message || error
        );
        setErrorMessage("An error occurred while adding the family member.");
      }
    }
  };

  const handleSubmit = async () => {
    setShowSummary(false);
    setLoading(true);
    try {
      const verifyResponse = await dispatch(
        addFamilyMember({
          memberId: searchResults[0]?.id,
          relation: selectedRelationship,
          emailOTP: otp,
        })
      );
      if (verifyResponse.payload.success === 400) {
        setTimeout(() => {
          setLoading(false);
          fetchAllMembers();
          setOpen(true);
          setErrorMessage(verifyResponse?.payload.message);
        }, 3000);
      }

      if (verifyResponse.payload.data.success === 200) {
        setTimeout(() => {
          fetchAllMembers();
          setLoading(false);
          setOpen(true);
          setMessage("Family member added successfully.");
        }, 3000);
      } else {
        setTimeout(() => {
          fetchAllMembers();
          setLoading(false);
          setOpen(true);
          setErrorMessage(verifyResponse?.payload.message);
        }, 3000);
      }
    } catch (error) {
      setTimeout(() => {
        fetchAllMembers();
        setLoading(false);
        setOpen(true);
        setErrorMessage(
          "Failed due to server issues.Please try again after sometimes!"
        );
      }, 3000);
    } finally {
      setAddMode(false);
    }
  };

  const handleClose = async() => {
    setOpen(false);
    setPanNumber("");
    setSelectedRelationship(null);
    setOpen(false);
    setIsValidPan(false);
    setSearchResults([]);
    setSelectedFamilyMember("");
    setSelectedRelationship(null);
    setErrorMessage("");
    setShowSummary(false);
    setMessage("");
    setOtp("");
    setLoadingModal(false);
    setAddMode(false);
    await dispatch(getUserById({}));
  };

  const getRelationshipLabel = (value: number): string => {
    const relationship = relationshipOptions.find(
      (option) => option.value === value
    );
    return relationship ? relationship.label : "Unknown";
  };

  const handleDelete = async () => {
    setConfirmOpen(false);
    try {
      const verifyResponse = await dispatch(
        deleteFamilyMember({
          memberId: memberID,
        })
      );
      if (verifyResponse.payload.success === 400) {
        await fetchAllMembers();
        setShowSummary(false);
        setOpen(true);
        setErrorMessage(verifyResponse?.payload.message);
      }

      if (verifyResponse.payload.data.success === 200) {
        await fetchAllMembers();
        setShowSummary(false);
        setOpen(true);
        setMessage("Family member removed successfully.");
      } else {
        await fetchAllMembers();
        setShowSummary(false);
        setOpen(true);
        setErrorMessage(verifyResponse?.payload.message);
      }
    } catch (error) {
      await fetchAllMembers();
      setShowSummary(false);
      setOpen(true);
      setErrorMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }finally{
      await dispatch(getUserById({}));
    }
  };

  const handleConfirm = (memberId: any, name: string) => {
    setMessage(`Do you want to remove ${name?.toLowerCase()} to your family member?`);
    setMemberID(memberId);
    setMemberName(name);
    setConfirmOpen(true);
  };
  const handleConfirmClose = () => {
    setConfirmOpen(false);
  };

  const toCamelCase = (str: string): string => {
    if (!str) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const handleFamilyDelete = async () => {
    setConfirmOpen(false);
    try {
      const verifyResponse = await dispatch(
        deleteFromFamily({
          familyId: familyID,
        })
      );
      if (verifyResponse.payload.success === 400) {
        await fetchAccessMembers();
        setShowSummary(false);
        setOpen(true);
        setErrorMessage(verifyResponse?.payload.message);
      }

      if (verifyResponse.payload.data.success === 200) {
        await fetchAccessMembers();
        setShowSummary(false);
        setOpen(true);
        setMessage(`You are removed successfully from the ${memberName?.toLowerCase()} family.`);
      } else {
        await fetchAccessMembers();
        setShowSummary(false);
        setOpen(true);
        setErrorMessage(verifyResponse?.payload.message);
      }
    } catch (error) {
      await fetchAccessMembers();
      setShowSummary(false);
      setOpen(true);
      setErrorMessage(
        "Failed due to server issues.Please try again after sometimes!"
      );
    }finally{
      setAccessCheck(false);
      await dispatch(getUserById({}));
    }
  };

  const handleFamilyConfirm = (familyId: any, name: string) => {
    setAccessCheck(true);
    setMessage(`Do you want to remove yourself from the ${name?.toLowerCase()} family?`);
    setFamilyID(familyId);
    setMemberName(name);
    setConfirmOpen(true);
  };

  const closetherow =()=>{
    setPanNumber("");
    setSelectedRelationship(null);
    setOpen(false);
    setIsValidPan(false);
    setSearchResults([]);
    setSelectedFamilyMember("");
    setSelectedRelationship(null);
    setErrorMessage("");
    setShowSummary(false);
    setMessage("");
    setOtp("");
    setLoadingModal(false);
    setAddMode(false);
  }

  return (
    <>
      <div className="m-auto w-[75%] my-10">
        <div className="flex justify-between mb-4">
          <Typography
            sx={{ color: colors.primary, fontWeight: 600, fontSize: "2rem" }}
          >
            Add Family Member
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddMemberClick}
          >
            Add Member
          </Button>
        </div>

        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 400,
            overflowY: "auto",
            overflowX: "auto",
            boxShadow: "none",
            border: "2px solid #ccc",
          }}
        >
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead
              sx={{
                backgroundColor: colors.lightBg,
                borderBottom: "2px solid #ccc",
                "& th": {
                  backgroundColor: colors.lightBg,
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                  borderBottom: "2px solid #ccc",
                },
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    color: colors.primary,
                    fontSize: "16px",
                  }}
                >
                  PAN Number
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    color: colors.primary,
                    fontSize: "16px",
                  }}
                >
                  Member Name
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    color: colors.primary,
                    fontSize: "16px",
                  }}
                >
                  Relationship
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: 600,
                    color: colors.primary,
                    fontSize: "16px",
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {addMode && (
                <TableRow>
                  <TableCell className="flex flex-col">
                    <TextField
                      id="pan-number"
                      label="Enter PAN Number"
                      value={panNumber.toUpperCase()}
                      onChange={handlePanChange}
                      // error={!isValidPan && panNumber.length > 0}
                      // helperText={!isValidPan ? errorMessage : ""}
                      autoComplete="off"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => searchPan(panNumber)}
                              disabled={!isValidPan}
                            >
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />{(panNumber?.length >= 10 && !isValidPan) && (
                      <FormHelperText style={{ color: "rgb(241,122,33)" }}>{errorMessage}</FormHelperText>
                    )}
                  </TableCell>
                  <TableCell>
                    {searchResults.length > 0 && (
                      <TextField
                        id="family-member-name"
                        value={searchResults[0]?.firstName || ""}
                        InputProps={{
                          readOnly: true,
                        }}
                        sx={{
                          marginLeft: "-5%",
                          backgroundColor: "white",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "transparent", // Removes the border
                          },
                          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "transparent", // Removes border on hover
                            },
                          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: "transparent", // Removes border focus
                            },
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {isValidPan &&
                      selectedFamilyMember &&
                      searchResults[0]?.isFamilyMember !== 1 && (
                        <FormControl fullWidth>
                          <InputLabel id="relationship-label">
                            Relationship
                          </InputLabel>
                          <Select
                            labelId="relationship-label"
                            id="relationship"
                            label="Relationship"
                            value={selectedRelationship || ""}
                            onChange={handleRelationshipChange}
                            disabled={!selectedFamilyMember}
                          >
                            {relationshipOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                  </TableCell>
                  <TableCell align="center">
                    {isValidPan &&
                      selectedFamilyMember &&
                      searchResults[0]?.isFamilyMember !== 1 && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleAddClick}
                          disabled={!selectedRelationship}
                        >
                          Verify OTP
                        </Button>
                      )}
                      <DeleteIcon style={{color:"rgb(241,122,33)"}}
                        fontSize="large" sx={{marginLeft:isValidPan? 3 :""}} onClick={closetherow}/>
                  </TableCell>
                </TableRow>
              )}
              {loading ? (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    align="center"
                    sx={{ fontSize: "1.2rem", color: colors.primary }}
                  >
                    <div className="flex justify-center items-center h-[30vh] w-full">
                      <LoaderIcon style={{ width: "60px", height: "60px" }} />
                    </div>
                  </TableCell>
                </TableRow>
              ) : data?.length > 0 ? (
                data?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ fontSize: "16px" }}>
                      {row?.pancardNumber}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }}>
                      {row?.firstName}
                    </TableCell>
                    <TableCell sx={{ fontSize: "16px" }}>
                      {getRelationshipLabel(row?.relation)}
                    </TableCell>
                    <TableCell
                      sx={{ fontSize: "16px", cursor: "pointer" }}
                      align="center"
                    >
                      <DeleteIcon
                        style={{color:"rgb(241,122,33)"}}
                        fontSize="large"
                        onClick={() =>
                          handleConfirm(row?.memberId, row?.firstName)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    align="center"
                    sx={{ fontSize: "1.2rem", color: colors.primary }}
                  >
                    No family member is added.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="m-auto w-[75%] my-10">
        <div className="flex justify-between mb-4">
          <Typography
            sx={{ color: colors.primary, fontWeight: 600, fontSize: "2rem" }}
          >
            Member with view access
          </Typography>
        </div>

        <TableContainer
          component={Paper}
          sx={{
            maxHeight: 400,
            overflowY: "auto",
            overflowX: "auto",
            boxShadow: "none",
            border: "2px solid #ccc",
          }}
        >
          <Table aria-label="collapsible table" stickyHeader>
            <TableHead
              sx={{
                backgroundColor: colors.lightBg,
                borderBottom: "2px solid #ccc",
                "& th": {
                  backgroundColor: colors.lightBg,
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize: "16px",
                  borderBottom: "2px solid #ccc",
                },
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    fontWeight: 600,
                    color: colors.primary,
                    fontSize: "16px",
                    flexGrow: 1,
                    width: "82%",
                  }}
                >
                  Member Name
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    fontWeight: 600,
                    color: colors.primary,
                    fontSize: "16px",
                    width: "full", 
                  }}                  
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Accessloading ? (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    align="center"
                    sx={{ fontSize: "1.2rem", color: colors.primary }}
                  >
                    <div className="flex justify-center items-center h-[30vh] w-full">
                      <LoaderIcon style={{ width: "60px", height: "60px" }} />
                    </div>
                  </TableCell>
                </TableRow>
              ) : AccessMemberData?.length > 0 ? (
                AccessMemberData?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        flexGrow: 1,
                        width: "82%",
                        textAlign: "left",
                      }}
                    >
                      {toCamelCase(row?.ownerName)} has added you.
                    </TableCell>
                    <TableCell
                      sx={{
                        fontSize: "16px",
                        cursor: "pointer",
                        textAlign: "center", // Align the content to the far end
                        width: "full", // Ensures it takes minimal width required
                      }}
                      align="center"
                    >
                      <DeleteIcon
                        style={{color:"rgb(241,122,33)"}}
                        fontSize="large"
                        onClick={() =>
                          handleFamilyConfirm(row?.familyId, row?.ownerName)
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={4}
                    align="center"
                    sx={{ fontSize: "1.2rem", color: colors.primary }}
                  >
                    No family member is available with view access.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <VerifyOTPModal
          title="Verify OTP Sent to your mobile and email"
          showModal={showSummary}
          setShowModal={setShowSummary}
          otp={otp}
          setOtp={setOtp}
          onClick={handleSubmit}
        />

        <ConfirmationModal2
          isVisible={ConfirmOpen}
          message={message}
          onConfirm={accessCheck?handleFamilyDelete:handleDelete}
          onCancel={handleConfirmClose}
        />

        <SuccessfullModal
          open={Open}
          message={message !== "" ? message : errorMessage}
          handleClose={handleClose}
        />
        <Modal
          open={loadingModal}
          aria-labelledby="loading-modal-title"
          aria-describedby="loading-modal-description"
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <div className="w-[30%] h-[30%] flex flex-col items-center justify-center bg-lightBg rounded-lg">
              <Typography
                id="loading-modal-title"
                variant="subtitle1"
                component="h2"
                marginBottom={5}
              >
                {`please wait...`}
              </Typography>
              <CircularProgress />
            </div>
          </Box>
        </Modal>
    </>
  );
};

export default AddFamilyMember;
