import { Card, CardContent, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { colors } from "../../constants/colors";
import { PieChart } from "@mui/x-charts";

function PieChart_Component({ data, additionalData }: any) {
  const [chartData, setchartData] = useState([]);
  const [innerRadius, setInnerRadius] = useState(120);
  const [outerRadius, setOuterRadius] = useState(150);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 440) {
        setInnerRadius(100);
        setOuterRadius(120);
      } else {
        setInnerRadius(120);
        setOuterRadius(150);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially to set the right value based on the initial screen size

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const hasData =
    (data?.totalValueInvestedMF ?? 0) > 0 ||
    (data?.totalValueInvestedAif ?? 0) > 0 ||
    (data?.totalValueInvestedPms ?? 0) > 0 ||
    (data?.totalValueInvestedBond ?? 0) > 0 ||
    (data?.totalValueInvestedFD ?? 0) > 0 ||
    (data?.totalValueInvestedStock ?? 0) > 0;
  const totalValueInvestedMF = hasData
    ? Number(additionalData[0]?.totalAmount) || 0
    : 0;
  const totalValueInvestedFD = hasData
    ? Number(data?.totalValueInvestedFD) || 0
    : 0;
  const totalValueInvestedAif = hasData
    ? Number(data?.totalValueInvestedAif) || 0
    : 0;
  const totalValueInvestedPms = hasData
    ? Number(data?.totalValueInvestedPms) || 0
    : 0;
  const totalValueInvestedBond = hasData
    ? Number(data?.totalValueInvestedBond) || 0
    : 0;
  const totalValueInvestedStock = hasData
    ? Number(data?.totalValueInvestedStock) || 0
    : 0;

  return (
    <div className="h-full flex flex-col">
      <Card className="flex-grow flex flex-col">
        <CardContent className="flex-grow flex flex-col">
          {/* <div className="flex flex-row gap-4 justify-between mt-5">
                        <div className='flex flex-col justify-between items-center' style={{ width: "100%", color: colors.textGrey }}>
                            <h6 className="font-bold">Mutual Fund: </h6>
                            <span className="font-inter">{additionalData[0]?.totalAmount}</span>
                        </div>
                        <div className='flex flex-col justify-between items-center' style={{ width: "100%", color: colors.textGrey }}>
                            <h6 className="font-bold">Fixed Deposit: </h6>
                            <span className="font-inter">{data?.totalValueInvestedFD}</span>
                        </div>
                    </div> */}

          <div className="flex-grow flex items-center justify-center">
            <PieChart
              series={[
                {
                  data: [
                    {
                      id: 0,
                      value: totalValueInvestedMF,
                      label: "Mutual Fund",
                    },
                    { id: 1, value: totalValueInvestedAif, label: "AIF" },
                    { id: 2, value: totalValueInvestedPms, label: "PMS" },
                    { id: 3, value: totalValueInvestedBond, label: "Bond" },
                    {
                      id: 4,
                      value: totalValueInvestedFD,
                      label: "Fixed Deposit",
                    },
                    { id: 5, value: totalValueInvestedStock, label: "Equity" },
                  ],
                  innerRadius: innerRadius,
                  outerRadius: outerRadius,
                  paddingAngle: 0,
                  cornerRadius: 0,
                  startAngle: 0,
                  endAngle: 360,
                },
              ]}
              colors={["#97C5F0", "#C3F3E0", "#E0C0F4", "#F99BAB", "#FFB44F"]}
              sx={{ width: "100%", padding: 0, margin: 0, border: "none" }}
              height={300}
              margin={{ top: 0, bottom: 0, left: 0, right: 0 }}
              slotProps={{
                legend: { hidden: true },
                // legend: {
                //     direction: 'row',
                //     position: { vertical: 'bottom', horizontal: 'middle' },
                //     padding: 0,
                //     itemGap: 10,
                // },
              }}
            />
          </div>

          <div className="flex flex-col gap-4 justify-between mt-5">
            {totalValueInvestedMF > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#97C5F0]"></span>
                  <h6 className="font-bold">Mutual Fund: </h6>
                </div>
                <span className="font-inter">
                  {totalValueInvestedMF.toFixed(2)}
                </span>
              </div>
            )}
            {totalValueInvestedAif > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#E0C0F4]"></span>
                  <h6 className="font-bold">AIF: </h6>
                </div>
                <span className="font-inter">
                  {totalValueInvestedAif.toFixed(2)}
                </span>
              </div>
            )}

            {totalValueInvestedPms > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#F99BAB]"></span>
                  <h6 className="font-bold">PMS: </h6>
                </div>
                <span className="font-inter">
                  {totalValueInvestedPms.toFixed(2)}
                </span>
              </div>
            )}

            {totalValueInvestedBond > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#FFB44F]"></span>
                  <h6 className="font-bold">Bond: </h6>
                </div>
                <span className="font-inter">
                  {totalValueInvestedBond.toFixed(2)}
                </span>
              </div>
            )}

            {totalValueInvestedFD > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#C3F3E0]"></span>
                  <h6 className="font-bold">Fixed Deposit: </h6>
                </div>
                <span className="font-inter">
                  {totalValueInvestedFD.toFixed(2)}
                </span>
              </div>
            )}

            {totalValueInvestedStock > 0 && (
              <div
                className="flex justify-between items-center"
                style={{ width: "100%", color: colors.textGrey }}
              >
                <div className="flex w-full items-center gap-x-2">
                  <span className="w-3 h-3 rounded-3xl bg-[#FFB44F]"></span>
                  <h6 className="font-bold">Equity: </h6>
                </div>
                <span className="font-inter">
                  {totalValueInvestedStock.toFixed(2)}
                </span>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export default PieChart_Component;
