import React from "react";
import {
  TableRow,
  TableCell
} from "@mui/material";
import getInitials from "../../utils/getInitailasIMGUtils";


const TableRowComponent = ({ data }: any) => {
  const isArray = Array.isArray(data);

  const isValidAssetName = (assetName: string) => {
    return assetName && assetName !== "-" && assetName.trim() !== "";
  };

  return (
    <>
      {isArray &&
        data?.map((item: any, index: number) => {
          return (            
            <>
            {item?.stockName && isValidAssetName(item?.stockName) && (
              <React.Fragment key={item.id}>
              <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg cursor-pointer">
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ border: "none" }}
                >
                  <div className="flex gap-x-1 items-center">
                    <div className="w-3/5">
                      <div
                        className="flex cursor-pointer items-center"
                        // onClick={() => handleToggleRow(index)}
                      >
                        <span className="flex w-12 h-12 items-center justify-center bg-lightGrey p-4 rounded-[100%] mr-4 text-primary font-semibold">
                {getInitials(item.stockSymbol)}
              </span>
              <span className="font-bold text-primary">{item.stockSymbol}</span>
                      </div>
                    </div>
                  </div>
                </TableCell>

                <TableCell
                  sx={{ border: "none" }}
                >
                  {item?.stockName || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                >
                  {item?.stockSeries || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                >
                  {item?.dateOfListing || "-"}
                </TableCell>
                <TableCell
                  sx={{ border: "none" }}
                  align="center"
                >
                  {item?.faceValue ||"-"}
                </TableCell>
                </TableRow>
              {item?.stockName && isValidAssetName(item?.stockName) && (
              <TableRow sx={{ height: "5%" }}>
                <TableCell
                  sx={{ border: "none" }}
                  colSpan={8}
                  align="center"
                ></TableCell>
              </TableRow>)}
            </React.Fragment>
        )}
            </>
          );
        })}
    </>
  );
};

export default TableRowComponent;
