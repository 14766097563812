import React, { useEffect, useState } from "react";
import { Divider } from "@mui/material";

import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import { useDispatch } from "react-redux";
import OrderHistoryTable from "../../components/OrderHistoryTable/OrderHistoryTable";
import { getAllOrderHistory } from "../../redux/OrderHistory/OrderSlice";

const MFheadings = ["createdAt","schemeName", "amount", "paymentStatus"];

const FDheadings = ["createdAt", "schemeName", "amount", "orderStatus"];

const AIFheadings = ["Date", "SchemeName", "Price", "paymentStatus"];

const PMSheadings = ["Date", "SchemeName", "Price", "Status"];


const OrderHistory = () => {
  const dispatch = useDispatch<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showScrollbar, setShowScrollbar] = useState(false);
  const [MFrows, setMFrows] = useState([]);
  const [FDrows, setFDrows] = useState([]);
  const [AIFrows, setAIFrows] = useState([]);
  const [PMSrows, setPMSrows] = useState([]);

  const handleMouseEnter = () => {
    setShowScrollbar(true);
  };

  const handleMouseLeave = () => {
    setShowScrollbar(false);
  };

  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Orders" }, // Breadcrumb item with link
  ];
  const fetchData = async () => {
    try {
      const response = await dispatch(getAllOrderHistory({}));

      console.log(response,"Order history Response");

      if (response) {
        let res = response?.payload?.data?.data || [];
        setMFrows(res?.mutualFund);
        setFDrows(res?.fd);
      }
    } catch (error) {
      console.error("API Error:", error);
    } 
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div className="px-8 pt-8 md:p-[40px] max-w-[1440px] w-full mx-auto flex flex-col gap-y-2">
        <DynamicBreadcrumbs items={breadcrumbItems} />
        <Divider sx={{ my: 2 }} />{" "}
        {/* Adds a division between the breadcrumbs and the content below */}
        <div className="flex flex-row gap-x-2 items-center w-full">
          <div>
            <h1 className="text-3xl font-medium text-gray-900">
              Orders History
            </h1>
          </div>
        </div>
      </div>
      {MFrows && MFrows?.length > 0 && (
        <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
          <div className="">
            <div className="flex flex-col">
              <div className="flex gap-x-8 items-center">
                <div className="flex flex-row w-full justify-start pb-4">
                  <div>
                    <h1 className="text-2xl font-semibold text-primary">
                      Mutual Funds
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <OrderHistoryTable headings={MFheadings} rows={MFrows} />
            </div>
          </div>
        </div>
      )}
      {FDrows && FDrows?.length > 0 && (
        <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
          <div className="">
            <div className="flex flex-col">
              <div className="flex gap-x-8 items-center">
                <div className="flex flex-row w-full justify-start pb-4">
                  <div>
                    <h1 className="text-2xl font-semibold text-primary">
                      Fixed Deposits
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <OrderHistoryTable headings={FDheadings} rows={FDrows} />
            </div>
          </div>
        </div>
      )}
      {AIFrows && AIFrows?.length > 0 && (
        <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
          <div className="">
            <div className="flex flex-col">
              <div className="flex gap-x-8 items-center">
                <div className="flex flex-row w-full justify-start pb-4">
                  <div>
                    <h1 className="text-2xl font-semibold text-primary">AIF</h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <OrderHistoryTable headings={AIFheadings} rows={AIFrows} />
            </div>
          </div>
        </div>
      )}
      {PMSrows && PMSrows?.length > 0 && (
        <div className="px-10 max-w-[1440px] w-full mx-auto mb-10">
          <div className="">
            <div className="flex flex-col">
              <div className="flex gap-x-8 items-center">
                <div className="flex flex-row w-full justify-start pb-4">
                  <div>
                    <h1 className="text-2xl font-semibold text-primary">PMS</h1>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <OrderHistoryTable headings={PMSheadings} rows={PMSrows} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderHistory;
