import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { colors } from "../../constants/colors";
import toast from "react-hot-toast";
import {
  getFDInterestRate,
  getFDPrinciple,
  getFDTenure,
} from "../../redux/FD/FDSlice";
import { useDispatch } from "react-redux";
import { useDebouncedCallback } from "use-debounce";
import { PieChart } from "@mui/x-charts";
import ConfirmationModal from "../modals/ConfirmationModal2";
import convertMoneyStringToNumber from "../../utils/convertMoneyStringToNumber";

interface InterestRates {
  monthly: string;
  quaterly: string;
  halfYearly: string;
  annual: string;
  cumulativeDeposit: string;
  annualisedYield: string;
}

interface SpecialInterestRates {
  monthly: string;
  quaterly: string;
  halfYearly: string;
  annual: string;
  cumulativeDeposit: string;
  annualisedYield: string;
}

interface Plan {
  id: number;
  fdName: string;
  ratings: string;
  minInvestAmount: {
    monthly: number;
    quaterly: number;
    halfYearly: number;
    annual: number;
    cumulativeDeposit: number;
    annualisedYield: number;
  };
  maxInvestAmount: number;
  minTenureinDays: number;
  maxTenureinDays: number;
  interestRates: InterestRates;
  womenInterestRates: SpecialInterestRates;
  seniorCitizenInterestRates: SpecialInterestRates;
}

interface Calculation {
  schemeId: number;
  investTenure: any;
  investAmount: number;
  interestFrequency: string;
  investmentPayoutTerm: string;
  investmentSpecialBenefits: string;
  maturityAmount?: number; // Change any to number
  return?: string;
  interestRate?: any; // Keep it optional
  grossInterest: any; // Consider specifying the type
  maturityDate: number;
}
interface TenureObject {
  id: number;
  minTenure: number;
  maxTenure: number;
}

const calculateTenure = (months: any) => {
  let tenure = 0;
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  for (let i = 0; i < months; i++) {
    const month = (currentMonth + i) % 12;
    const year = currentYear + Math.floor((currentMonth + i) / 12);
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    tenure += daysInMonth;
  }

  return tenure;
};

const debounce = (func: Function, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const debouncedToast = debounce((message: string) => {
  toast(message);
}, 500);

interface TenureState {
  minTenure: number | null;
  maxTenure: number | null;
  id: number;
}

const Calculator = ({
  fdData,
  onUpdate,
  data,
}: {
  fdData?: any;
  onUpdate?: any;
  data?: any;
}) => {
  const [tenureState, setTenureState] = useState<TenureState>({
    minTenure: null,
    maxTenure: null,
    id: 0,
  });
  const [availableOptions, setAvailableOptions] = useState<any>([]);
  const dispatch = useDispatch<any>();
  const [minInvestment, setMinInvestment] = useState(0);
  const [calculation, setCalculation] = useState<Calculation>({
    schemeId: 0,
    investAmount: Number.isNaN(minInvestment) ? 0 : minInvestment,
    investTenure: tenureState.minTenure || 0,
    interestRate: "", // Initialize as undefined to match the interface
    interestFrequency: "",
    grossInterest: 0,
    maturityAmount: 0,
    maturityDate: 0,
    return: "",
    investmentPayoutTerm: "",
    investmentSpecialBenefits: "No",
  });
  const [finalRes, setFinalRes] = useState<any>({});
  const [MatchTenure, setMatchTenure] = useState<any>();
  const interestFrequencyuency = [
    { value: "choose", label: "choose" },
    { value: "monthly", label: "Monthly" },
    { value: "days", label: "Days" },
  ];
  const [tenure, setTenure] = useState({
    minTenure: "",
    maxTenure: "",
  });
  const [interestRate, setInterestRate] = useState<any>();

  console.log(fdData,"fdData")

  const CalculateFn = () => {
    if (
      !calculation.investTenure ||
      !calculation.investmentPayoutTerm ||
      !finalRes.interestRates
    ) {
      toast.error("Please fill all fields");
    }

    if (minInvestment && calculation.investAmount < minInvestment) {
      toast.error(
        `Invested amount should be greater than or equal ${minInvestment} to the minimum investment amount.`
      );
      return;
    }

    const { interestRates } = finalRes;
    const { investmentPayoutTerm, investmentSpecialBenefits } = calculation;
    // console.log('interestRates', interestRates);

    if (calculation.investmentPayoutTerm) {
      if (calculation.investmentSpecialBenefits) {
        const getRate = () => {
          if (!investmentPayoutTerm || !interestRates) return undefined;

          switch (investmentSpecialBenefits) {
            case "seniorCitizen":
              return interestRates.seniorCitizen[investmentPayoutTerm];
            case "womenInterestRates":
              return interestRates.womenInterestRates[investmentPayoutTerm];
            default:
              return interestRates.interestRate[investmentPayoutTerm];
          }
        };

        const rate = getRate();
        if (rate !== undefined) {
          const parsedRate = parseFloat(rate);
          calculateReturn(parsedRate);

          setCalculation((prevCalculation) => ({
            ...prevCalculation,
            interestRate: parsedRate,
          }));
          // console.log('result', calculation);
        } else {
          // console.log('Interest rate not found for the selected term.');
        }
      }
    }
  };

  const calculateReturn = (rate: any) => {
    const P = calculation.investAmount;
    const R = rate / 100;
    const T = calculation.investTenure; // Convert investment period from months to years

    let SI: any;
    let maturityAmount: any;
    let earnings: any;
    let tenure = 12;
    if (fdData.FDInfo.schemeName) {
      tenure = fdData.FDInfo.schemeName?.includes("HDFC") ? 365 : 12;
      // console.log(`now tenure will be ${tenure}`);
    }
    const tenure_compound = calculation.investTenure / tenure;

    switch (calculation.investmentPayoutTerm) {
      case "monthly":
        SI = (P * R * 1) / tenure;
        maturityAmount = P + SI * T;
        earnings = SI * T;
        break;
      case "quaterly":
        SI = (P * R * 1) / tenure;
        maturityAmount = P + SI * T;
        earnings = SI * T;
        break;
      case "halfyearly":
        SI = (P * R * 1) / tenure;
        maturityAmount = P + SI * T;
        earnings = SI * T;
        break;
      case "yearly":
        SI = (P * R * 1) / tenure;
        maturityAmount = P + SI * T;
        earnings = SI * T;
        break;
      case "cumulativeDeposit":
        maturityAmount = P * Math.pow(1 + R, tenure_compound);
        SI = maturityAmount - P;
        earnings = maturityAmount - P;
        break;
      default:
        SI = 0;
        maturityAmount = P;
        break;
    }

    // Calculate maturity date as a timestamp
    const startDate = new Date(); // Start date is today's date
    let maturityDate: number;

    if (fdData.FDInfo.schemeName && fdData.FDInfo.schemeName?.includes("HDFC")) {
      maturityDate = new Date(
        startDate.setDate(startDate.getDate() + calculation.investTenure)
      ).getTime();
    } else {
      maturityDate = new Date(
        startDate.setMonth(startDate.getMonth() + calculation.investTenure)
      ).getTime();
    }

    console.log("maturityDate", maturityDate);

    setCalculation((prevCalculation) => ({
      ...prevCalculation,
      grossInterest: SI.toFixed(2),
      maturityAmount: Number(maturityAmount.toFixed(2)),
      maturityDate: maturityDate, // Store timestamp
      return: earnings?.toFixed(2),
    }));
  };

  const debouncedHandleInvestmentChange = useDebouncedCallback(
    (amount: number) => {
      if (
        calculation.investmentPayoutTerm !== "choose" &&
        amount <
          fdData?.FDInfo?.minInvestmentAmount[calculation?.investmentPayoutTerm]
      ) {
        toast.error(
          "Investment amount should be greater than the minimum investment."
        );
      }
    },
    2000
  );

  const handleInvestmentChange = (amount: number) => {
    setCalculation({ ...calculation, investAmount: amount });
    // debouncedHandleInvestmentChange(amount);
  };

  const getAvailableInterestRateOptions = (
    finalRes: any,
    specialBenefit: keyof any
  ) => {
    if (!finalRes || !finalRes.interestRates) {
      return [];
    }

    const interestRates = finalRes.interestRates;
    let selectedRates: Record<string, string> | undefined; // Define type based on your data structure

    if (specialBenefit === "No") {
      selectedRates = interestRates.interestRate;
    } else {
      selectedRates = interestRates[specialBenefit];
    }

    if (!selectedRates || Object.keys(selectedRates).length === 0) {
      selectedRates = interestRates.interestRates;
    }

    if (!selectedRates) {
      return [];
    }

    const options = [
      { value: "monthly", label: "Monthly" },
      { value: "quaterly", label: "Quarterly" },
      { value: "halfyearly", label: "Half Yearly" },
      { value: "yearly", label: "Annually" },
      { value: "cumulativeDeposit", label: "Cumulative" },
    ];

    // Filter options based on available interest rates in selectedRates
    return options.filter(
      (option) =>
        selectedRates &&
        selectedRates[option.value] !== undefined &&
        selectedRates[option.value] !== "" &&
        selectedRates[option.value] !== "-"
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (onUpdate && calculation.return && calculation.maturityAmount) {
        onUpdate({ fieldName: "calculation", value: calculation });
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [calculation, onUpdate]);

  const [activeSpan, setActiveSpan] = useState<number | null>(null);

  const tenureIDFinder = async (
    event: any,
    key: number,
    minTenure: number,
    maxTenure: number,
    interestRate: any
  ) => {
    setTenureState({
      ...tenureState,
      minTenure: minTenure,
      maxTenure: maxTenure,
      id: key,
    });
    if (key && key !== 0) {
      setMatchTenure({
        ...MatchTenure,
        id: key,
        minTenure: minTenure,
        maxTenure: maxTenure,
      });
      setCalculation({ ...calculation, schemeId: fdData?.FDInfo?.schemeID });
      setFinalRes({ ...finalRes, interestRates: interestRate });

      // console.log('finalRes interest rate', finalRes);
      if (finalRes && interestRate) {
        const options = getAvailableInterestRateOptions(
          { ...finalRes, interestRates: interestRate },
          calculation.investmentSpecialBenefits
        );
        setAvailableOptions(options);
      }
    }
    setActiveSpan(key);
  };

  useEffect(() => {
    setCalculation({ ...calculation, investTenure: tenureState.minTenure });
  }, [tenureState]);

  // console.log('Final Calculation data', fdData);

  const handlePayoutTerm = (e: any) => {
    const investmentPayoutTerm = e.target.value as keyof InterestRates;

    setCalculation((prevCalculation) => ({
      ...prevCalculation,
      investmentPayoutTerm,
      interestFrequency: investmentPayoutTerm.toUpperCase(),
    }));

    const newMinInvestment =
      fdData?.FDInfo?.minInvestmentAmount[investmentPayoutTerm];
    // console.log('current value', newMinInvestment);

    if (typeof newMinInvestment === "string") {
      try {
        const convertedValue = convertMoneyStringToNumber(newMinInvestment);
        // console.log('converted value', convertedValue);
        setMinInvestment(convertedValue);
      } catch (error) {
        console.error("Error converting money string:", error);
        setMinInvestment(0); // Default to 0 or handle the error case appropriately
      }
    } else {
      setMinInvestment(newMinInvestment || 0); // Ensure it defaults to 0 if undefined or null
    }
  };

  const handleSpecialOption = (e: any) => {
    const specialBenefit = e.target.value as keyof SpecialInterestRates;
    setCalculation({
      ...calculation,
      investmentSpecialBenefits: specialBenefit,
    });

    // Recalculate available options based on the selected special benefit
    const options = getAvailableInterestRateOptions(finalRes, specialBenefit);
    setAvailableOptions(options);
  };

  const convertDaysToReadableFormat2 = (days: number): string => {
    if (fdData?.FDInfo?.schemeName && fdData?.FDInfo?.schemeName?.includes("HDFC")) {
      if (days <= 90) {
        return `${days} day${days > 1 ? "s" : ""}`;
      }
    } else {
      return days<=1?days +" month":days+" months" ;// Dynamic value for 12 months, approximated as 12 * 30 = 360 days
    }
    const today = new Date();
    let currentYear = today.getFullYear();
    let currentMonth = today.getMonth(); // Month is 0-based (0 = January, 11 = December)
    let currentDay = today.getDate();

    // Helper function to get the number of days in a given month and year
    const daysInMonth = (month: number, year: number): number => {
      return new Date(year, month + 1, 0).getDate();
    };

    // Determine the month length to use based on the current day
    let monthLength = daysInMonth(currentMonth, currentYear);
    if (currentDay > 20) {
      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
      monthLength = daysInMonth(currentMonth, currentYear);
    }

    let totalMonths = 0;
    let remainingDays = days;
    let futureDate = new Date(today);

    // Calculate total months and remaining days
    while (remainingDays >= monthLength) {
      totalMonths += 1;
      remainingDays -= monthLength;
      currentMonth += 1;
      if (currentMonth > 11) {
        currentMonth = 0;
        currentYear += 1;
      }
      monthLength = daysInMonth(currentMonth, currentYear);
    }

    // Calculate the exact future date
    futureDate.setMonth(today.getMonth() + totalMonths);
    futureDate.setDate(today.getDate() + remainingDays);

    // Format the readable string
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;
    let readableFormat = "";
    if (years > 0) readableFormat += `${years} yr${years > 1 ? "s" : ""} `;
    if (months > 0) readableFormat += `${months} mon `;
    if (remainingDays > 0) readableFormat += `${remainingDays} day${remainingDays > 1 ? "s" : ""} `;

    return readableFormat.trim();
  };

  const max = Number(tenureState.maxTenure);
  const min = Number(tenureState.minTenure);

  return (
    <div className="my-5">
      <h6 className="font-bold text-3xl" style={{ color: colors.primary }}>
        Make Investments
      </h6>
      <div className="my-5 flex flex-col md:flex-row  gap-5 justify-between">
        <div className="md:w-1/2 max-w-[700px]">
          <p className="mb-5 font-bold mt-5" style={{ color: colors.darkGrey }}>
            SELECTED PLAN :{" "}
            <span className="font-semibold">{fdData?.FDInfo?.schemeName}</span>
          </p>
          <div className="flex flex-col gap-y-2 mb-3">
            <div className="flex items-center justify-between mt-8">
              <Typography variant="subtitle2" sx={{ color: colors.textGrey }}>
                Investment Period
              </Typography>
              <div className="flex gap-x-2">
                <TextField
                  hiddenLabel
                  className="bg-gray-200 rounded"
                  id="filled-hidden-label-small"
                  value={convertDaysToReadableFormat2(calculation.investTenure || 0)}
                  variant="outlined"
                  size="small"
                  onChange={(e) => {
                    let value = parseInt(e.target.value) || 0;
                    if (value > max) {
                      value = max;
                    }
                    setCalculation({ ...calculation, investTenure: value });
                  }}
                  inputProps={{
                    max: max,
                    min: min,
                    readOnly: true,
                  }}
                  InputProps={{
                    disableUnderline: true,
                    style: {
                      border: "none",
                      backgroundColor: "transparent",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none",
                      },
                      "&:hover fieldset": {
                        border: "none",
                      },
                      "&.Mui-focused fieldset": {
                        border: "none",
                      },
                    },
                  }}
                  disabled={MatchTenure?.minTenure === MatchTenure?.maxTenure}
                />

                {/* {
                                    fdData?.FDInfo?.isHDFC === 0 ?
                                        (<div className='h-10 w-20 flex items-center justify-center border border-lightGrey rounded-md'>
                                            <span className=''>
                                                Months
                                            </span>
                                        </div>) :
                                        (<div className='h-10 w-20 flex items-center justify-center border border-lightGrey rounded-md'>
                                            <span className=''>
                                                Days
                                            </span>
                                        </div>)} */}

                <div className="h-10 w-20 flex items-center justify-center border border-lightGrey rounded-md text-white bg-blue-900">
                  <span className="">Duration</span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            {fdData?.tenure && fdData?.tenure?.length > 0 ? (
              fdData.tenure.map((item: any) => {
                let minTenureDisplay: any,
                  maxTenureDisplay: any,
                  interestRateBYFDData: any;
                minTenureDisplay = item.minTenure;
                maxTenureDisplay = item.maxTenure;
                interestRateBYFDData = {
                  interestRate: item.interestRate,
                  seniorCitizen: item.seniorInterestRate,
                };
                return (
                  <div
                    key={item.id}
                    onClick={(e) => {
                      if (
                        item &&
                        typeof item.minTenure !== "undefined" &&
                        typeof item.maxTenure !== "undefined"
                      ) {
                        tenureIDFinder(
                          e,
                          item.id,
                          minTenureDisplay,
                          maxTenureDisplay,
                          interestRateBYFDData
                        );
                      } else {
                        console.error(
                          "Min or max tenure not defined for item:",
                          item
                        );
                      }
                    }}
                    className={`border border-primary flex items-center justify-center cursor-pointer p-2 m-1 rounded-2xl text-sm ${
                      activeSpan === item.id
                        ? "bg-primary text-white"
                        : "bg-white text-primary"
                    }`}
                  >
                    {minTenureDisplay === maxTenureDisplay
                      ? convertDaysToReadableFormat2(minTenureDisplay)
                      : `${convertDaysToReadableFormat2(
                          minTenureDisplay
                        )} - ${convertDaysToReadableFormat2(maxTenureDisplay)}`}
                  </div>
                );
              })
            ) : (
              <div className="w-full text-start text-gray-500">No data</div>
            )}
          </div>

          {MatchTenure?.minTenure !== MatchTenure?.maxTenure && (
            <Slider
              value={calculation.investTenure}
              aria-label="Default"
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => convertDaysToReadableFormat2(value)}
              sx={{ color: colors.orange }}
              max={max}
              min={min}
              step={1}
              onChange={(event, newValue) => {
                setCalculation({
                  ...calculation,
                  investTenure: Number(newValue),
                });
              }}
            />
          )}

          <div className="flex flex-col gap-y-2 mb-3">
            <div className="flex gap-x-3 justify-between">
              <div className="w-1/2 max-w-[500px] mt-10">
                <Typography variant="subtitle2" sx={{ color: colors.textGrey }}>
                  Special Benefits
                </Typography>
                <select
                  className="border border-solid border-stone-300 rounded-md w-full py-2"
                  value={calculation.investmentSpecialBenefits}
                  onChange={(e) => handleSpecialOption(e)}
                >
                  <option value="No">No Special Benefits</option>
                  <option value="seniorCitizen">Senior Citizen</option>
                  {/* <option value="womenInterestRates">Women</option> */}
                </select>
              </div>

              <div className="w-1/2 max-w-[500px] mt-10">
                <Typography variant="subtitle2" sx={{ color: colors.textGrey }}>
                  Interest payout term
                </Typography>
                <select
                  className="border border-solid border-stone-300 rounded-md w-full py-2"
                  value={calculation.investmentPayoutTerm}
                  onChange={(e) => handlePayoutTerm(e)}
                >
                  <option value="">Select Payout</option>
                  {availableOptions.map((option: any) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-y-2 mb-3">
            <div className="flex items-center justify-between mt-8 gap-x-4">
              <Typography variant="subtitle2" sx={{ color: colors.textGrey }}>
                Investment Amount
              </Typography>
              <TextField
                hiddenLabel
                id="investment-amount-input"
                value={calculation.investAmount}
                variant="outlined"
                size="small"
                sx={{ width: "100px" }}
                onChange={(e) => {
                  const value = parseInt(e.target.value) || 0;
                  handleInvestmentChange(value);
                }}
                disabled={
                  !calculation.investmentPayoutTerm &&
                  calculation.investmentPayoutTerm === ""
                }
              />
            </div>
            <Slider
              aria-label="Default"
              valueLabelDisplay="auto"
              value={calculation.investAmount}
              max={
                fdData?.FDInfo?.maxInvestmentAmount === 0
                  ? 500000000
                  : fdData?.FDInfo?.maxInvestmentAmount
              }
              min={Number.isNaN(minInvestment) ? 0 : minInvestment}
              step={10000}
              sx={{ color: "orange" }}
              onChange={(event, value) => {
                handleInvestmentChange(Number(value));
              }}
              disabled={
                !calculation.investmentPayoutTerm &&
                calculation.investmentPayoutTerm === ""
              }
            />
          </div>

          <div className="flex flex-col items-center justify-center mt-8 w-full">
            <Button
              onClick={() => CalculateFn()}
              variant="contained"
              sx={{
                textTransform: "none",
                backgroundColor: colors.primary,
                fontSize: "20px",
              }}
              className="w-full p-8"
            >
              Calculate
            </Button>
          </div>
        </div>
        <div className="w-full md:w-1/2 max-w-[700px] p-4">
          <Card
            sx={{
              minWidth: 275,
              boxShadow: "none",
              borderRadius: 2,
              padding: "0.5rem",
            }}
          >
            <CardContent>
              <PieChart
                series={[
                  {
                    data: [
                      {
                        id: 0,
                        value: calculation?.maturityAmount || 50,
                        label: "Investment",
                      },
                      {
                        id: 1,
                        value: Number(calculation?.return) || 50,
                        label: "Earnings",
                      },
                    ],
                    innerRadius: 75,
                    outerRadius: 100,
                    paddingAngle: 2,
                    startAngle: -45,
                    endAngle: 360,
                  },
                ]}
                colors={["#8DAEE5", "#FEE1A7"]}
                sx={{ width: "100%" }}
                height={200}
              />
              <div className="flex flex-wrap gap-4 justify-between items-center mt-5">
                <div
                  className="w-full sm:w-[45%]"
                  style={{ color: colors.textGrey }}
                >
                  <h6 className="font-bold">Plan:</h6>
                  <p className="font-inter">{fdData?.FDInfo?.schemeName}</p>
                </div>
                <div
                  className="w-full sm:w-[45%]"
                  style={{ color: colors.textGrey }}
                >
                  <h6 className="font-bold">Investment Amount:</h6>
                  <p className="font-inter">{calculation.investAmount}</p>
                </div>
                <div
                  className="w-full sm:w-[45%]"
                  style={{ color: colors.textGrey }}
                >
                  <h6 className="font-bold">ROI:</h6>
                  <p className="font-inter">
                    {Number(calculation.interestRate).toFixed(2) || 0}%
                  </p>
                </div>
                <div
                  className="w-full sm:w-[45%]"
                  style={{ color: colors.textGrey }}
                >
                  <h6 className="font-bold">Interest Payment:</h6>
                  <p className="font-inter">
                    {(calculation.investmentPayoutTerm || "").toUpperCase()}
                  </p>
                </div>
                <div
                  className="w-full sm:w-[45%]"
                  style={{ color: colors.textGrey }}
                >
                  <h6 className="font-bold">Interest Earned:</h6>
                  <p className="font-inter">{calculation.return || 0}</p>
                </div>
                <div
                  className="w-full sm:w-[45%]"
                  style={{ color: colors.textGrey }}
                >
                  <h6 className="font-bold">Return Amount:</h6>
                  <p className="font-inter">
                    {calculation?.maturityAmount || 0}
                  </p>
                </div>
                {/* Uncomment these sections if needed */}
                {/* <div className="w-full sm:w-[45%]" style={{ color: colors.textGrey }}>
              <h6 className="font-bold">Min Tenure:</h6>
              <p className="font-inter">{fdData?.minTenureinDays || '-'} Days</p>
            </div>
            <div className="w-full sm:w-[45%]" style={{ color: colors.textGrey }}>
              <h6 className="font-bold">Max Tenure:</h6>
              <p className="font-inter">{fdData?.maxTenureinDays || '-'} Days</p>
            </div> */}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
