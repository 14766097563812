import React, { FC, useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import { colors } from "../../constants/colors";
import { Button } from "../Button";
import plus from "../../../src/assets/icons/plus-circle-outline.png";
import MfInfo from "../../components/MFInfoSection/MFInfo";
import Alert from "@mui/material/Alert";
import getInitials from "../../utils/getInitailasIMGUtils";
import MFDetailCard from "./MFDetailCard";
import { Formik } from "formik";
import * as yup from "yup";
import { FormInput } from "../FormInput/FormInput";
import FormSelect from "../FormSelect/FormSelect";
import { useDispatch, useSelector } from "react-redux";
import { InvestMF, InvestRequest, createPayment, getSTPFunds } from "../../redux/MF/MFSlice";
import schemeTypes from "../../constants/schemeTypes";
import { useParams } from "react-router-dom";
import { Autocomplete } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import dayjs from "dayjs";
import FormDatePicker from "../FormDatePicker/FormDatePicker";
import { getAmountInWords } from "../../utils";
import AmountButtons from "./AmountButtons";
import QuantityInput from "../QuantityInput/QuantityInput";
import MFFooterCard from "./MFFooterCard";
import AmountCard from "./AmountCard";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import toast from "react-hot-toast";
import { showInterest } from "../../redux/user/userSlice";
import {
  Button as Btn,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { fetchDashboardData } from "../../redux/Dashboard/DashboardSlice";
import SuccessfullModal from "../modals/SuccessfullModal";
import APIloader from "../AppLoader/APIloader";
import { BrowserPopUpModal } from "../../utils/BrowserPopUpModal";
import { useUserData } from "../../hooks/useUserData";
import CustomDialog from "../modals/BsePaymentAutherizationModal";

const FDDetailCard = (data: any) => {
  return (
    <>
      <div className="max-w-[800px]">
        <div className="pl-5 py-2 flex flex-col gap-y-2 rounded-md bg-opacity-80 min-w-[150px] md:min-w-[200px]">
          <div className="text-lg font-medium">{data.label}</div>
          <div className="text-xl font-bold" style={{ color: colors.textGrey }}>
            {data.value}
          </div>
        </div>
      </div>
    </>
  );
};

const Amount = (data: any) => {
  return (
    <Button style={{}}>
      <div className="">
        <div
          className="items-center py-4 flex flex-col gap-y-2 rounded-md border border-solid bg-opacity-80 min-w-[150px] md:min-w-[250px]"
          style={{
            backgroundColor: colors.white,
            border: `1px solid ${colors.lightGrey}`,
          }}
        >
          <div className="text-xl font-medium">{data.label}</div>
        </div>
      </div>
    </Button>
  );
};

const fdDetails = (fundInfo: any) => [
  {
    label: "AUM (in Cr)",
    value: "Rs. 18.66L",
  },
  {
    label: "Current NAV",
    value: fundInfo.currentNAV,
  },
  {
    label: "Rank",
    value: fundInfo.mfRank,
  },
  {
    label: "Risk Level",
    value: fundInfo.riskLevel,
  },
];

const amount = [
  {
    label: "Rs. 5,000",
  },
  {
    label: "Rs. 10,000",
  },
  {
    label: "Rs. 25,000",
  },
  {
    label: "Rs. 50,000",
  },
  {
    label: "Rs. 1,00,000",
  },
];

interface MFProps {
  activeSection: string;
  fundInfo?: any;
  fundId: number;
  onClose?: any;
}

interface FormInputProps {
  label?: string;
  type?: string;
  id?: string;
  altText?: string;
  name?: string;
  onChange?: any;
  value?: any;
  disabled?: boolean;
}

const STP: FC<MFProps> = ({ fundId, fundInfo, activeSection, onClose }) => {
  const stpRef = useRef<any>();
  const dispatch = useDispatch<any>();
  const { bank } = useUserData() || { bank: [] };
  const userData=useUserData();
  const [investmentPlan, setInvestmentPlan] = useState<string>(
    "Existing investment"
  );
  const switchFunds = useSelector((state: any) => state?.MFund?.funds);
  const [stpFrequencies, setStpFrequencies] = useState([]);
  const [availableFunds, setAvailableFunds] = useState<any>([]);
  const [selectedFund, setSelectedFund] = useState<any>();
  const [selectedAmount, setSelectedAmount] = useState("");
  const [stpDates, setStpDates] = useState<string[]>([]);
  const [stpEndDate, setStpEndDate] = useState<string>();
  const [openDialog, setOpenDialog] = useState(false);
  const [summaryData, setSummaryData] = useState<any>({});
  const [button, setButton] = useState(false);
  const [InterestOpen, setInterestOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [authLink, setAuthLink] = useState("");
  const [orderID, setOrderID] = useState("");
  const [loadmessage, setLoadMessage] = useState<string>("");
  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [fundParams, setFundParams] = useState<any>({
    "Total Units": fundInfo?.totalUnits||(fundInfo?.currentValue/fundInfo?.currentNAV).toFixed(2),
    "Current Value": fundInfo?.currentValue,
    // {
    //   label: "Available Clear Units",
    //   value: "7489",
    // },
    // {
    //   label: "Min. Units",
    //   value: "0.01",
    // },
  });
  const [transactionData, setTransactionData] = useState<any>(null);
  const [isNewInvestmentEnabled, setIsNewInvestmentEnabled] = useState(true);
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");

  const [stpSchema, setStpSchmea] = useState<any>(
    yup?.object({
      sourceScheme: yup?.string().required("Source scheme is required"),
      destinationSchemeType: yup
        .string()
        .required("Destination scheme type is required"),
      newScheme: yup?.number().required("New scheme name is required"),
      folioNo: yup?.string().required("Folio number is required"),
      startDate: yup?.string().required("Start date is required"),
      frequency: yup?.number().required("Frequency is required"),
      noOfInstallment: yup?.number().required("No. of installment is required"),
      amount: yup?.number().required("Amount is required"),
    })
  );

  useEffect(() => {
    if (selectedFund) {
      const params = { ...fundParams };
      params["Min. Amount"] = selectedFund.minAmount;
      setFundParams(params);
    } else {
      const params = { ...fundParams };
      params["Min. Amount"] = "";
      setFundParams(params);
    }
  }, [selectedFund]);
  console.log(fundInfo, "fundInfo");

  useEffect(() => {
    if (fundInfo && stpRef.current) {
      console.log(fundInfo.schemeName, "fundInfo.schemeName");
      stpRef.current?.setFieldValue("sourceScheme", fundInfo?.schemeName);
      stpRef.current?.setFieldValue("folioNo", fundInfo?.folioNo || "112233");
    }
    if (fundInfo?.stpInfo?.length > 0) {
      const fqs: any = [];
      const data: any = {};
      fundInfo?.stpInfo?.forEach((info: any) => {
        data[info?.stpFrequency] = {
          minStp: info?.stpOutMinimumInstallmentAmount,
          maxStp: info?.stpOutMaximumInstallmentAmount,
          minInstallment: info?.stpMinimumInstallmentNumber,
          maxInstallment: info?.stpMaximumInstallmentNumber,
        };
        info?.stpFrequency === 1 && fqs.push({ label: "Daily", value: 1 });
        info?.stpFrequency === 2 && fqs.push({ label: "Weekly", value: 2 });
        info?.stpFrequency === 3 && fqs.push({ label: "Monthly", value: 3 });
        info?.stpFrequency === 4 && fqs.push({ label: "Quarterly", value: 4 });
      });
      console.log(data, "data stp");
      setStpFrequencyData(data);
      setStpFrequencies(fqs);
    }
  }, [fundInfo]);

  useEffect(() => {
    if (switchFunds.length > 0) {
      const newList = switchFunds.map((fund: any) => {
        return {
          aum: fund?.aum,
          currentNAV: fund?.currentNAV,
          mfRank: fund?.mfRank,
          riskLevel: fund?.riskLevel,
          label: fund?.schemeName,
          schemeName: fund?.schemeName,
          value: fund?.schemeName,
          minAmount: fund?.minimumPurchaseAmount,
          id: fund?.id,
        };
      });
      setAvailableFunds(newList);
    }
  }, [switchFunds]);

  useEffect(() => {
    if (investmentPlan) {
      stpRef.current?.setFieldValue("folioNo", fundInfo?.folioNumber[0]?.folio || "112233");
    }
  }, [investmentPlan]);

  const [stpFrequencyData, setStpFrequencyData] = useState<any>({});

  useEffect(() => {
    if (stpFrequencies.length > 0) {
      setStpSchmea(
        yup.object({
          folioNo: yup?.string(),
          amount: yup
            .number()
            .required("Amount is required")
            .when("frequency", (frequency, schema) => {
              const { minStp, maxStp } = stpFrequencyData[
                parseInt(frequency[0])
              ] || { minStp: "100", maxStp: "9999999" };
              return schema
                .min(
                  minStp,
                  `Amount must be greater than or equal to ${minStp}`
                )
                .max(maxStp, `Amount must be less than or equal to ${maxStp}`);
            }),
          startDate: yup
            .string()
            .test(
              "is-valid-start-date",
              `Invalid start date. Start date should be: ${stpDates}`,
              function (value) {
                if (!value) return true;
                console.log(value, "selected date");
                const date = dayjs(value, "DD/MM/YYYY").format("D");
                console.log(stpDates, "sip dates");
                if (!stpDates) return true;
                return stpDates?.includes(date);
              }
            )
            .required("Start date is required"),
          frequency: yup.number().required(),
          noOfInstallment: yup
            .number()
            .when("frequency", (frequency, schema) => {
              const f = parseInt(frequency[0]);
              if (f === 1) return schema;
              return schema.required("No. of Installment is required");
            })
            .when("frequency", (frequency, schema) => {
              const { minInstallment, maxInstallment } = stpFrequencyData[
                parseInt(frequency[0])
              ] || { minInstallment: "5", maxInstallment: "50" };
              return schema
                .min(
                  minInstallment,
                  `Installment must be more than or equal to ${minInstallment}`
                )
                .max(
                  maxInstallment,
                  `Installment must be less than or equal to ${maxInstallment}`
                );
            }),
        })
      );
    }
  }, [stpFrequencies && stpDates]);

  const [stpValues, setStpValues] = useState({
    orderType: 4, // 1 for lumpsump, 2 for sip, 3 for swp, 4 for stp
    newOrder:1,
    fundId: 0,
    amount: 0,
    bankId: 0,
    folioNumber: "",
    dividend: "",
    startDate: "",
    frequency: 0,
    noOfInstallment: 0,
    firstOrderToday: "",
    units: 0,
    transType: "NEW",
    STPType: "EXCH",
    buySellType: "Fresh",
    toSchemeId: 0,
    sourceScheme: "",
    destinationSchemeType: "",
    newScheme: "",
    folioNo: ""
  });

  const renderRadio = (option: string) => (
    <label key={option} className="flex items-center">
      <input
        type="radio"
        checked={investmentPlan === option}
        onChange={() => setInvestmentPlan(option)}
        className={`mr-2`}
        disabled={option === "New investment" && !isNewInvestmentEnabled}
      />
      {option}
    </label>
  );

  const getSwtchFunds = (fundType: string, search: string = "") => {
    if (fundId) {
      dispatch(
        getSTPFunds({
          pageIndex: 1,
          pageSize: 100,
          search: search,
          fundType: fundType,
          fundId: fundId,
        })
      );
    }
  };

  const onFrequencyChange = (value: any) => {
    fundInfo?.stpInfo?.map((info: any) => {
      if (info?.stpFrequency == value) {
        setStpDates(info?.stpDates);
      }
    });
  };

  const calculateEndDate = (
    frequency: any,
    startDate: any,
    numOfInstallments: any
  ) => {
    console.log(
      frequency,
      numOfInstallments,
      "frequency, numOfInstallments, startDate"
    );
    if (!frequency || !numOfInstallments || !startDate) return;
    let endDate = dayjs(startDate, "DD/MM/YYYY");
    console.log(frequency, "EndDate");
    switch (frequency) {
      case 1:
        endDate = endDate.add(numOfInstallments - 1, "day");
        break;
      case 2:
        endDate = endDate.add(numOfInstallments - 1, "week");
        break;
      case 3:
        endDate = endDate.add(numOfInstallments - 1, "month");
        break;
      case 4:
        endDate = endDate.add((numOfInstallments - 1) * 3, "month");
        break;
      default:
        break;
    }

    const endDateValue = endDate.format("DD/MM/YYYY");
    if (endDateValue) {
      setStpEndDate(endDateValue);
    }
  };

  const investInSTP = async (values: any) => {
    try {
      const payload = {
        orderType: "4",
        transType: "NEW",
        STPType: "EXCH",
        buySellType: "Fresh",
        folioNo: values.folioNo,
        startDate: values.startDate,
        frequency: values.frequency,
        noOfTransfers: values.noOfInstallment,
        fundId: fundId.toString(),
        amount: values.amount,
        units: "",
        firstOrderToday:
          values.startDate === dayjs(new Date()).format("dd-MM-yyyy")
            ? "Y"
            : "N",
        toSchemeId: values.newScheme,
        isDirectAllowed: userData?.preferences?.direct===1?1:0,
      };
      setLoading(true);
      setLoadMessage("Processing your request, please wait 30s to 40s...");
      handleCloseDialog();
      const res = await dispatch(InvestMF(payload));
      setOrderID(res?.payload?.data?.data?.orderId);
        if (res?.payload?.data?.success === 200) {
          setLoading(false);
          const authLink = res?.payload?.data?.data?.authLink;
            setAuthLink(authLink);
            setAuthModalOpen(true); // Open modal with auth link
        } else {
          setLoading(false);
          setInterestOpen(true);
          setErrorMessage(
            "Error in executing your order."
          );
        }

      } catch (error) {
        setOpen(true);
        setErrorMessage("Investment Failed");
      } finally {
      dispatch(hideLoading());
      handleCloseDialog();
    }
  };

  const handleOpenDialog = () => {
    const values = stpRef.current?.values;

    // Extract values directly from `values` or use defaults
    const amount = parseFloat(values?.amount || "0");
    const sipDate = values?.startDate || "N/A";

    // Single-line frequency mapping
    const frequency =
      values?.frequency === 1
        ? "Daily"
        : values?.frequency === 2
        ? "Weekly"
        : values?.frequency === 3
        ? "Monthly"
        : values?.frequency === 4
        ? "Quarterly"
        : "N/A";

    // Fetching the folio number based on investment plan
    const folioNo =
      investmentPlan === "Existing investment"
        ? values?.folioNo || "N/A"
        : "N/A";

    // Assuming current NAV is available
    const currentNAV = parseFloat(fundInfo?.currentNAV || "0");
    const units = currentNAV > 0 ? (amount / currentNAV).toFixed(2) : "N/A";
    const sourceScheme = values?.sourceScheme || "N/A";
    const newScheme = values.newScheme || "N/A";
    // Formatting firstOrderToday
    const firstOrderToday =
      values?.startDate === dayjs(new Date()).format("DD-MM-YYYY") ? "Y" : "N";

    // Summary data object including all relevant payload information
    const summary = {
      Scheme: sourceScheme,
      DestinationScheme:
        availableFunds.find((fund: any) => fund.id === newScheme)?.label ||
        "N/A",
      Amount: amount.toFixed(2),
      BuySellType: values?.buySellType || "N/A",
      FirstOrderToday: firstOrderToday,
      FolioNumber: folioNo || "112233",
      Frequency: frequency,
      FundId: values?.fundId || "N/A",
      NoOfTransfers: values?.noOfTransfers || "N/A",
      OrderType: values?.orderType || "N/A",
      StartDate: sipDate,
      ToSchemeId: values?.toSchemeId || "N/A",
      TransType: values?.transType || "N/A",
      Units: units,
    };

    setSummaryData(summary);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = async () => {
    handleCloseDialog();
    const values = stpRef.current?.values;
    await investInSTP(values);
  };

  useEffect(() => {
    fetchData(); // Fetch data when component mounts or dependencies change
  }, []);

  const fetchData = async () => {
    let data = { fetchFor: "aumcapital" };
    try {
      const response = await dispatch(fetchDashboardData(data));
      if (response.payload.data.data) {
        setTransactionData(response.payload.data.data);
        evaluateInvestmentStatus(response.payload.data.data);
      }
    } catch (error) {
      console.error("Fetch dashboard data failed:", error);
      setOpen(true);
      setErrorMessage("Please Refresh");
    }
  };

  const evaluateInvestmentStatus = (data: any) => {
    let newInvestmentDisabled = false;

    // Log the entire recentTransactions array for debugging
    console.log("Recent Transactions Data:", data.recentTransactions);

    // Check if any of the MF orders are of type `isBuy` 1
    data.recentTransactions.forEach(
      (transactionGroup: any[], groupIndex: number) => {
        transactionGroup.forEach((order: any, orderIndex: number) => {
          console.log(`Processing order ${orderIndex}:`, order);

          if (order.orderType === "MF" && order.isBuy === 1) {
            console.log("Found MF order with isBuy 1:", order);
            newInvestmentDisabled = true;
          }
        });
      }
    );

    // Update the state based on the evaluation
    setIsNewInvestmentEnabled(!newInvestmentDisabled);

    // Log the final state of newInvestmentDisabled
    console.log("New Investment Disabled:", newInvestmentDisabled);
  };

  
  const showingInterest = async () => {
    try {
      const response = await dispatch(
        showInterest({ id: fundId, holdingType: 2 })
      );

      if (response?.payload?.statusCode === 200) {
        setInterestOpen(true);
        setMessage("Your Interest is submitted successfully");
      } else {
        setInterestOpen(true);
        setErrorMessage("Something wents wrong.Please try again later!");
        console.warn("Unexpected response structure:", response);
      }
    } catch (error) {
      setInterestOpen(true);
      setErrorMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };

  const handleInterestClose = () =>{
    setInterestOpen(false);
  }

  const handleClose=()=>{
    setOpen(false);
    onClose();
  }
  
  useEffect(() => {
    // Check if the popup window is closed every 100 milliseconds
    const checkPopupClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        setLoading(false);
        clearInterval(checkPopupClosed); // Stop checking once the popup is closed
        handleAuthModalClose();
      }
    }, 100);

    // Clear the interval if the component unmounts
    return () => clearInterval(checkPopupClosed);
  }, [popupWindow]);

  const openAuthLink = () => {
    setAuthModalOpen(false);
    setLoadMessage("Please authenticate your order and close the bse tab...");
    setLoading(true);
    if (authLink) {
      const newPopupWindow =  BrowserPopUpModal(authLink, "BSE Autherization",1250,550);
      setPopupWindow(newPopupWindow);
    }
  };
  const handleAuthModalClose = async () => {
    setAuthModalOpen(false); // Close the auth link modal
      try {
        const response=await dispatch(
          createPayment({
            orderId: orderID,
            modeofpayment: "DIRECT",
            vpaid: "",
            bankid: parseInt(bank[0]?.id),
            isDirectAllowed: userData?.preferences?.direct===1?1:0,
          })
        );
        setOpen(true);
        if(response.payload.data.statusCode===200){          
          setButton(response.payload.data.statusCode===200);
          setMessage("STP Transaction completed successfully.");
           // Open the modal once data is fetched
        }
        else{
          setErrorMessage(response.payload.data.message);
        }
      } catch (error) {
        console.error("Authentication is failed", error);
      }
  };

  return (
    <>
      <div className="bg-lightBg pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="bg-white">
              <div className="md:px-8 py-10">
                <MFDetailCard fundInfo={fundInfo} />
                <Formik
                  innerRef={stpRef}
                  initialValues={stpValues}
                  validationSchema={stpSchema}
                  onSubmit={async (values) => {
                    console.log(values,"onSubmit");
                    handleOpenDialog();
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <>
                      <div className="my-5">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault(); // Prevent the default form submission
                            // Check if either totalUnits or currentValue is missing
                            if (!(fundInfo?.currentValue/fundInfo?.currentNAV).toFixed(2) || !fundInfo?.currentValue) {
                              return; // Do nothing if both are missing
                            }
                        
                            // Log errors and call handleSubmit if validation passes
                            console.log(errors);
                            handleSubmit();
                          }}
                        >
                          <div className="flex flex-row w-full gap-5 mt-10">
                            <div className="w-full sm:w-3/4 sm:mb-0 ">
                              <FormInput
                                label="Source Scheme"
                                name="sourceScheme"
                                type={"text"}
                                value={values.sourceScheme}
                                disabled={true}
                                onChange={handleChange("sourceScheme")}
                              />
                            </div>
                            <div className="w-full sm:w-3/4 sm:mb-0 ">
                              <FormSelect
                                label="Destination Scheme Type"
                                name="destinationSchemeType"
                                options={schemeTypes}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    "destinationSchemeType",
                                    e.target.value
                                  );
                                  getSwtchFunds(e.target.value);
                                }}
                                value={values?.destinationSchemeType}
                                error={errors.destinationSchemeType}
                                touched={touched.destinationSchemeType}
                              />
                            </div>
                          </div>
                          <div className="flex flex-wrap gap-5 pt-5 justify-center md:justify-start">
                            {Object.keys(fundParams).map(
                              (label: any) =>
                                fundParams[label] !== "" &&
                                fundParams[label] != null && (
                                  <AmountCard
                                    label={label}
                                    value={fundParams[label]}
                                  />
                                )
                            )}
                          </div>
                          <div className="flex flex-row w-full gap-5 mt-10">
                            <div className="w-full sm:w-3/4 sm:mb-0 ">
                              <Autocomplete
                                sx={{
                                  "& input": {
                                    width: "100%",
                                    color: colors.darkGrey, //CH11/5
                                    border: `1px solid ${colors.darkBg}`,
                                    padding: "1rem",
                                    borderRadius: "0.5rem",
                                    marginTop: "0.75rem",
                                  },
                                }}
                                isOptionEqualToValue={(option: any, value) =>
                                  option.value === value.value
                                }
                                disablePortal
                                id="combo-box-demo"
                                options={availableFunds || []}
                                onChange={(_, newValue: any) => {
                                  setSelectedFund(newValue);
                                  setFieldValue("newScheme", newValue?.id);
                                }}
                                renderInput={(params) => (
                                  <div
                                    ref={params.InputProps.ref}
                                    className={`w-full mx-auto mb-4 sm:mb-0`}
                                  >
                                    <div className="flex flex-col text-lg md:mb-10">
                                      <label style={{ color: colors.darkGrey }}>
                                        Scheme Name
                                      </label>
                                      <div className="relative">
                                        <input
                                          type={"text"}
                                          placeholder="Select Scheme Name"
                                          {...params.inputProps}
                                        />
                                        <span className="absolute right-5 top-7">
                                          <KeyboardArrowDown />
                                        </span>
                                      </div>
                                      {!!errors?.newScheme &&
                                      touched?.newScheme ? (
                                        <p className="text-secondary h-1.5">
                                          {errors?.newScheme}
                                        </p>
                                      ) : (
                                        <p className="text-secondary h-1.5"></p>
                                      )}
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="w-full sm:w-3/4 sm:mb-0 "></div>
                            {/* <div className="w-full sm:w-3/4 sm:mb-0 ">
                              <FormInput
                                label="Scheme Option"
                                name="SchemeOption"
                                type={""}
                                id={""}
                                onChange={undefined}
                              />
                            </div> */}
                          </div>
                          {selectedFund && (
                            <div className="flex flex-col w-full gap-5">
                              <h6 className="mr-5 text-textGrey text-lg">
                                Destination Scheme
                              </h6>
                              <MFDetailCard fundInfo={selectedFund} />
                            </div>
                          )}
                          <div className="flex justify-between items-center">
                            <h6
                              className="font-medium text-xl mb-5 mt-10"
                              style={{ color: colors.darkGrey }}
                            >
                              Scheme Details
                            </h6>
                          </div>
                          <div className="flex flex-col md:flex-row text-start justify-start text-sm md:text-lg gap-2 mt-5">
                            <p>Purchase mode:</p>{" "}
                            {["Existing investment", "New investment"].map(
                              renderRadio
                            )}
                          </div>
                          {investmentPlan === "Existing investment" && (
                            <div className="flex flex-row w-full gap-5 mt-10">
                              <div className="w-full sm:w-3/4 mb-4 sm:mb-0 ">
                                <FormInput
                                  label="Folio Number"
                                  name="folioNo"
                                  type={"text"}
                                  onChange={handleChange("folioNo")}
                                  value={values?.folioNo}
                                  id="folioNo"
                                  disabled={true}
                                />
                              </div>
                              <div className="w-full sm:w-3/4 mb-4 sm:mb-0"></div>
                            </div>
                          )}
                          <div className="flex justify-between items-center">
                            <h6
                              className="font-medium text-xl mb-5 mt-10"
                              style={{ color: colors.darkGrey }}
                            >
                              STP Details
                            </h6>
                          </div>
                          <div className="flex flex-row w-full gap-5">
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              <FormSelect
                                label="STP Frequency"
                                name="frequency"
                                options={stpFrequencies}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    "frequency",
                                    parseInt(e.target.value)
                                  );
                                  onFrequencyChange(e.target.value);
                                  calculateEndDate(
                                    parseInt(e.target.value),
                                    values.startDate,
                                    values.noOfInstallment
                                  );
                                }}
                                value={values.frequency}
                                error={errors.frequency}
                                touched={touched.frequency}
                              />
                            </div>

                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              {/* <FormInput
                              label="SIP Date"
                              name="date"
                              type=""
                              id={""}
                              onChange={undefined}
                            /> */}
                            </div>
                          </div>
                          <div className="flex flex-row w-full gap-5">
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              <FormDatePicker
                                label="STP Start Date"
                                name="startDate"
                                onChange={(date) => {
                                  const formattedDate = date
                                    ? date.format("DD/MM/YYYY")
                                    : "";
                                  setFieldValue("startDate", formattedDate);
                                  calculateEndDate(
                                    values.frequency,
                                    formattedDate,
                                    values.noOfInstallment
                                  );
                                }}
                                value={
                                  values.startDate
                                    ? dayjs(values.startDate, "DD-MM-YYYY")
                                    : null
                                }
                                defaultValue={dayjs(new Date())}
                                error={errors.startDate}
                                touched={touched.startDate}
                                maxDate={dayjs().add(1, "year")}
                                hintText={
                                  stpDates &&
                                  `Suggested STP start dates: ${stpDates}`
                                }
                              />
                            </div>
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              {values.frequency !== 1 && (
                                <FormInput
                                  label="No. of installments"
                                  name="noOfInstallment"
                                  type={"number"}
                                  onChange={(e: any) => {
                                    let value = e.target.value;
                                    // Remove leading zero when user starts typing
                                    if (
                                      value.startsWith("0") &&
                                      value.length > 1
                                    ) {
                                      value = value.replace(/^0+/, ""); // Remove leading zeros
                                    }
                                    setFieldValue(
                                      "noOfInstallment",
                                      value
                                    );
                                    calculateEndDate(
                                      values.frequency,
                                      values.startDate,
                                      parseInt(value)
                                    );
                                  }}
                                  value={values.noOfInstallment}
                                  error={errors.noOfInstallment}
                                  touched={touched.noOfInstallment}
                                  // onChange={() => { calculateInstallmentCount() }}
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex flex-row w-full gap-5">
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                              <FormDatePicker
                                label="STP End Date"
                                name="endDate"
                                onChange={(date) => {
                                  const formattedDate = date
                                    ? date.format("DD/MM/YYYY")
                                    : "";
                                  setStpEndDate(formattedDate);
                                }}
                                value={
                                  stpEndDate
                                    ? dayjs(stpEndDate, "DD-MM-YYYY")
                                    : null
                                }
                                defaultValue={dayjs(new Date())}
                                disabled
                              />
                            </div>
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0"></div>
                          </div>
                          {/* <div className="flex justify-between items-center">
                            <h6
                              className="font-medium text-xl mb-5 "
                              style={{ color: colors.darkGrey }}
                            >
                              OR Select recommended amount
                            </h6>
                          </div> */}
                          <AmountButtons
                            label="Amount"
                            setFieldValue={setFieldValue}
                            selectedAmount={selectedAmount}
                            setSelectedAmount={setSelectedAmount}
                            fieldName="amount"
                            values={values}
                            errors={errors}
                            touched={touched}
                            currentValue={fundParams["Current Value"]}
                          />
                          {/* <div className="flex flex-row w-full gap-5">
                          <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                            <h6
                              className="font-medium text-xl mt-10 mb-3"
                              style={{ color: colors.darkGrey }}
                            >
                              Mode Of Payment
                            </h6>
                            <label className="flex items-center">
                              <input
                                type="radio"
                                checked={true}
                                name="modeOfPayment"
                                className={`mr-2`}
                              />
                              Autopay
                            </label>
                          </div>
                          <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                            <h6
                              className="font-medium text-xl mt-10 mb-3"
                              style={{ color: colors.darkGrey }}
                            >
                              SIP Step Up (in %)
                            </h6>
                            <QuantityInput />
                          </div>
                        </div> */}
                          {/* <div className="w-full my-10">
                            <MFFooterCard
                              onBankChange={handleChange("bankId")}
                              hideBank={true}
                              bankValue={undefined}
                              bankError={undefined}
                              bankTouched={undefined}
                            />
                          </div> */}
                          <Alert
                            variant="outlined"
                            severity="info"
                            sx={{
                              color: colors.textGrey,
                              border: "1px solid",
                              backgroundColor: colors.bgGrey,
                            }}
                          >
                            Day of Debit to investor’s account: T day <br />
                            Day of Unit Allotment: T + 1 day <br />
                            Cut-off time: 3:00 PM
                          </Alert>
                          <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                              <div className="flex flex-col max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                                  <div className="flex gap-5 justify-between   lg:mt-2 text-sm max-md:mt-2 lg:mb-5">
                                    <Button
                                      type="submit"
                                      className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid ${
                                        !fundInfo?.totalUnits &&
                                        !fundInfo?.currentValue
                                          ? "opacity-50 cursor-not-allowed"
                                          : "cursor-pointer hover:bg-primary"
                                      }`}
                                      tabIndex={0}
                                      style={{
                                        cursor:
                                          !fundInfo?.totalUnits &&
                                          !fundInfo?.currentValue
                                            ? "not-allowed"
                                            : "pointer",
                                      }}
                                    >
                                      Invest
                                      {/* Proceed to payment */}
                                    </Button>
                                    <Button
                                  type="button"
                                    className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary`}
                                    onClick={showingInterest}
                                  >
                                    Show Interest
                                    {/* Proceed to payment */}
                                  </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </form>
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <APIloader loadingModal={loading} message={loadmessage} />
      <Dialog
                  open={openDialog}
                  onClose={handleCloseDialog}
                  className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
                >
                  <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
                      <Typography variant="h4" className="font-bold mb-4">
                        Summary
                      </Typography>
                      <DialogContent className="flex flex-col">
                        {summaryData ? (
                          <>
                            <div className="flex justify-between mb-4">
                              <span className="font-semibold text-gray-700">
                                Source Scheme:
                              </span>
                              <span className="text-xs w-52">
                                {summaryData.Scheme}
                              </span>
                            </div>
                            <div className="flex justify-between mb-4">
                              <span className="font-semibold text-gray-700">
                                Destination Scheme:
                              </span>
                              <span className="text-xs w-52">
                                Rs. {summaryData.DestinationScheme}
                              </span>
                            </div>
                            <div className="flex justify-between mb-4">
                              <span className="font-semibold text-gray-700">
                                Folio Number:
                              </span>
                              <span>{summaryData.FolioNumber}</span>
                            </div>
                            <div className="flex justify-between mb-4">
                              <span className="font-semibold text-gray-700">
                                Amount:
                              </span>
                              <span>Rs. {summaryData.Amount}</span>
                            </div>
                            <div className="flex justify-between mb-4">
                              <span className="font-semibold text-gray-700">
                                Frequency:
                              </span>
                              <span>{summaryData.Frequency}</span>
                            </div>
                            <div className="flex justify-between mb-4">
                              <span className="font-semibold text-gray-700">
                                Start Date:
                              </span>
                              <span>{summaryData.StartDate}</span>
                            </div>
                            <div className="flex justify-between mb-4">
                              <span className="font-semibold text-gray-700">
                                Approx. Units:
                              </span>
                              <span>{summaryData.Units}</span>
                            </div>
                          </>
                        ) : (
                          <div className="flex justify-center items-center">
                            <span>Loading...</span>
                          </div>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <Btn
                          onClick={handleConfirmDialog}
                          color="primary"
                          sx={{
                            backgroundColor: colors.primary,
                            borderRadius: "40px",
                            color: colors.lightBg,
                            padding: "10px",
                            "&:hover": {
                              backgroundColor: colors.primary,
                              color: colors.lightBg,
                            },
                          }}
                        >
                          Confirm
                        </Btn>
                        <Btn
                          onClick={handleCloseDialog}
                          sx={{
                            backgroundColor: colors.primary,
                            borderRadius: "40px",
                            color: colors.lightBg,
                            padding: "10px",
                            "&:hover": {
                              backgroundColor: colors.primary,
                              color: colors.lightBg,
                            },
                          }}
                        >
                          Cancel
                        </Btn>
                      </DialogActions>
                    </div>
                  </div>
                </Dialog>
                <SuccessfullModal open={InterestOpen} message={message!==""?message:errormessage} handleClose={handleInterestClose} />
                        <Dialog open={authModalOpen} onClose={handleAuthModalClose}>
  <DialogContent>
    <Typography>
      Click below to complete your authorization on the BSE STAR MF platform and also close the tab after autherized yourself.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Btn onClick={openAuthLink} sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}>
      Open Authorization Link
    </Btn>
    <Btn onClick={handleAuthModalClose} sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}>
      Close
    </Btn>
  </DialogActions>
        </Dialog>
        <CustomDialog
        open={Open}
        errormessage={message!==""?message:errormessage}
        button={button}
        handleDialogClose={handleCloseDialog}
        handleClose={handleClose}
        openAuthLink={button ? undefined : openAuthLink}
        openPaymentTab={button ?handleClose :undefined}
      />
    </>
  );
};

export default STP;
