import React, { useEffect, useState } from 'react'
import ListingComponent from '../../components/ListingTemplate/listingTemplate';
import { useDispatch } from 'react-redux';
import { getFDinfo } from '../../redux/FD/FDSlice';
import { hideLoading, showLoading } from '../../redux/loader/loaderSlice';
import { getAllStocks } from '../../redux/Stocks/StockSlice';
import StockListingComponent from '../../components/ListingTemplate/StockListingTemplate';
import { Pagination } from '@mui/material';


function EquityListing() {
    const dispatch = useDispatch<any>();
    const [page, setPage] = useState(1);
    const [inputData, setInputData] = useState({
        pageIndex: 1,
        pageSize: 100,
        search: "",
    });

    const breadcrumbItems = [
        { label: 'Home', href: '/' },
        { label: 'Investment', href: '/' },
        { label: 'Equity', },
    ];
    const filterOps: any = [
        {
            name: "Fund Type",
            options: [
                {
                    name: "Equity",
                    value: 1,
                    isChecked: false,
                },
                {
                    name: "Debt",
                    value: 3,
                    isChecked: false,
                },
                {
                    name: "Hybrid",
                    value: 5,
                    isChecked: false,
                },
                {
                    name: "Solution Oriented",
                    value: 7,
                    isChecked: false,
                },
                {
                    name: "GILT",
                    value: 8,
                    isChecked: false,
                },
                {
                    name: "ELSS",
                    value: 9,
                    isChecked: false,
                },
                {
                    name: "MIP",
                    value: 10,
                    isChecked: false,
                },
                {
                    name: "Balanced",
                    value: 11,
                    isChecked: false,
                },
                {
                    name: "STP",
                    value: 12,
                    isChecked: false,
                },
                {
                    name: "FOF",
                    value: 13,
                    isChecked: false,
                },
                {
                    name: "Liquid",
                    value: 14,
                    isChecked: false,
                },
                {
                    name: "Bond",
                    value: 15,
                    isChecked: false,
                },
                {
                    name: "Income",
                    value: 16,
                    isChecked: false,
                },
            ],
        },
    ];
    const [loader, setLoader] = useState<boolean>(false);
    const [totalEnteries, setTotalEnteries] = useState<number>(0);
    const [data, setData] = useState<any[]>();
    const [dataEnteries, setDataEnteries] = useState<any[]>();
    const [countOFPages, seCountOFPages] = useState(1);


    const StockTransactions = [
        { key: 'stockSymbol', value: 'Stock Symbol' },
        { key: 'stockName', value: 'Stock Name' },
        { key: 'stockSeries', value: 'Stock Series' },
        { key: 'dateOfListing', value: 'Listing Date' },
        {key:'faceValue',value:'Face Value'},
        // { key: 'action', value: 'Action' },
    ];


    const summary = 'Equity investments involve buying shares of a company that gives you partial ownership. This asset class has potential for high returns as businesses grow and increase in value. However, there is also a higher level of risk due to market fluctuations that can affect share prices.'


      //Fetch MF Data from Get All fund list API
  const fetchData = async () => {
    try {
      const response = await dispatch(getAllStocks(inputData));

      console.log(response,"Stocks Response");

      if (response) {
        let stockList = response?.payload?.pmsList || [];
        setTotalEnteries(stockList?.length);
        setData(stockList);
        seCountOFPages(response?.payload?.totalPages || 0);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
        setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, [inputData]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setInputData({ ...inputData, pageIndex: value });
  };




    return (
        <>
        <StockListingComponent
        title='Equity'
        breadcrumb={breadcrumbItems}
        filterOps={filterOps}
        totalEnteries={totalEnteries}
        dataEnteries={data}
        controlBarObj={StockTransactions}
        summary={summary}
        setDataEnteries={setDataEnteries}
        loaderData={loader}
    />
    <div className="flex justify-center items-center mb-4">
        <Pagination
          shape="rounded"
          count={countOFPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
      </>
    )
}

export default EquityListing;
