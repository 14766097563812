import { CheckCircleOutline } from "@mui/icons-material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { bseNomineeAuth } from "../../redux/user/userSlice";
import { useLocation, useParams } from "react-router-dom";
import { useUserData } from "../../hooks/useUserData";

const VerificationPending = () => {
  const user=useUserData();
  const dispatch = useDispatch<any>();
  const { id } = useParams();
  const {search} = useLocation();

  useEffect(() => {
    const status = new URLSearchParams(search).get("STATUS");
    if(id && status){
      const data = { id, status,user }; 
      dispatch(bseNomineeAuth(data));
    }
  }, []); 

  return (
    <div className="flex flex-col items-center justify-start w-full bg-white">
      <div className="flex flex-col items-center justify-start w-full px-5 py-8 md:px-14 md:py-12 bg-primary shadow-xs min-h-screen">
        <div className="flex flex-col gap-y-2 mt-5">
          <CheckCircleOutline
            style={{ fontSize: "8rem", color: "lightgreen" }}
          />
        </div>
        <div className="flex flex-col gap-y-8 items-center justify-center mt-8">
          <p className="text-white font-inter text-2xl text-center">
            Your application is under verification. <br /> Please wait for 24
            hours.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VerificationPending;
