import api from "../../config/apiConfig";

const searchPanNo = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("user/searchFamily", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const addFamilyMember = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("user/addMember", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const getFamilyMember = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("user/getFamily", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const deleteFamilyMember = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("user/deleteMember", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const deleteFromFamily = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("user/deleteFamily", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const FamilyService = {
  searchPanNo,
  addFamilyMember,
  getFamilyMember,
  deleteFamilyMember,
  deleteFromFamily
};
export default FamilyService;
