import api from "../../config/apiConfig";

const getAllOrderHistory = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("user/getOrderHistory", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const OrderService = {
  getAllOrderHistory,
};
export default OrderService;
