import React, { useEffect, useState } from 'react'
import ListingComponent from '../../components/ListingTemplate/listingTemplate';
import { useDispatch } from 'react-redux';
import { getFDinfo } from '../../redux/FD/FDSlice';
import { hideLoading, showLoading } from '../../redux/loader/loaderSlice';
import { getAllStocks } from '../../redux/Stocks/StockSlice';
import { Pagination } from '@mui/material';
import BondListingComponent from '../../components/ListingTemplate/BondListingTemplate';
import { getAllBonds } from '../../redux/Bond/BONDSlice';


function BondListing() {
    const dispatch = useDispatch<any>();
    const [page, setPage] = useState(1);
    const [inputData, setInputData] = useState({
        pageIndex: 1,
        pageSize: 100,
        search: "",
    });

    const breadcrumbItems = [
        { label: 'Home', href: '/' },
        { label: 'Investment', href: '/' },
        { label: 'Bond', },
    ];
    const filterOps: any = [
        {
            name: "Fund Type",
            options: [
                {
                    name: "Equity",
                    value: 1,
                    isChecked: false,
                },
                {
                    name: "Debt",
                    value: 3,
                    isChecked: false,
                },
                {
                    name: "Hybrid",
                    value: 5,
                    isChecked: false,
                },
                {
                    name: "Solution Oriented",
                    value: 7,
                    isChecked: false,
                },
                {
                    name: "GILT",
                    value: 8,
                    isChecked: false,
                },
                {
                    name: "ELSS",
                    value: 9,
                    isChecked: false,
                },
                {
                    name: "MIP",
                    value: 10,
                    isChecked: false,
                },
                {
                    name: "Balanced",
                    value: 11,
                    isChecked: false,
                },
                {
                    name: "STP",
                    value: 12,
                    isChecked: false,
                },
                {
                    name: "FOF",
                    value: 13,
                    isChecked: false,
                },
                {
                    name: "Liquid",
                    value: 14,
                    isChecked: false,
                },
                {
                    name: "Bond",
                    value: 15,
                    isChecked: false,
                },
                {
                    name: "Income",
                    value: 16,
                    isChecked: false,
                },
            ],
        },
    ];
    const [loader, setLoader] = useState<boolean>(false);
    const [totalEnteries, setTotalEnteries] = useState<number>(0);
    const [data, setData] = useState<any[]>();
    const [dataEnteries, setDataEnteries] = useState<any[]>();
    const [countOFPages, seCountOFPages] = useState(1);


    const BondTransactions = [
        { key: 'promoter', value: 'Scheme Name' },
        { key: 'faceValue', value: 'Minimum Investment' },
        { key: 'bondYield', value: 'Yield' },
        { key: 'bondPrice', value: 'Price' },
        {key:'interestPaymentFrequency',value:'IP Frequency'},
        { key: 'couponRate', value: 'coupon' },
        { key: 'maturityDate', value: 'Maturity Date' },
    ];


    const summary = 'A debt bond is a type of investment where you lend money to a company or government in exchange for regular interest payments and return of the principal amount for a specific period.'     

      //Fetch MF Data from Get All fund list API
  const fetchData = async () => {
    try {
      const response = await dispatch(getAllBonds(inputData));
      if (response) {
        let bondList = response?.payload?.data?.data?.bondList;
        setTotalEnteries(bondList?.length);
        setData(bondList);
        seCountOFPages(response?.payload?.data?.data?.totalPages || 0);
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
        setLoader(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, [inputData]);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPage(value);
    setInputData({ ...inputData, pageIndex: value });
  };




    return (
        <>
        <BondListingComponent
        title='Bond'
        breadcrumb={breadcrumbItems}
        filterOps={filterOps}
        totalEnteries={totalEnteries}
        dataEnteries={data}
        controlBarObj={BondTransactions}
        summary={summary}
        setDataEnteries={setDataEnteries}
        loaderData={loader}
    />
    <div className="flex justify-center items-center mb-4">
        <Pagination
          shape="rounded"
          count={countOFPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </div>
      </>
    )
}

export default BondListing;
