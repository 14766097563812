import api from "../../config/apiConfig";

const investBONDRequest = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("bond/invest", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const withdrawBONDRequest = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("bond/withdraw", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const BondSummary = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  // console.log('MF Service getindidualMutualFundInfo ::', 'Req data: ', data, 'Access Token :', accessToken);

  const response = await api.post("bond/getSummary", data, {
    headers: {
      Authorization: accessToken,
    },
  });

  return response;
};

const getAllBonds = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("bond/getAll", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response;
};

const BONDService = {
  investBONDRequest,
  withdrawBONDRequest,
  BondSummary,
  getAllBonds
};
export default BONDService;
