import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Typography,
  Box,
  Select,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import toast from "react-hot-toast";
import { addOrganizationFatca } from "../../../redux/NonInd_Company/companySlice";
import { useDispatch } from "react-redux";
import { getUserById } from "../../../redux/user/userSlice";
import { CheckCircle } from "@mui/icons-material";
import { useUserData } from "../../../hooks/useUserData";
import { colors } from "../../../constants/colors";

const options = {
  uboNationality: [{ value: "IN", label: "Indian" }],
  uboStateCode: [
    { value: "AN", label: "Andaman and Nicobar Islands" },
    { value: "AP", label: "Andhra Pradesh" },
    { value: "AR", label: "Arunachal Pradesh" },
    { value: "AS", label: "Assam" },
    { value: "BR", label: "Bihar" },
    { value: "CG", label: "Chhattisgarh" },
    { value: "DN", label: "Dadra and Nagar Haveli and Daman and Diu" },
    { value: "DL", label: "Delhi" },
    { value: "GA", label: "Goa" },
    { value: "GJ", label: "Gujarat" },
    { value: "HR", label: "Haryana" },
    { value: "HP", label: "Himachal Pradesh" },
    { value: "JK", label: "Jammu and Kashmir" },
    { value: "JH", label: "Jharkhand" },
    { value: "KA", label: "Karnataka" },
    { value: "KL", label: "Kerala" },
    { value: "LA", label: "Ladakh" },
    { value: "LD", label: "Lakshadweep" },
    { value: "MP", label: "Madhya Pradesh" },
    { value: "MH", label: "Maharashtra" },
    { value: "MN", label: "Manipur" },
    { value: "ML", label: "Meghalaya" },
    { value: "MZ", label: "Mizoram" },
    { value: "NL", label: "Nagaland" },
    { value: "OD", label: "Odisha" },
    { value: "PB", label: "Punjab" },
    { value: "RJ", label: "Rajasthan" },
    { value: "SK", label: "Sikkim" },
    { value: "TN", label: "Tamil Nadu" },
    { value: "TG", label: "Telangana" },
    { value: "TR", label: "Tripura" },
    { value: "UP", label: "Uttar Pradesh" },
    { value: "UK", label: "Uttarakhand" },
    { value: "WB", label: "West Bengal" },
  ],
  uboAddressType: [
    { label: "Residential or Business", value: "1" },
    { label: "Residential", value: "2" },
    { label: "Business", value: "3" },
    { label: "Registered Office", value: "4" },
    { label: "Unspecified", value: "5" },
  ],
  uboPepFl: [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
  ],
  uboCountry: [
    { value: "India", label: "India" },
    { value: "USA", label: "USA" },
    { value: "UK", label: "UK" },
  ],
  uboTaxResidency: [
    { value: "India", label: "India" },
    { value: "USA", label: "USA" },
    { value: "UK", label: "UK" },
  ],
  uboCode: [
    { label: "CP of legal person-ownership", value: "C01" },
    { label: "CP of legal person-other means", value: "C02" },
    { label: "CP of legal person-senior managing official", value: "C03" },
    { label: "CP of legal arrangement-trust-settlor", value: "C04" },
    { label: "CP of legal arrangement-trust-trustee", value: "C05" },
    { label: "CP of legal arrangement-trust-protector", value: "C06" },
    { label: "CP of legal arrangement-trust-beneficiary", value: "C07" },
    { label: "CP of legal arrangement-trust-other", value: "C08" },
    {
      label: "CP of legal arrangement-trust-other-settlor equivalent",
      value: "C09",
    },
    {
      label: "CP of legal arrangement-trust-other-trustee-equivalent",
      value: "C10",
    },
    {
      label: "CP of legal arrangement-trust-other-protector equivalent",
      value: "C11",
    },
    {
      label: "CP of legal arrangement-trust-other-beneficiary-equivalent",
      value: "C12",
    },
    {
      label: "CP of legal arrangement-trust-other-other-equivalent",
      value: "C13",
    },
    { label: "Unknown", value: "C14" },
  ],
  uboCateg: [
    { value: "UBO", label: "UBO" },
    { value: "SMO", label: "SMO" },
  ],
};

const mandatoryFields = [
  "uboName",
  "uboNationality",
  "uboAddressType",
  "uboCountryOfBirth",
  "uboPan",
  "uboTaxResidency",
  "uboCode",
];

interface BankDetailsFormProps {
  onDirectorToBank: () => void;
  activeSection: string;
  allowToProceed: () => void;
  companyData: any;
  userData: any;
}

const Director: React.FC<BankDetailsFormProps> = ({
  onDirectorToBank,
  allowToProceed,
  activeSection,
  companyData,
  userData,
}) => {
  const [countries, setCountries] = useState([]);
  const dispatch = useDispatch<any>();
  const user = useUserData();
  console.log("Received Company Data:", companyData);

  const formatDateToDDMMYYYY = (dateString: string) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const formattedDateOfNetworth = formatDateToDDMMYYYY(companyData?.dateOfNetworth);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUserById({}));
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countriesData = data
        .filter((country: any) => country.name.common === "India")
          .map((country:any) => ({
            label: country.name.common,
            value: country.cca2, // Country code (ISO 3166-1 alpha-2)
          }))
          .sort((a:any, b:any) => a.label.localeCompare(b.label)); // Sorting countries alphabetically

        setCountries(countriesData); // Setting sorted countries data to state
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  const [formData, setFormData] = useState<Record<string, string>>({
    uboName:"",
    uboNationality:"",
    uboAddressType:"",
    uboCountryOfBirth:"",
    uboPan:"",
    uboTaxResidency:"",
    uboCode:"",
  });
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [fieldDirty, setFieldDirty] = useState<Record<string, boolean>>({});

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    field: string,
    label:string
  ) => {
    const value = event.target.value;
  
    // Update form data
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  
    // Mark the field as dirty
    setFieldDirty((prevDirty) => ({
      ...prevDirty,
      [field]: true,
    }));
  
    // Validate and set field errors
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value.trim() === "") {
        newErrors[field] = `${label} is required`; // Set error if field is empty
      } else {
        delete newErrors[field]; // Clear error if valid
      }
      return newErrors;
    });
  };
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: string, label: string) => {
    const value = e.target.value;
    setFormData({ ...formData, [field]: value });
  
    // Mark the field as dirty when user starts typing
    setFieldDirty((prevDirty) => ({ ...prevDirty, [field]: true }));
  
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
  
      // Validation for PAN field
      if (field === "uboPan") {
        const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // PAN validation regex
        if (value.trim() === "") {
          newErrors[field] = `${label} is required`; // Show error if field is empty
        } else if (!panRegex.test(value?.toUpperCase()) && value?.length>=10) {
          newErrors[field] = `${label} is invalid. Please enter a valid PAN.`; // Show error if PAN is invalid
        } else {
          delete newErrors[field]; // Remove error if PAN is valid
        }
      } else if (value.trim() === "") {
        newErrors[field] = `${label} is required`; // General required field error
      } else {
        delete newErrors[field]; // Remove error if valid
      }
  
      return newErrors;
    });
  };
  

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const handleDropdownOpen = () => setDropdownOpen(true);
  const handleDropdownClose = () => setDropdownOpen(false);

  const handleSubmit = () => {
    const fieldLabels: Record<string, string> = {
      uboName:"UBO Name",
      uboNationality:"UBO Nationality",
      uboAddressType:"UBO Address Type",
      uboCountryOfBirth:"UBO Country Of Birth",
      uboPan:"UBO Pan",
      uboTaxResidency:"UBO Tax Residency",
      uboCode:"UBO Code",
    };
    const unfilledFields = mandatoryFields.filter(
      (field) => formData[field]?.trim() === ""
    );
  
    if (unfilledFields.length > 0) {
      // Show error messages for unfilled fields
      const newErrors: Record<string, string> = {};
      unfilledFields.forEach((field) => {
        const label = fieldLabels[field] || field; // Default to field name if label is missing
        newErrors[field] = `${label} is required`;
      });
      setFieldErrors(newErrors);
      toast.error("Please fill all mandatory fields.");
      return;
    }

     // PAN Validation
  const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // Indian PAN pattern: 5 letters, 4 digits, 1 letter
  if (!panRegex.test(formData.uboPan?.toUpperCase())) {
    setFieldErrors((prevErrors) => ({
      ...prevErrors,
      uboPan: "UBO PAN is invalid. Please enter a valid PAN.",
    }));
    toast.error("UBO PAN is invalid. Please enter a valid PAN.");
    return;
  }
  
    setIsModalVisible(true);
  };

  const renderField = (
    label: string,
    type: string,
    field: string,
    options?: any
  ) => {
    const isMandatory = mandatoryFields.includes(field);
    const errorMessage = fieldErrors[field];
    return (
      <Grid item xs={12} container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="body1" sx={{ color: "gray", fontSize: "18px" }}>
            {label}
            {isMandatory && (
              <span> *</span>
            )}
          </Typography>
        </Grid>
        <Grid item mb={4}>
          {type === "dropdown" ? (
            <FormControl fullWidth>
              <Select
                value={formData[field]}
                onChange={(event) => handleSelectChange(event, field, label)}
                onOpen={handleDropdownOpen}
                onClose={handleDropdownClose}
                MenuProps={{
                  disableScrollLock: true,
                }}
                sx={{
                  backgroundColor: "white",
                  whiteSpace: "wrap",
                }}
              >
                {options?.map((option: any) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    style={{
                      whiteSpace: "wrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <TextField
              fullWidth
              type={type}
              value={field==="uboPan"?formData[field]?.toUpperCase():formData[field]}
              onChange={(e:any) => handleInputChange(e, field, label)}
              sx={{ backgroundColor: "white" }}
            />
          )}
          {errorMessage && <Typography sx={{color:"rgb(241,122,33)",fontSize:18,marginTop:1}} >{errorMessage}</Typography>}
        </Grid>
      </Grid>
    );
  };

  const handleModalConfirm = async () => {
    setIsModalVisible(false);
    console.log(formData, "FormData");
    const body = {
      organizationId: userData.organization.id,
      addressType: companyData?.addressType,
      city: companyData?.placeOfIncorporation,
      wealthSource:companyData?.wealthSource,
      incomeSlab: companyData?.incomeSlab,
      politicalExposed: companyData?.politicallyExposed,
      occupation: companyData?.occupationCode,
      occupationType: companyData?.occupationType,
      tin: companyData?.taxPayerId,
      docType: companyData?.idDocumentType,
      corpServiceSector: companyData?.corporateServiceSector,
      dateOfNetworth: formattedDateOfNetworth,
      networth: Number(Number(companyData?.netWorth).toFixed(2)),
      exempCode: companyData?.exemptionCode,
      ffi: companyData?.ffiDrnfe,
      giin: "NA",
      nfec: companyData?.entityCategory,
      nfesc: companyData?.entitySubCategory,
      businessNature: companyData?.natureOfBusiness,
      pincode:companyData?.pincode,
      uboInfo:
        companyData?.entityCategory === "L" ||
        companyData?.entityCategory === "RL"
          ? {
              name: "",
              panCard: "",
              nation: "",
              address1: "",
              address2: "",
              address3: "",
              city: "",
              pincode: "",
              state: "",
              country: "",
              addressType: "",
              tin: "",
              docType: "",
              code: "",
            }
          : {
              name: formData.uboName, // required
              panCard: formData?.uboPan?.toUpperCase(), //tin or pan
              nation: formData.uboNationality, // required
              address1: "", // required
              address2: "", // required
              address3: "",
              city: "", // required
              pincode: "",
              state: "", // required
              country: "IN", // required
              addressType: formData.uboAddressType, // required
              tin: "", //tin or pan
              docType: "C",
              code: formData.uboCode, // required
            },
    };

    console.log(body, "Body");
    try {
      const response = await dispatch(addOrganizationFatca(body));
      if (response.payload.success === 200) {
        toast.success("UBO added successfully!");
        onDirectorToBank(); // Proceed to next step
      } else {
        toast.error("Failed to add UBO. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred. Please try again.");
      console.error(error);
    }
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
    {
      user?.fatcaDone===1?(<>
      <div className="flex flex-col gap-5 items-center">
            <CheckCircle className="text-green-600 text-3xl" fontSize="large" />
            <p className="text-lg font-semibold font-inter">
              Your UBO details is already verified. Please proceed further.
            </p>
            <Button
              type="button"
              sx={{
                px: 4,
                py: 2,
                borderRadius: "8px",
                backgroundColor: colors.darkGrey,
                color: "white",
                "&:hover": {
                  backgroundColor: colors.primary,
                },
                transition: "all 0.3s ease-in-out",
                maxWidth: "250px",
                width: "100%",
                mt: 3,
                mb:6,
              }}
              onClick={onDirectorToBank}
            >
              Proceed
            </Button>
          </div>
      </>):(
          <>
          <Box p={4} marginX="8%">
            <Grid container spacing={4}>
              {/* First Section */}
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  {renderField("UBO Name", "text", "uboName")}
                  {renderField("UBO Email", "text", "uboEmail")}
                  {renderField(
                    "UBO Nationality",
                    "dropdown",
                    "uboNationality",
                    options.uboNationality
                  )}
                  {renderField("UBO Address 2", "text", "uboAddress2")}
                  {renderField("UBO City", "text", "uboCity")}
                  {renderField(
                    "UBO State Code",
                    "dropdown",
                    "uboStateCode",
                    options.uboStateCode
                  )}
                  {renderField(
                    "UBO Address Type",
                    "dropdown",
                    "uboAddressType",
                    options.uboAddressType
                  )}
                  {renderField("UBO Country of Birth", "text", "uboCountryOfBirth")}
                  {renderField("UBO Rel Li", "text", "uboRelLi")}
                  {renderField(
                    "UBO PEP FL",
                    "dropdown",
                    "uboPepFl",
                    options.uboPepFl
                  )}
                </Grid>
              </Grid>
    
              {/* Second Section */}
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  {renderField("UBO PAN", "text", "uboPan")}
                  {renderField("UBO Address 1", "text", "uboAddress1")}
                  {renderField("UBO Address 3", "text", "uboAddress3")}
                  {renderField("UBO Pincode", "text", "uboPincode")}
                  {renderField("UBO Country", "dropdown", "uboCountry", countries)}
    
                  {renderField(
                    "UBO Country of Tax Residency",
                    "dropdown",
                    "uboTaxResidency",
                    countries
                  )}
                  {renderField("UBO Code", "dropdown", "uboCode", options.uboCode)}
                  {renderField("UBO ISIN", "text", "uboIsin")}
                  {renderField(
                    "UBO Categ",
                    "dropdown",
                    "uboCateg",
                    options.uboCateg
                  )}
                  {renderField("UBO SMO De", "text", "uboSmoDe")}
                </Grid>
              </Grid>
            </Grid>
    
            <div className="w-full flex justify-center mt-6">
              <button
                type="submit"
                onClick={handleSubmit}
                className="w-full max-w-xs py-3 px-4 bg-primary text-white hover:bg-white hover:text-primary border border-primary rounded-lg text-lg"
              >
                Save and Proceed
              </button>
            </div>
          </Box>
          {isModalVisible && (
            <ConfirmationModal
              isVisible={isModalVisible}
              message="Are you sure you want to proceed? After submission, changes cannot be made."
              onConfirm={handleModalConfirm}
              onCancel={handleModalCancel}
            />
          )}
        </>
      )
    }
    </>
  );
};

export default Director;
