import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from "react-redux";
import { CancelSIP } from '../../redux/MF/MFSlice';
import ConfirmationModal from './ConfirmationModal2';
import APIloader from '../AppLoader/APIloader';
import SuccessfullModal from './SuccessfullModal';
import { useUserData } from '../../hooks/useUserData';

interface SIPItem {
  sipRegId: string;
  sipAmount: string;
  noOfSIP: string;
  createdAt: string;
  schemeName: string;
}

interface CancelSIPModalProps {
  open: boolean;
  onClose: () => void;
  mySIP: SIPItem[];
  schemeName: string;
}

const CancelSIPModal: React.FC<CancelSIPModalProps> = ({ open, onClose, mySIP, schemeName }) => {
  const dispatch = useDispatch<any>();
  const userData=useUserData();
  const [selectedRows, setSelectedRows] = useState<SIPItem[]>([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successOpen, setSuccessOpen] = useState(false);
  const [message, setMessage] = useState("");

  const handleCheckboxChange = (sip: SIPItem) => {
    setSelectedRows((prev) =>
      prev?.includes(sip) ? prev.filter((item) => item !== sip) : [...prev, sip]
    );
  };

  const handleSelectAllChange = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(mySIP);
    }
    setSelectAll(!selectAll);
  };

  const handleSubmit = () => {
    setShowModal(true);
  };

  const onConfirm =async()=>{
    try {
      setLoading(true);
      console.log(selectedRows,"selectedRows");
      // Convert the sipRegId values of selectedRows into a comma-separated string
    const regIdString = selectedRows.map((sip) => sip.sipRegId).join(',');
    console.log(regIdString,"regIdString");

    // Create the payload with the regId parameter
    const payload = {
      regId: regIdString,
      isDirectAllowed: userData?.preferences?.direct===1?1:0,
    };
      const res = await dispatch(CancelSIP(payload));
        if (res?.payload?.success === 200) {
          setMessage("SIP Cancelled successfully.");
        } else {
          setMessage(res?.payload?.message);
        }
        setTimeout(() => {
          setLoading(false);
          setSuccessOpen(true);
        }, 5000);

      } catch (error) {
      console.error('Error submitting data:', error);
    }finally{
      onClose();
      setShowModal(false);
    }
  }
  const onCancel =()=>{
    setShowModal(false);
  }
  const handleSuccessClose =()=>{
    setSuccessOpen(false);
  }

  return (
    <>
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h6" component="h2" gutterBottom>
          <div className="text-2xl font-[600] text-primary mb-4">{schemeName}</div>
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Checkbox
                    checked={selectAll}
                    onChange={handleSelectAllChange}
                  />
                  Select All
                </TableCell>
                <TableCell align="center">Start Date</TableCell>
                <TableCell align="center">No. of Installments</TableCell>
                <TableCell align="center">SIP Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {mySIP.map((sip) => (
                <TableRow key={sip.sipRegId}>
                  <TableCell align="center">
                    <Checkbox
                      checked={selectedRows?.includes(sip)}
                      onChange={() => handleCheckboxChange(sip)}
                    />
                  </TableCell>
                  <TableCell align="center">{sip.createdAt}</TableCell>
                  <TableCell align="center">{sip.noOfSIP}</TableCell>
                  <TableCell align="center">{sip.sipAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          disabled={selectedRows.length === 0}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Modal>
    <Box sx={{ zIndex: 1400, position: 'relative' }}>
    <ConfirmationModal
        isVisible={showModal}
        message="Are you sure you want to cancel the selected SIP(s)?"
        buttonFirst="Cancel"
        buttonSecond="Confirm"
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </Box>
    <APIloader loadingModal={loading} message={"Processing your SIP cancellation request..."} />
    <SuccessfullModal open={successOpen} message={message} handleClose={handleSuccessClose} />
    </>

  );
};

export default CancelSIPModal;
