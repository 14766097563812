import React, { useState, useEffect } from "react";
import {
  TextField,
  MenuItem,
  Button,
  Grid,
  Typography,
  Box,
  Select,
  InputLabel,
  FormControl,
  SelectChangeEvent,
  Menu,
  Modal,
  IconButton,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import toast from "react-hot-toast";
import { colors } from "../../../constants/colors";
import { useUserData } from "../../../hooks/useUserData";
import { CheckCircle } from "@mui/icons-material";

interface BankDetailsFormProps {
  onCompanyToDirector: () => void;
  activeSection: string;
  allowToProceed: () => void;
  onSave: (data: object) => void;
}

const options = {
  idDocumentType: [
    { label: "Passport", value: "A" },
    { label: "Election ID Card", value: "B" },
    { label: "PAN Card", value: "C" },
    { label: "ID Card", value: "D" },
    { label: "Driving License", value: "E" },
    { label: "UIDIA / Aadhar letter", value: "G" },
    { label: "NREGA Job Card", value: "H" },
    { label: "Others", value: "O" },
    { label: "Not categorized", value: "X" },
    { label: "TIN [Tax Payer Identification Number]", value: "T" },
    { label: "Company Identification Number", value: "C1" },
    { label: "US GIIN", value: "G1" },
    { label: "Global Entity Identification Number", value: "E1" },
  ],
  incomeSlab: [
    { value: "31", label: "Below 1 Lakh" },
    { value: "32", label: "1 to 5 Lacs" },
    { value: "33", label: "5 to 10 Lacs" },
    { value: "34", label: "10 to 25 Lacs" },
    { value: "35", label: "25 Lacs to 1 Crore" },
    { value: "36", label: "Above 1 Crore" },
  ],
  occupationCode: [
    { value: "01", label: "Business" },
    { value: "02", label: "Service" },
    { value: "03", label: "Professional" },
    { value: "04", label: "Agriculturist" },
    { value: "05", label: "Retired" },
    { value: "06", label: "Housewife" },
    { value: "07", label: "Student" },
    { value: "08", label: "Others" },
    { value: "09", label: "Doctor" },
    { value: "41", label: "Private Sector Service" },
    { value: "42", label: "Public Sector Service" },
    { value: "43", label: "Forex Dealer" },
    { value: "44", label: "Government Service" },
    { value: "99", label: "Unknown / Not Applicable" },
  ],
  exemptionCode: [
    {
      value:
        "An organization exempt from tax under section 501(a) or any individual retirement plan as defined in section 7701(a)(37)",
      label: "A",
    },
    {
      value: "The United States or any of its agencies or instrumentalities",
      label: "B",
    },
    {
      value:
        "A state, the District of Columbia, a possession of the United States, or any of their political subdivisions or instrumentalities",
      label: "C",
    },
    {
      value:
        "A corporation the stock of which is regularly traded on one or more established securities markets, as described in Reg. section 1.1472-1(c)(1)(i)",
      label: "D",
    },
    {
      value:
        "A corporation that is a member of the same expanded affiliated group as a corporation described in Reg. section 1.1472-1(c)(1)(i)",
      label: "E",
    },
    {
      value:
        "A dealer in securities, commodities, or derivative financial instruments (including notional principal contracts, futures, forwards, and options) that is registered as such under the laws of the United States or any state",
      label: "F",
    },
    { value: "A real estate investment trust", label: "G" },
    {
      value:
        "A regulated investment company as defined in section 851 or an entity registered at all times during the tax year under the Investment Company Act of 1940",
      label: "H",
    },
    { value: "A common trust fund as defined in section 584(a)", label: "I" },
    { value: "A bank as defined in section 581", label: "J" },
    { value: "Broker", label: "K" },
    {
      value:
        "A trust exempt from tax under section 664 or described in section 4947(a)(1)",
      label: "L",
    },
    {
      value:
        "A tax exempt trust under a section 403(b) plan or section 457(g) plan",
      label: "M",
    },
    { value: "Not Applicable", label: "N" },
  ],

  entityCategory: [
    { label: "Listed entity", value: "L" },
    { label: "Related to listed entity", value: "RL" },
    { label: "Active NFFE", value: "A" },
    { label: "Passive NFFE", value: "P" },
    { label: "Not Applicable for Non NFFE", value: "NA" },
  ],
  entitySubCategory: [
    {
      value:
        "Less than 50 percent of the NFE's gross income for the preceding financial year is passive income and less than 50 percent of the assets held by the NFE during the preceding financial year are assets that produce or are held for the production of passive income",
      label: "01",
    },
    {
      value:
        "The NFE is a Governmental Entity, an International Organization, a Central Bank, or an entity wholly owned by one or more of the foregoing",
      label: "02",
    },
    {
      value:
        "Substantially all of the activities of the NFE consist of holding (in whole or in part) the outstanding stock of, or providing financing and services to, one or more subsidiaries that engage in trades or businesses other than the business of a Financial Institution, except that an entity shall not qualify for this status if the entity functions as an investment fund, such as a private equity fund, venture capital fund, leveraged buyout fund, or any investment vehicle whose purpose is to acquire or fund companies and then hold interests in those companies as capital assets for investment purposes",
      label: "03",
    },
    {
      value:
        "The NFE is not yet operating a business and has no prior operating history, but is investing capital into assets with the intent to operate a business other than that of a Financial Institution, provided that the NFE shall not qualify for this exception after the date that is 24 months after the date of the initial organization of the NFE",
      label: "04",
    },
    {
      value:
        "The NFE was not a Financial Institution in the past five years, and is in the process of liquidating its assets or is reorganizing with the intent to continue or recommence operations in a business other than that of a Financial Institution",
      label: "05",
    },
    {
      value:
        "The NFE primarily engages in financing and hedging transactions with, or for, Related Entities that are not Financial Institutions, and does not provide financing or hedging services to any Entity that is not a Related Entity, provided that the group of any such Related Entities is primarily engaged in a business other than that of a Financial Institution",
      label: "06",
    },
    {
      value:
        "Any NFE that fulfills all of the following requirements: It is established and operated in India exclusively for religious, charitable, scientific, artistic, cultural, athletic, or educational purposes; or it is established and operated in India and it is a professional organization, business league, chamber of commerce, labor organization, agricultural or horticultural organization, civic league or an organization operated exclusively for the promotion of social welfare; It is exempt from income tax in India; It has no shareholders or members who have a proprietary or beneficial interest in its income or assets; The applicable laws of the NFE's country or territory of residence or the NFE's formation documents do not permit any income or assets of the NFE to be distributed to, or applied for the benefit of, a private person or non-charitable Entity other than pursuant to the conduct of the NFE's charitable activities, or as payment of reasonable compensation for services rendered, or as payment representing the fair market value of property which the NFE has purchased; and The applicable laws of the NFE's country or territory of residence or the NFE's formation documents require that, upon the NFE's liquidation or dissolution, all of its assets be distributed to a governmental entity or other non-profit organization, or escheat to the government of the NFE's country or territory of residence or any political subdivision thereof.",
      label: "07",
    },
  ],
  addressType: [
    { label: "Residential or Business", value: "1" },
    { label: "Residential", value: "2" },
    { label: "Business", value: "3" },
    { label: "Registered Office", value: "4" },
    { label: "Unspecified", value: "5" },
  ],
  countryOfIncorporation: [
    { label: "India", value: "India" },
    { label: "USA", value: "USA" },
    { label: "UK", value: "UK" },
  ],
  residentialCountry: [
    { label: "India", value: "India" },
    { label: "USA", value: "USA" },
    { label: "UK", value: "UK" },
  ],
  wealthSource: [
    { label: "Salary", value: "01" },
    { label: "Business Income", value: "02" },
    { label: "Gift", value: "03" },
    { label: "Ancestral Property", value: "04" },
    { label: "Rental Income", value: "05" },
    { label: "Prize Money", value: "06" },
    { label: "Royalty", value: "07" },
    { label: "Others", value: "08" },
  ],
  corporateServiceSector: [
    { label: "Foreign Exchange / Money Changer Services", value: "01" },
    {
      label: "Gaming / Gambling / Lottery Services [e.g. casinos, betting]",
      value: "02",
    },
    { label: "Money laundering / Pawning", value: "03" },
    { label: "to be blank if the same is not applicable", value: "04" },
  ],
  occupationType: [
    { label: "Service", value: "S" },
    { label: "Business", value: "B" },
    { label: "Other", value: "O" },
  ],
  politicallyExposed: [
    { label: "Politically Exposed Person", value: "Y" },
    { label: "Not a Politically Exposed Person", value: "N" },
    { label: "Relative of the Politically Exposed Person", value: "R" },
  ],
  ffiDrnfe: [
    { label: "FFI", value: "FFI" },
    { label: "DRNFE", value: "DRNFE" },
    { label: "NA", value: "NA" },
  ],
  uboGender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ],
};

const ModalTable = ({
  title,
  data,
  onClose,
}: {
  title: string;
  data: { label: string; value: string }[];
  onClose: () => void;
}) => {
  // Log the data passed to the modal
  console.log("Modal Data:", data);

  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "75%",
          height: "60%",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          overflow: "auto",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" gutterBottom>
            {title}
          </Typography>
          <CloseIcon onClick={onClose} />
        </Box>

        {data && data.length > 0 ? (
          <Box>
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: colors.lightBg,
                    borderBottom: "2px solid #ccc",
                    "& th": {
                      backgroundColor: colors.lightBg,
                      fontWeight: 600,
                      color: colors.primary,
                      fontSize: "16px",
                      borderBottom: "2px solid #ccc",
                    },
                  }}
                >
                  <TableRow>
                    <TableCell
                      style={{ border: "1px solid #ddd", padding: "8px" }}
                    >
                      CODE
                    </TableCell>
                    <TableCell
                      style={{
                        border: "1px solid #ddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      PARTICULARS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        {row.label}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                        }}
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ) : (
          <Typography>No data available</Typography>
        )}
      </Box>
    </Modal>
  );
};
const mandatoryFields = [
  "placeOfIncorporation",
  "taxPayerId",
  "idDocumentType",
  "incomeSlab",
  "occupationCode",
  "netWorth",
  "exemptionCode",
  "entityCategory",
  "entitySubCategory",
  "addressType",
  "countryOfIncorporation",
  "residentialCountry",
  "wealthSource",
  "corporateServiceSector",
  "dateOfNetworth",
  "occupationType",
  "ffiDrnfe",
  "natureOfBusiness",
  "pincode",
];

const Company: React.FC<BankDetailsFormProps> = ({
  onCompanyToDirector,
  allowToProceed,
  activeSection,
  onSave,
}) => {
  const [formData, setFormData] = useState<Record<string, string>>({
    dateOfIncorporation: "",
    placeOfIncorporation: "",
    taxPayerId: "",
    idDocumentType: "",
    incomeSlab: "",
    occupationCode: "",
    netWorth: "",
    exemptionCode: "",
    entityCategory: "",
    entitySubCategory: "",
    spouseName: "",
    sprEntity: "",
    addressType: "",
    countryOfIncorporation: "",
    residentialCountry: "",
    wealthSource: "",
    corporateServiceSector: "",
    dateOfNetworth: "",
    occupationType: "",
    politicallyExposed: "",
    ffiDrnfe: "",
    natureOfBusiness: "",
    fathersName: "",
    uboGender: "",
    pincode: "",
  });
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({}); // Track errors for each mandatory field
  const [isFormValid, setIsFormValid] = useState(false);
  const [countries, setCountries] = useState([]);
  const user = useUserData();
  const [fieldDirty, setFieldDirty] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const isValid = mandatoryFields.every(
      (field) => formData[field]?.trim() !== ""
    );
    setIsFormValid(isValid);
  }, [formData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetch("https://restcountries.com/v3.1/all")
      .then((response) => response.json())
      .then((data) => {
        const countriesData = data
          .filter((country: any) => country.name.common === "India")
          .map((country: any) => ({
            label: country.name.common,
            value: country.cca2, // Country code (ISO 3166-1 alpha-2)
          }))
          .sort((a: any, b: any) => a.label.localeCompare(b.label)); // Sorting countries alphabetically

        setCountries(countriesData); // Setting sorted countries data to state
      })
      .catch((error) => console.error("Error fetching country data:", error));
  }, []);

  const [modalData, setModalData] = useState<{
    title: string;
    data: { label: string; value: string }[];
  } | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleOpenModal = (
    title: string,
    data: { label: string; value: string }[]
  ) => {
    console.log("Opening Modal with data:", data); // Log the data before opening
    setModalData({ title, data });
  };

  const handleCloseModal = () => {
    setModalData(null);
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Disable scroll when dropdown is open
  useEffect(() => {
    if (dropdownOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto"; // Reset on cleanup
    };
  }, [dropdownOpen]);

  const handleDropdownOpen = () => setDropdownOpen(true);
  const handleDropdownClose = () => setDropdownOpen(false);

  const handleSubmit = () => {
    // Map mandatory fields to their respective labels
    const fieldLabels: Record<string, string> = {
      dateOfIncorporation: "Date of Incorporation",
      placeOfIncorporation: "Place of Incorporation",
      taxPayerId: "Taxpayer ID",
      idDocumentType: "ID Document Type",
      incomeSlab: "Income Slab",
      occupationCode: "Occupation Code",
      netWorth: "Net Worth",
      exemptionCode: "Exemption Code",
      entityCategory: "Entity Category",
      entitySubCategory: "Entity Subcategory",
      spouseName: "Spouse Name",
      sprEntity: "SPR Entity",
      addressType: "Address Type",
      countryOfIncorporation: "Country of Incorporation",
      residentialCountry: "Residential Country",
      wealthSource: "Source of Wealth",
      corporateServiceSector: "Corporate Service Sector",
      dateOfNetworth: "Date of Net Worth",
      occupationType: "Occupation Type",
      politicallyExposed: "Politically Exposed Person",
      ffiDrnfe: "FFI/DRNFE",
      natureOfBusiness: "Nature of Business",
      fathersName: "Father's Name",
      uboGender: "UBO Gender",
      pincode: "Pincode",
    };
  
    const unfilledFields = mandatoryFields.filter(
      (field) => formData[field]?.trim() === ""
    );
  
    if (unfilledFields.length > 0) {
      // Show error messages for unfilled fields
      const newErrors: Record<string, string> = {};
      unfilledFields.forEach((field) => {
        const label = fieldLabels[field] || field; // Default to field name if label is missing
        newErrors[field] = `${label} is required`;
      });
      setFieldErrors(newErrors);
      toast.error("Please fill all mandatory fields.");
      return;
    }
  
    // Proceed if form is valid
    setIsModalVisible(true);
  };
  

  // const handleSubmit = () => {
  //   const unfilledFields = mandatoryFields.filter(
  //     (field) => formData[field]?.trim() === ""
  //   );
  //   if (unfilledFields.length > 0) {
  //     // Show error messages for unfilled fields
  //     const newErrors: Record<string, string> = {};
  //     unfilledFields.forEach((field) => {
  //       newErrors[field] = `${label} is required`; // Customize error message as needed
  //     });
  //     setFieldErrors(newErrors);
  //     toast.error("Please fill all mandatory fields.");
  //     return;
  //   }
  //   setIsModalVisible(true);
  // };

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    field: string,
    label:string
  ) => {
    const value = event.target.value;
  
    // Update form data
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  
    // Mark the field as dirty
    setFieldDirty((prevDirty) => ({
      ...prevDirty,
      [field]: true,
    }));
  
    // Validate and set field errors
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value.trim() === "") {
        newErrors[field] = `${label} is required`; // Set error if field is empty
      } else {
        delete newErrors[field]; // Clear error if valid
      }
      return newErrors;
    });
  };
  

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: string, label:string) => {
    const value = e.target.value;
    setFormData({ ...formData, [field]: value });

    // Mark the field as dirty when user starts typing
    setFieldDirty((prevDirty) => ({ ...prevDirty, [field]: true }));

    // Clear or set the error message based on the field value
    setFieldErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (value.trim() === "") {
        newErrors[field] = `${label} is required`; // Show error if field is empty
      } else {
        delete newErrors[field]; // Remove error if field is valid
      }
      return newErrors;
    });
  };


  const renderField = (
    label: string,
    type: string,
    field: string,
    options?: any
  ) => {
    const isMandatory = mandatoryFields.includes(field);
    const errorMessage = fieldErrors[field];
    return (
         <Grid
      item
      xs={12}
      container
      direction="column"
      spacing={1}
    >
        <Grid item>
          <Typography variant="body1" sx={{ color: "gray", fontSize: "18px" }}>
            {label}
            {isMandatory && <span> *</span>}
          </Typography>
        </Grid>
        <Grid item mb={4}>
          {type === "dropdown" ? (
            <FormControl fullWidth>
              {/* <InputLabel>{label}</InputLabel> */}
              {field === "exemptionCode" || field === "entitySubCategory" ? (
                <FormControl fullWidth>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Select
                      value={formData[field]}
                      onChange={(event) => handleSelectChange(event, field, label)}
                      onOpen={handleDropdownOpen}
                      onClose={handleDropdownClose}
                      MenuProps={{
                        disableScrollLock: true,
                      }}
                      sx={{
                        backgroundColor: "white",
                        flexGrow: 1, // Ensures Select takes up available space
                        whiteSpace: "wrap",
                      }}
                    >
                      {options?.map((option: any) => (
                        <MenuItem
                          key={option.value}
                          value={option.label}
                          style={{
                            whiteSpace: "wrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <IconButton
                      onClick={() => handleOpenModal(label, options)}
                      sx={{ ml: 1 }}
                    >
                      <InfoIcon />
                    </IconButton>
                  </Box>
                </FormControl>
              ) : (
                <FormControl fullWidth>
                <Select
                  value={formData[field]}
                  onChange={(event) => handleSelectChange(event, field ,label)}
                  onOpen={handleDropdownOpen}
                  onClose={handleDropdownClose}
                  MenuProps={{
                    disableScrollLock: true,
                  }}
                  sx={{
                    backgroundColor: "white",
                    whiteSpace: "wrap",
                  }}
                >
                  {options?.map((option: any) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      style={{
                        whiteSpace: "wrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                </FormControl>
              )}
            </FormControl>
          ) : (
            <TextField
              fullWidth
              type={type}
              value={formData[field]}
              onChange={(e:any) => handleInputChange(e, field, label)}
              sx={{
                backgroundColor: "white",
              }}
            />
          )}
          {errorMessage && <Typography sx={{color:"rgb(241,122,33)",fontSize:18,marginTop:1}} >{errorMessage}</Typography>}
        </Grid>
      </Grid>
    );
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false);
    onCompanyToDirector();
    console.log(formData, "Company");
    onSave(formData);
    // if (formikRef.current) {

    //   console.log('handle submit', formikRef?.current);

    //   formikRef?.current?.handleSubmit();
    //}
  };
  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      {user?.fatcaDone === 1 ? (
        <>
          <div className="flex flex-col gap-5 items-center">
            <CheckCircle className="text-green-600 text-3xl" fontSize="large" />
            <p className="text-lg font-semibold font-inter">
              Your company details is already verified. Please proceed further.
            </p>
            <Button
              type="button"
              sx={{
                px: 4,
                py: 2,
                borderRadius: "8px",
                backgroundColor: colors.darkGrey,
                color: "white",
                "&:hover": {
                  backgroundColor: colors.primary,
                },
                transition: "all 0.3s ease-in-out",
                maxWidth: "250px",
                width: "100%",
                mt: 3,
                mb: 6,
              }}
              onClick={onCompanyToDirector}
            >
              Proceed
            </Button>
          </div>
        </>
      ) : (
        <>
          <Box p={4} marginX="8%">
            <Grid container spacing={4}>
              {/* Left Section */}
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  {renderField(
                    "Date Of Incorporation",
                    "date",
                    "dateOfIncorporation"
                  )}
                  {renderField(
                    "Place Of Incorporation (City)",
                    "text",
                    "placeOfIncorporation"
                  )}
                  {renderField(
                    "Tax Payer Identification No.",
                    "text",
                    "taxPayerId"
                  )}
                  {renderField(
                    "Identification Document Type",
                    "dropdown",
                    "idDocumentType",
                    options.idDocumentType
                  )}
                  {renderField(
                    "Income Slab",
                    "dropdown",
                    "incomeSlab",
                    options.incomeSlab
                  )}
                  {renderField(
                    "Occupation Code",
                    "dropdown",
                    "occupationCode",
                    options.occupationCode
                  )}
                  {renderField("Net Worth (in Lakhs)", "text", "netWorth")}
                  {renderField(
                    "Exemption Code",
                    "dropdown",
                    "exemptionCode",
                    options.exemptionCode
                  )}
                  {renderField(
                    "Non Financial Entity Category",
                    "dropdown",
                    "entityCategory",
                    options.entityCategory
                  )}
                  {renderField(
                    "Non Financial Entity Sub Category",
                    "dropdown",
                    "entitySubCategory",
                    options.entitySubCategory
                  )}
                  {renderField("Spouse Name", "text", "spouseName")}
                  {renderField("SPR Entity", "text", "sprEntity")}
                  {renderField(
                    "UBO Gender",
                    "dropdown",
                    "uboGender",
                    options.uboGender
                  )}
                </Grid>
              </Grid>
              {/* Right Section */}
              <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                  {renderField(
                    "Address Type",
                    "dropdown",
                    "addressType",
                    options.addressType
                  )}
                  {renderField("Pincode", "text", "pincode")}
                  {renderField(
                    "Country of Incorporation",
                    "dropdown",
                    "countryOfIncorporation",
                    countries
                  )}
                  {renderField(
                    "Residential Country",
                    "dropdown",
                    "residentialCountry",
                    countries
                  )}
                  {renderField(
                    "Wealth Source",
                    "dropdown",
                    "wealthSource",
                    options.wealthSource
                  )}
                  {renderField(
                    "Corporate Service Sector",
                    "dropdown",
                    "corporateServiceSector",
                    options.corporateServiceSector
                  )}
                  {renderField("Date of Networth", "date", "dateOfNetworth")}
                  {renderField(
                    "Occupation Type",
                    "dropdown",
                    "occupationType",
                    options.occupationType
                  )}
                  {renderField(
                    "Politically Exposed?",
                    "dropdown",
                    "politicallyExposed",
                    options.politicallyExposed
                  )}
                  {renderField(
                    "FFI/DRNFE",
                    "dropdown",
                    "ffiDrnfe",
                    options.ffiDrnfe
                  )}
                  {renderField(
                    "Nature Of Business",
                    "text",
                    "natureOfBusiness"
                  )}
                  {renderField("Fathers Name", "text", "fathersName")}
                </Grid>
              </Grid>
              {/* Modal for Info */}
              {modalData && (
                <ModalTable
                  title={modalData.title}
                  data={modalData.data}
                  onClose={handleCloseModal}
                />
              )}
            </Grid>

            <div className="w-full flex justify-center mt-6">
              <button
                type="submit"
                onClick={handleSubmit}
                className="w-full max-w-xs py-3 px-4 bg-primary text-white hover:bg-white hover:text-primary border border-primary rounded-lg text-lg"
              >
                Save and Proceed
              </button>
            </div>
          </Box>

          {isModalVisible && (
            <ConfirmationModal
              isVisible={isModalVisible}
              message="Are you sure you want to proceed? After submission, changes cannot be made."
              onConfirm={handleModalConfirm}
              onCancel={handleModalCancel}
            />
          )}
        </>
      )}
    </>
  );
};

export default Company;
