import api from "../../config/apiConfig";

const getAllStocks = async (data: any) => {
  const accessToken = localStorage.getItem("accessToken");
  const response = await api.post("stocks/getAll", data, {
    headers: {
      Authorization: accessToken,
    },
  });
  return response.data.data;
};


const StockService = {
    getAllStocks,
};
export default StockService;
