import {
  Box,
  Checkbox,
  Collapse,
  Divider,
  Fade,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import React, { useCallback, useState } from "react";
import { Dots } from "react-activity";
import "react-activity/dist/library.css";
import { useDispatch, useSelector } from "react-redux";
import { getFDSchemeInfo, getFDinfo } from "../../redux/FD/FDSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BorderBottom, InfoOutlined, MoreVert, ReportProblem } from "@mui/icons-material";
import getInitials from "../../utils/getInitailasIMGUtils";
import { colors } from "../../constants/colors";
import { addToWatchlist, removeToWatchlist } from "../../redux/wishlists/wishlistSlice";
import toast from "react-hot-toast";
import InvestModal from "../modals/FDRequestModal";
import TableRowComponent from "./TableRowComponent";
interface Data {
  schemes: Scheme[];
}

interface Scheme {
  schemeName: string;
  plans: Plan[];
  ratings: string;
  minInvestAmountinINR: {
    monthly: number;
    quaterly: number;
    halfYearly: number;
    annual: number;
    cumulativeDeposit: number;
    annualisedYield: number;
  };
  maxInvestAmountinINR: number;
}

interface Plan {
  id: number;
  interestRates: {
    annual: number;
  };
  name: string;
  plans: {};
  ratings: string;
  minInvestAmountInINR: number;
  maxInvestAmountinINR: number;
}

interface Result {
  highestRateOverall: number;
  planWithHighestRateOverall: Plan | null;
  highestRatePerScheme: { [schemeName: string]: { highestRate: number; highestPlanId: number } };
}

const Rows = ({ data, setLoading,setDataById, setTenureALL, openInvestModal }: any) => {
  const dispatch = useDispatch<any>();
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);
  const openMenuMD = Boolean(anchorMD);
  const [isinWishilist, setIsinWishilist] = useState<any>();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const modalMenuClick = (event: React.MouseEvent<HTMLElement>, isinWishilist: number, id: number) => {
    setAnchorMD(event.currentTarget);
    let data = { status: isinWishilist, id: id }
    setIsinWishilist(data)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMD = () => {
    setAnchorMD(null);
  };

  const fetchData = async (id: number) => {
    setLoading(true);
    try {
      const response = await dispatch(getFDSchemeInfo({ schemeId: id }));

      if (response?.payload) {
        setDataById(response.payload.scheme);
        setTenureALL(response?.payload?.scheme?.tenures);
        openInvestModal();
      } else {
        toast.error("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  }
  // console.log('data on fd', data);

  return (
    <>
      <TableRowComponent
        data={data}
        modalMenuClick={modalMenuClick}
      />
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMD}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            backgroundColor: '#255288',
            color: '#fff',
            overflow: 'hidden',
            borderRadius: '10px',
            position: 'relative',
            width: isSmallScreen ? '80%' : '12%',
          },
        }}
        className="relative"
      >
        <div
          className="absolute bg-white"
          style={{
            width: '20px',
            height: '20px',
            transform: 'rotate(315deg)',
            bottom: 'calc(100% - 10px)',
            left: 'calc(50% - 10px)',
          }}
        />
      </Menu>
    </>
  );
};
const BondTable = ({ data, loader }: any) => {
  const [sortConfig, setSortConfig] = useState<{ key: string | null; direction: 'ascending' | 'descending' }>({
    key: null,
    direction: 'descending', // Default direction for initial sorting
  });

  const parseDate = (dateString: string) => {
    const [day, month, year] = dateString.split("/").map(Number);
    return new Date(year, month - 1, day).getTime(); 
  };

  const sortedData = data ? [...data].sort((a, b) => {
    if (!sortConfig.key) return 0;
    
    let comparison = 0;
    switch (sortConfig.key) {
      case 'promoter':
        comparison = a.promoter.localeCompare(b.promoter);
        break;
        case 'minInvestment':
      comparison = a.faceValue - b.faceValue;
      break;
    case 'bondPrice':
      comparison = a.bondPrice - b.bondPrice;
      break;
    case 'interestPaymentFrequency':
      comparison = a.interestPaymentFrequency.localeCompare(b.interestPaymentFrequency);
      break;
      case 'maturityDate':
        comparison = parseDate(a.maturityDate) - parseDate(b.maturityDate);
        break;
      default:
        break;
    }
    
    return sortConfig.direction === 'ascending' ? comparison : -comparison;
  }) : [];

  const handleSort = (key: string) => {
    setSortConfig(prevConfig => {
      const isSameColumn = prevConfig.key === key;
      const direction = isSameColumn && prevConfig.direction === 'descending' ? 'ascending' : 'descending';
      return { key, direction };
    });
  };

  return (
    <>
      <TableContainer component={Paper}
        sx={{
          boxShadow: "none",
          border: "none",
          maxHeight: 550,
          overflow: "auto",
        }}>
        <Table aria-label="collapsible table" >
          <TableHead sx={{
            borderBottom: "2px solid #ccc",
            backgroundColor: colors.lightBg,
            "& th": {
              backgroundColor: colors.lightBg,
              border: "none",
              paddingTop: "2%",
              paddingBottom: "2%",
              borderBottom: "2px solid #ccc",
            },
          }}  >
            <TableRow sx={{
              position: "sticky",
              top: 0,
              zIndex: 1000,
              borderBottom: "2px solid #ccc",
              backgroundColor: colors.lightBg,
              "& th": {
                backgroundColor: colors.lightBg,
                border: "none",
                paddingTop: "2%",
                paddingBottom: "2%",
                borderBottom: "2px solid #ccc",
              },
            }}>
              {/* <TableCell padding="checkbox" /> */}
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
                
                <div className={`flex items-center w-auto`}>
                  <>Scheme Name</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort('promoter')}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort('promoter')}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
              <div className={`flex items-center w-auto`}>
                  <>Minimum Investment</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort('faceValue')}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort('faceValue')}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">                
                <div className={`flex items-center w-auto justify-center`}>
                  Yield
                </div>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
              <div className={`flex items-center w-auto`}>
                  <>Price</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort('bondPrice')}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort('bondPrice')}
                    />
                  </div>
                </div>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
              <div className={`flex items-center w-auto`}>
                  <>IP Frequency</>
                  {/* <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort('interestPaymentFrequency')}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort('interestPaymentFrequency')}
                    />
                  </div> */}
                </div>
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
                Coupon
              </TableCell>
              <TableCell sx={{ fontWeight: 600, color: colors.primary, fontSize: '16px' }} align="center">
              <div className={`flex items-center w-auto`}>
                  <>Maturity Date</>
                  <div className="flex flex-col items-center justify-center w-10 h-2 ml-2">
                    <KeyboardArrowUpIcon
                      className="w-2 mb-[-10px] cursor-pointer"
                      onClick={() => handleSort('maturityDate')}
                    />
                    <KeyboardArrowDownIcon
                      className="cursor-pointer"
                      onClick={() => handleSort('maturityDate')}
                    />
                  </div>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <div className="flex justify-center items-center h-full">
              <div className="flex flex-col justify-center items-center">
                <Dots />
              </div>
            </div>
          ) : (
            <TableBody className="" sx={{
              border: 'none',
              '& .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root': {
                borderBottom: 'none',
              },
            }} >
              <TableRow sx={{ height: '5%', }}>
                <TableCell colSpan={8} align="center">

                </TableCell>
              </TableRow>

              {sortedData && sortedData.length > 0 ? (
                <Rows data={sortedData} />
              )


                : <TableRow>
                  <TableCell colSpan={8} align="center">
                    No similar plans available.
                  </TableCell>
                </TableRow>}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default BondTable;