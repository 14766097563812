import React, { FC, useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import { colors } from "../../constants/colors";
import { Button } from "../Button";
import plus from "../../../src/assets/icons/plus-circle-outline.png";
import MfInfo from "../../components/MFInfoSection/MFInfo";
import getInitials from "../../utils/getInitailasIMGUtils";
import { Formik, FormikProps } from "formik";
import * as yup from "yup";
import { FormInput } from "../FormInput/FormInput";
import FormSelect from "../FormSelect/FormSelect";
import schemeTypes from "../../constants/schemeTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getSTPFunds,
  InvestRequest,
  switchMutualFund,
} from "../../redux/MF/MFSlice";
import { useNavigate, useParams } from "react-router-dom";
import { Autocomplete, TextField } from "@mui/material";
import MFDetailCard from "./MFDetailCard";
import { KeyboardArrowDown } from "@mui/icons-material";
import toast from "react-hot-toast";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import AmountCard from "./AmountCard";
import MFFooterCard from "./MFFooterCard";
import {
  Button as Btn,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { useUserData } from "../../hooks/useUserData";
import { showInterest } from "../../redux/user/userSlice";
import SuccessfullModal from "../modals/SuccessfullModal";
import { BrowserPopUpModal } from "../../utils/BrowserPopUpModal";
import APIloader from "../AppLoader/APIloader";

const FDDetailCard = (data: any) => {
  return (
    <>
      <div className="max-w-[800px]">
        <div className="pl-5 py-2 flex flex-col gap-y-2 rounded-md bg-opacity-80 min-w-[150px] md:min-w-[200px]">
          <div className="text-lg font-medium">{data.label}</div>
          <div className="text-xl font-bold" style={{ color: colors.textGrey }}>
            {data.value}
          </div>
        </div>
      </div>
    </>
  );
};

const switchDetails = [
  {
    label: "AUM(in Cr)",
    value: "Rs. 18.66L",
  },
  {
    label: "Current NAV",
    value: "263",
  },
  {
    label: "Rank",
    value: "01",
  },
  {
    label: "Risk Level",
    value: "High",
  },
];

interface FormInputProps {
  label: string;
  type: string;
  id?: string;
  altText?: string;
  name?: string;
  onChange: any;
  value?: any;
  disabled?: boolean;
}

interface MFProps {
  activeSection: string;
  fundInfo?: any;
  onClose: any;
}

// const FormInput: React.FC<FormInputProps> = ({
//   label,
//   type,
//   id,
//   altText,
//   name,
//   onChange,
//   value,
//   disabled = false,
// }) => {
//   if (
//     label === "Scheme Name" ||
//     label === "Scheme Option" ||
//     label === "Destination Scheme Type" ||
//     label === "Source Scheme"
//   ) {
//     return (
//       <div
//         className="flex flex-col text-lg mb-10"
//         style={{ color: colors.darkGrey }}
//       >
//         <label htmlFor={id} className="" style={{ color: colors.darkGrey }}>
//           {label}
//         </label>
//         <select
//           id={id}
//           className="shrink-0 mt-3 rounded-lg p-4 gap-10"
//           style={{
//             color: colors.darkGrey,
//             backgroundColor: colors.white,
//             border: `1px solid ${colors.darkBg}`,
//           }}
//           aria-label={altText || label}
//           name={name}
//           onChange={onChange}
//           value={value}
//           disabled={disabled}
//         >
//           <option value="Select Account Type"></option>
//           <option value="acc1">Option 1</option>
//           <option value="acc2">Option 2</option>
//           <option value="acc3">OPtion 3</option>
//         </select>
//       </div>
//     );
//   } else {
//     return (
//       <div
//         className="flex flex-col text-lg mb-10 "
//         style={{ color: colors.darkGrey }}
//       >
//         <label htmlFor={id} className="" style={{ color: colors.darkGrey }}>
//           {label}
//         </label>
//         <input
//           className="shrink-0 mt-3 rounded-lg p-4 "
//           style={{
//             color: colors.darkGrey,
//             backgroundColor: colors.white,
//             border: `1px solid ${colors.darkBg}`,
//           }}
//           type={type}
//           id={id}
//           aria-label={altText || label}
//           name={name}
//           onChange={onChange}
//           value={value}
//           disabled={disabled}
//         />
//       </div>
//     );
//   }
// };

const SwitchDetails: React.FC<MFProps> = ({ fundInfo, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  const { id: fundId } = useParams();
  const [investmentPlan, setInvestmentPlan] = useState<string>("Amount");
  const formikRef = useRef<any>(null);
  const switchFunds = useSelector((state: any) => state?.MFund?.funds);
  const [availableFunds, setAvailableFunds] = useState<any>([]);
  const [selectedFund, setSelectedFund] = useState<any>();
  const { bank } = useUserData() || { bank: [] };
  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadmessage, setLoadMessage] = useState<string>("");
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [authLink, setAuthLink] = useState("");
  const [orderID, setOrderID] = useState("");
  const [fundParams, setFundParams] = useState<any>({
    "Total Units":
      fundInfo?.totalUnits ||
      (fundInfo?.currentValue / fundInfo?.currentNAV).toFixed(2),
    "Current Value": fundInfo?.currentValue,
    // {
    //   label: "Available Clear Units",
    //   value: "7489",
    // },
    // {
    //   label: "Min. Units",
    //   value: "0.01",
    // },
  });
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const [open, setopen] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [summaryData, setSummaryData] = useState<any>({});
  const switchSchema = yup.object({
    sourceScheme: yup.string().required("Source scheme is required"),
    destinationSchemeType: yup
      .string()
      .required("Destination scheme type is required"),
    newScheme: yup.string().required("New scheme name is required"),
    amount: yup.string().required("Amount is required"),
  });

  const [switchValues, setswitchValues] = useState({
    orderType: 6, // 1 for lumpsump, 2 for sip, 3 for swp, 4 for stp
    newOrder: 1,
    fundId: 0,
    amount: "",
    bankId: 0,
    folioNumber: "",
    dividend: "",
    startDate: "",
    frequency: 0,
    noOfInstallment: 0,
    firstOrderToday: "",
    units: 0,
    transType: "",
    STPType: "",
    buySellType: "",
    toSchemeId: 0,
    folioNo: "",
    sourceScheme: "",
    destinationSchemeType: "",
    newScheme: "",
    allUnits: 0,
  });

  // const switchValues = {
  //   sourceScheme: "",
  //   destinationSchemeType: "",
  //   newScheme: "",
  //   amount: "",
  // };

  useEffect(() => {
    if (selectedFund) {
      const params = { ...fundParams };
      params["Min. Amount"] = selectedFund.minAmount;
      setFundParams(params);
    } else {
      const params = { ...fundParams };
      params["Min. Amount"] = "";
      setFundParams(params);
    }
  }, [selectedFund]);

  useEffect(() => {
    if (fundInfo && formikRef.current) {
      console.log(fundInfo.schemeName, "fundInfo.schemeName");
      formikRef.current?.setFieldValue("sourceScheme", fundInfo?.schemeName);
      formikRef.current?.setFieldValue("amount", fundInfo?.currentValue);
    }
  }, [fundInfo]);

  useEffect(() => {
    if (switchFunds.length > 0) {
      const newList = switchFunds.map((fund: any) => {
        return {
          aum: fund?.aum,
          currentNAV: fund?.currentNAV,
          mfRank: fund?.mfRank,
          riskLevel: fund?.riskLevel,
          label: fund?.schemeName,
          schemeName: fund?.schemeName,
          value: fund?.schemeName,
          minAmount: fund?.minimumPurchaseAmount,
          id: fund?.id,
        };
      });
      setAvailableFunds(newList);
    }
  }, [switchFunds]);

  const renderRadio = (option: string) => (
    <label key={option} className="flex items-center">
      <input
        type="radio"
        checked={investmentPlan === option}
        onChange={() => setInvestmentPlan(option)}
        className={`mr-5`}
      />
      {option}
    </label>
  );

  const getSwtchFunds = (fundType: string, search: string = "") => {
    if (fundId) {
      dispatch(
        getSTPFunds({
          pageIndex: 1,
          pageSize: 100,
          search: search,
          fundType: fundType,
          fundId: fundId,
        })
      );
    }
  };

  const calculateUnits = (amount: string): number => {
    const numericAmount = parseFloat(amount);
    if (fundInfo?.currentNAV > 0) {
      return numericAmount / fundInfo?.currentNAV;
    }
    return 0;
  };

  const validateAmount = (value: string) => {
    const numValue = parseFloat(value);
    if (isNaN(numValue) || numValue <= 0) {
      return "Invalid input value";
    }

    if (investmentPlan === "Amount") {
      if (!value) {
        return "Amount is a required field";
      } else if (fundInfo?.currentValue <= 0) {
        return "You don't have sufficient funds";
      } else if (numValue > fundInfo?.currentValue) {
        return `Amount should be less than ${fundInfo?.currentValue}`;
      } else if (numValue < selectedFund?.minAmount) {
        return `Amount should be greater than ${selectedFund?.minAmount}`;
      }
    } else if (investmentPlan === "Units") {
      if (!value) {
        return "Units is a required field";
      } else if (fundInfo?.totalUnits <= 0) {
        return "You don't have sufficient funds";
      } else if (numValue > fundInfo?.totalUnits) {
        return `Units should be less than ${fundInfo?.totalUnits}`;
      }
    }
    return "";
  };

  const switchMF = async (values: any) => {
    try {
      if (!selectedFund) {
        setOpen(true);
        setErrorMessage("Please select a fund to switch!");
        return;
      }
      const { sourceScheme, destinationSchemeType, newScheme, amount } = values;
      dispatch(showLoading());

      // Ensure amount is parsed as a number
      const parsedAmount = parseFloat(amount);

      const allUnits =
        (investmentPlan === "Units" && parsedAmount === fundInfo?.totalUnits) ||
        (investmentPlan === "Amount" && parsedAmount === fundInfo?.currentValue)
          ? "Y"
          : "N";

      const folios = fundInfo?.folioNumber || [];
      const isAllRedeem = allUnits === "Y";
      const payloads = [];

      if (isAllRedeem) {
        // If allRedeem is "Y", loop through all folios and set doRedeemAuth for the last folio.
        payloads.push(
          ...folios.map((folioItem: any, index: number) => ({
            folioNo: folioItem.folio,
            amount: "",
            units: "",
            allUnits,
            fundId,
            bankId: parseInt(bank[0]?.id),
            doRedeemAuth: index === folios.length - 1 ? 1 : 0, // Last folio has doRedeemAuth as 1
            isDirectAllowed: userData?.preferences?.direct === 1 ? 1 : 0,
          }))
        );
      } else {
        // If allRedeem is "N", calculate based on entered amount
        let runningTotal = 0;
        let amountToRedeem = parseFloat(values.amount);

        for (let i = 0; i < folios.length; i++) {
          const folioItem = folios[i];
          if (investmentPlan === "Units") {
            const folioUnit = parseFloat(folioItem.units);

            if (amountToRedeem <= runningTotal + folioUnit) {
              // When the total amount surpasses the target, set doRedeemAuth to 1 for this folio and stop
              payloads.push({
                folioNo: folioItem.folio,
                amount: "",
                units:
                  investmentPlan === "Units"
                    ? (amountToRedeem - runningTotal).toFixed(2).toString()
                    : "",
                allUnits,
                fundId,
                toSchemeId: selectedFund?.id,
                bankId: parseInt(bank[0]?.id),
                buySellType: "FRESH",
                doRedeemAuth: 1, // Final folio to redeem has doRedeemAuth as 1
                isDirectAllowed: userData?.preferences?.direct === 1 ? 1 : 0,
              });
              break;
            } else {
              // Accumulate folio amounts until the target amount is reached
              payloads.push({
                folioNo: folioItem.folio,
                amount: "",
                units: investmentPlan === "Units" ? folioItem.units : "",
                allUnits,
                fundId,
                toSchemeId: selectedFund?.id,
                bankId: parseInt(bank[0]?.id),
                buySellType: "FRESH",
                doRedeemAuth: 0, // Intermediate folios have doRedeemAuth as 0
                isDirectAllowed: userData?.preferences?.direct === 1 ? 1 : 0,
              });
              runningTotal += folioUnit;
            }
          } else {
            const folioAmount = parseFloat(folioItem.amount);

            if (amountToRedeem <= runningTotal + folioAmount) {
              // When the total amount surpasses the target, set doRedeemAuth to 1 for this folio and stop
              payloads.push({
                folioNo: folioItem.folio,
                amount:
                  investmentPlan === "Amount"
                    ? (amountToRedeem - runningTotal).toString()
                    : "",
                units: "",
                allUnits,
                fundId,
                toSchemeId: selectedFund?.id,
                bankId: parseInt(bank[0]?.id),
                buySellType: "FRESH",
                doRedeemAuth: 1, // Final folio to redeem has doRedeemAuth as 1
                isDirectAllowed: userData?.preferences?.direct === 1 ? 1 : 0,
              });
              break;
            } else {
              // Accumulate folio amounts until the target amount is reached
              payloads.push({
                folioNo: folioItem.folio,
                amount: investmentPlan === "Amount" ? folioItem.amount : "",
                units: "",
                allUnits,
                fundId,
                toSchemeId: selectedFund?.id,
                bankId: parseInt(bank[0]?.id),
                buySellType: "FRESH",
                doRedeemAuth: 0, // Intermediate folios have doRedeemAuth as 0
                isDirectAllowed: userData?.preferences?.direct === 1 ? 1 : 0,
              });
              runningTotal += folioAmount;
            }
          }
        }
      }

      for (const payload of payloads) {
        const res = await dispatch(switchMutualFund(payload));
        setLoading(true);
        setLoadMessage("Processing your request, please wait 30s to 40s...");
        handleCloseDialog();
        setOrderID(res?.payload?.data?.data?.orderId);
        if (res?.payload?.success === 200 && res?.payload?.data?.authLink) {
          setLoading(false);
          const authLink = res?.payload?.data?.authLink;
          setAuthLink(authLink);
          setAuthModalOpen(true); // Open modal with auth link
        } else {
          setLoading(false);
          setOpen(true);
          setErrorMessage("Error in executing your order.");
          break;
        }
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Some error in switching your fund!");
    } finally {
      dispatch(hideLoading());
    }
  };

  const handleOpenDialog = () => {
    const amount = parseFloat(formikRef.current?.values.amount || "0");
    const sourceScheme = formikRef.current?.values.sourceScheme || "N/A";
    const newScheme = formikRef.current?.values.newScheme || "N/A";
    const currentNAV = parseFloat(fundInfo?.currentNAV || "0");
    const units = currentNAV > 0 ? (amount / currentNAV).toFixed(2) : "N/A";
    console.log("Current NAV:", fundInfo?.currentNAV, units);

    const summary = {
      Amount: amount.toFixed(2),
      Scheme: sourceScheme,
      DestinationScheme:
        availableFunds.find((fund: any) => fund.id === newScheme)?.label ||
        "N/A",
      Units: units,
    };

    setSummaryData(summary);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = async () => {
    handleCloseDialog();
    const values = formikRef.current?.values;
    if (validateAmount(values.amount)) return;
    await switchMF(values);
  };

  const showingInterest = async () => {
    try {
      const response = await dispatch(
        showInterest({ id: fundId, holdingType: 2 })
      );

      if (response?.payload?.statusCode === 200) {
        setOpen(true);
        setMessage("Your Interest is submitted successfully");
      } else {
        setOpen(true);
        setErrorMessage("Something wents wrong.Please try again later!");
        console.warn("Unexpected response structure:", response);
      }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    // Check if the popup window is closed every 1000 milliseconds
    const checkPopupClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        setLoading(false);
        clearInterval(checkPopupClosed); // Stop checking once the popup is closed
        setOpen(true);
        setMessage("Fund switched successfully!");
      }
    }, 1000);

    // Clear the interval if the component unmounts
    return () => clearInterval(checkPopupClosed);
  }, [popupWindow]);

  const openAuthLink = () => {
    setAuthModalOpen(false);
    setLoadMessage(
      "Please authenticate your switch fund request and close the bse tab..."
    );
    setLoading(true);
    if (authLink) {
      const newPopupWindow = BrowserPopUpModal(
        authLink,
        "BSE Autherization",
        1250,
        550
      );
      setPopupWindow(newPopupWindow);
    }
  };

  const handleAuthModalClose = () => {
    setOpen(true);
    setMessage("Switch fund request has not been submitted!");
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <>
      <div className="bg-lightBg pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="bg-white">
              <div className="md:px-8 py-10">
                <MFDetailCard fundInfo={fundInfo} />
                <Formik
                  innerRef={formikRef}
                  initialValues={switchValues}
                  validationSchema={switchSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    if (validateAmount(values.amount)) return;
                    handleOpenDialog();
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <>
                      <div className="my-5">
                        <form onSubmit={handleSubmit}>
                          <div className="flex flex-row w-full gap-5 mt-10">
                            <div className="w-full sm:w-3/4 sm:mb-0 ">
                              <FormInput
                                label="Source Scheme"
                                name="sourceScheme"
                                type={"text"}
                                value={values.sourceScheme}
                                disabled={true}
                                onChange={handleChange("sourceScheme")}
                              />
                            </div>
                            <div className="w-full sm:w-3/4 sm:mb-0 ">
                              <FormSelect
                                label="Destination Scheme Type"
                                name="destinationSchemeType"
                                options={schemeTypes}
                                onChange={(e: any) => {
                                  setFieldValue(
                                    "destinationSchemeType",
                                    e.target.value
                                  );
                                  getSwtchFunds(e.target.value);
                                }}
                                value={values.destinationSchemeType}
                                error={errors.destinationSchemeType}
                                touched={touched.destinationSchemeType}
                              />
                            </div>
                          </div>
                          <div className="flex flex-wrap gap-5 pt-5 justify-center md:justify-start">
                            {Object.keys(fundParams).map(
                              (label: any) =>
                                fundParams[label] !== "" &&
                                fundParams[label] != null && (
                                  <AmountCard
                                    label={label}
                                    value={fundParams[label]}
                                  />
                                )
                            )}
                          </div>
                          <div className="flex flex-row w-full gap-5 mt-10">
                            <div className="w-full sm:w-3/4 sm:mb-0 ">
                              {/* <FormSelect
                                label="Scheme Name"
                                name="schemeName"
                                options={switchFunds || []}
                                type={"text"}
                                value={values.schemeName}
                                onChange={handleChange("schemeName")}
                              /> */}
                              <Autocomplete
                                sx={{
                                  "& input": {
                                    width: "100%",
                                    color: colors.darkGrey, //CH11/5
                                    border: `1px solid ${colors.darkBg}`,
                                    padding: "1rem",
                                    borderRadius: "0.5rem",
                                    marginTop: "0.75rem",
                                  },
                                }}
                                isOptionEqualToValue={(option: any, value) =>
                                  option.value === value.value
                                }
                                disablePortal
                                id="combo-box-demo"
                                open={open} // Control dropdown visibility
                                onOpen={() => setopen(true)} // Handle when the dropdown should open
                                onClose={() => setopen(false)} // Handle when the dropdown should close
                                options={availableFunds || []}
                                onChange={(_, newValue: any) => {
                                  console.log(newValue, "selectedFund");
                                  setSelectedFund(newValue);
                                  setFieldValue("newScheme", newValue?.id);
                                }}
                                renderInput={(params) => (
                                  <div
                                    ref={params.InputProps.ref}
                                    className={`w-full mx-auto mb-4 sm:mb-0`}
                                  >
                                    <div className="flex flex-col text-lg md:mb-10">
                                      <label style={{ color: colors.darkGrey }}>
                                        Scheme Name
                                      </label>
                                      <div className="relative">
                                        <input
                                          type={"text"}
                                          placeholder="Select Scheme Name"
                                          {...params.inputProps}
                                        />
                                        <span
                                          className="absolute right-5 top-7"
                                          onClick={() =>
                                            setopen((prev) => !prev)
                                          }
                                        >
                                          <KeyboardArrowDown />
                                        </span>
                                      </div>
                                      {!!errors?.newScheme &&
                                      touched?.newScheme ? (
                                        <p className="text-secondary h-1.5">
                                          {errors?.newScheme}
                                        </p>
                                      ) : (
                                        <p className="text-secondary h-1.5"></p>
                                      )}
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="w-full sm:w-3/4 sm:mb-0 "></div>
                            {/* <div className="w-full sm:w-3/4 sm:mb-0 ">
                              <FormInput
                                label="Scheme Option"
                                name="SchemeOption"
                                type={""}
                                id={""}
                                onChange={undefined}
                              />
                            </div> */}
                          </div>
                          {selectedFund && (
                            <div className="flex flex-col w-full gap-5">
                              <h6 className="mr-5 text-textGrey text-lg">
                                Destination Scheme
                              </h6>
                              <MFDetailCard fundInfo={selectedFund} />
                            </div>
                          )}
                          {/* <div>
                            <p>SWP End Date :</p>
                          </div> */}
                          {/* <div className="flex flex-col md:flex-row text-start justify-start text-sm md:text-lg gap-10 mb-10 mt-5">
                            {["Amount", "Units"].map(renderRadio)}
                          </div> */}
                          <div className="flex flex-row w-full gap-5">
                            <div className="w-full sm:w-3/4 sm:mb-0">
                              <FormInput
                                label={investmentPlan}
                                name="amount"
                                type="text"
                                value={values.amount}
                                onChange={(e: any) => {
                                  setFieldValue("amount", e.target.value);
                                }}
                                error={validateAmount(values.amount)}
                                touched={touched.amount}
                                style={{
                                  backgroundColor: colors.bgGrey,
                                  border: `1px solid ${colors.lightGrey}`,
                                }}
                                disabled
                              />
                            </div>
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0"></div>
                          </div>
                          {/* <div className="w-full my-10">
                            <MFFooterCard
                              onBankChange={handleChange("bankId")}
                              hideBank={true}
                              bankValue={undefined}
                              bankError={undefined}
                              bankTouched={undefined}
                            />
                          </div> */}

                          <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                            <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                              <div className="flex flex-col max-md:ml-0 max-md:w-full">
                                <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                                  <div className="flex gap-5 justify-between   lg:mt-2 text-sm max-md:mt-2 lg:mb-5">
                                    <Button
                                      type="submit"
                                      className={`grow  justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary  `}
                                      tabIndex={0}
                                      // onClick={() => updateCompanyRef.current.click()} // Add onClick event handler
                                    >
                                      Invest
                                      {/* Proceed to switch */}
                                    </Button>
                                    <Button
                                      type="button"
                                      className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary`}
                                      onClick={showingInterest}
                                    >
                                      Show Interest
                                      {/* Proceed to payment */}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </form>
                      </div>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
      <APIloader loadingModal={loading} message={loadmessage} />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
      >
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
            <Typography variant="h4" className="font-bold mb-4">
              Summary
            </Typography>
            <DialogContent className="flex flex-col">
              {summaryData ? (
                <>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Source Scheme:
                    </span>
                    <span className="text-xs w-52">{summaryData.Scheme}</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Destination Scheme:
                    </span>
                    <span className="text-xs w-52">
                      {summaryData.DestinationScheme}
                    </span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">Amount:</span>
                    <span>Rs. {summaryData.Amount}</span>
                  </div>
                  <div className="flex justify-between mb-4">
                    <span className="font-semibold text-gray-700">
                      Approx. Units:
                    </span>
                    <span>{summaryData.Units}</span>
                  </div>
                </>
              ) : (
                <div className="flex justify-center items-center">
                  <span>Loading...</span>
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <Btn
                onClick={handleConfirmDialog}
                color="primary"
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Confirm
              </Btn>
              <Btn
                onClick={handleCloseDialog}
                sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}
              >
                Cancel
              </Btn>
            </DialogActions>
            {/* <Typography
                  variant="body2"
                  className="mb-4"
                  sx={{ marginTop: 4 }}
                >
                Once confirmed, you will receive an email with the payment link. Please click on the link to proceed with the switch.
              </Typography> */}
          </div>
        </div>
      </Dialog>
      <SuccessfullModal
        open={Open}
        message={message !== "" ? message : errormessage}
        handleClose={handleClose}
      />
      <Dialog open={authModalOpen} onClose={handleAuthModalClose}>
        <DialogContent>
          <Typography>
            Click below to complete your authorization on the BSE STAR MF
            platform and also close the tab after autherized yourself.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Btn
            onClick={openAuthLink}
            sx={{
              backgroundColor: colors.primary,
              borderRadius: "40px",
              color: colors.lightBg,
              padding: "10px",
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.lightBg,
              },
            }}
          >
            Open Authorization Link
          </Btn>
          <Btn
            onClick={handleAuthModalClose}
            sx={{
              backgroundColor: colors.primary,
              borderRadius: "40px",
              color: colors.lightBg,
              padding: "10px",
              "&:hover": {
                backgroundColor: colors.primary,
                color: colors.lightBg,
              },
            }}
          >
            Close
          </Btn>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SwitchDetails;
