import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import companyService from "./companyService";


// Define async thunk to add organization
export const addOrganizationFatca = createAsyncThunk(
  "org/addFatca",
  async (data: any, thunkAPI: any) => {
    try {
      return await companyService.addOrganizationFatca(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  data: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Create companySlice
export const companySlice = createSlice({
  name: "company",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(addOrganizationFatca.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrganizationFatca.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.isSuccess = true;
        state.data = action.payload.data || {};
      })
      .addCase(addOrganizationFatca.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

// Export actions and reducer
export const { resetState } = companySlice.actions;
export default companySlice.reducer;
