import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Fade,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  TextField,
  Typography,
  Breadcrumbs,
  Link,
  Divider,
} from "@mui/material";
import {
  Add,
  Close,
  FilterAlt,
  OutboxOutlined,
  Search,
} from "@mui/icons-material";
import { colors } from "../../constants/colors";
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import MFTable from "../../components/MFTable/MFTable";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import dropdown from "../../assets/icons/dropdown.svg";
import AssetTable from "../../components/AssetTable/AssetHoldingTable";
import AifTable from "../../components/AssetTable/AIF";
import UploadModal from "../../components/modals/UploadDataModal";
import TableComponent from "../../components/Tables/TableRow";
import styles from "./AssetHolding.module.css";
import api from "../../config/apiConfig";
import DynamicBreadcrumbs from "../../components/BreadCrumbs/BreadCrumbs";
import MFAssetTable from "../../components/AssetHoldingsTables/MFAssetTable";
import AIFAssetTable from "../../components/AssetHoldingsTables/AIFAssetTable";
import BONDAssetTable from "../../components/AssetHoldingsTables/BONDAssetTable";
import EquityAssetTable from "../../components/AssetHoldingsTables/EquityAssetTable";
import FDAssetTable from "../../components/AssetHoldingsTables/FDAssetTable";
import PMSAssetTable from "../../components/AssetHoldingsTables/PMSAssetTable";
import {
  FDAHInfo,
  getUnrealisedMFInfo,
  HoldingsData,
} from "../../redux/asset_Holdings/assetSlice";
import { useDispatch } from "react-redux";
import { useUserData } from "../../hooks/useUserData";

const filterOps: any = [
  {
    name: "Asset",
    options: [
      {
        name: "Mutual Fund",
        value: 1,
        isChecked: false,
      },
      {
        name: "Corporate FD",
        value: 3,
        isChecked: false,
      },
      {
        name: "PMS/AIF",
        value: 5,
        isChecked: false,
      },
      {
        name: "Equity",
        value: 7,
        isChecked: false,
      },
      {
        name: "Bond",
        value: 8,
        isChecked: false,
      },
      {
        name: "SGB/FRB",
        value: 11,
        isChecked: false,
      },
      {
        name: "Others",
        value: 11,
        isChecked: false,
      },
    ],
  },
  {
    name: "Company",
    options: [
      {
        name: "Online",
        value: "online",
        isChecked: false,
      },
      {
        name: "Offline",
        value: "offline",
        isChecked: false,
      },
    ],
  },
];

const headDataMF = [
  { key: "schemeName", value: "Name" },
  { key: "schemeType", value: "Fund Category" },
  { key: "transactionDate", value: "Transaction Date" },
  { key: "units", value: "Units" },
  { key: "costValue", value: "Invested Amount" },
  { key: "currentValue", value: "Current Amount" },
  { key: "unrealisedPNL", value: "P&L (Amount & %)" },
  { key: "currentNAV", value: "Current NAV" },
  { key: "folioNumber", value: "Folio No" },
  // { key: "action", value: "Action" },
];

const FDData = [
  { key: "name", value: "Name" },
  { key: "category", value: "Fund Category" },
  { key: "createdAt", value: "Transaction Date" },
  { key: "units", value: "Units" },
  { key: "amount", value: "Invested Amount" },
  { key: "returnAmount", value: "Current Amount" },
  { key: "interesetRate", value: "P&L (Amount & %)" },
  { key: "currentNAV", value: "Current NAV" },
  { key: "isin", value: "ISIN" },
  // { key: "action", value: "Action" },
];
const headData = [
  { key: "holdingName", value: "Name" },
  { key: "category", value: "Fund Category" },
  { key: "transactionDate", value: "Transaction Date" },
  { key: "units", value: "Units" },
  { key: "investedAmount", value: "Invested Amount" },
  { key: "currentAmount", value: "Current Amount" },
  { key: "pl", value: "P&L (Amount & %)" },
  { key: "currentNAV", value: "Current NAV" },
  { key: "isin", value: "ISIN" },
  // { key: "action", value: "Action" },
];
const BondData = [
  { key: "holdingName", value: "Name" },
  { key: "category", value: "Issuer" },
  { key: "transactionDate", value: "Transaction Date" },
  { key: "units", value: "Units" },
  { key: "investedAmount", value: "Invested Amount" },
  { key: "currentAmount", value: "Current Amount" },
  { key: "pl", value: "P&L (Amount & %)" },
  { key: "currentNAV", value: "Current NAV" },
  { key: "isin", value: "ISIN" },
  // { key: "action", value: "Action" },
];
const StockData = [
  { key: "holdingName", value: "Name" },
  { key: "category", value: "Industry" },
  { key: "transactionDate", value: "Transaction Date" },
  { key: "units", value: "Units" },
  { key: "investedAmount", value: "Invested Amount" },
  { key: "currentAmount", value: "Current Amount" },
  { key: "pl", value: "P&L (Amount & %)" },
  { key: "currentNAV", value: "Current NAV" },
  { key: "isin", value: "ISIN" },
  // { key: "action", value: "Action" },
];

const AssetHolding = () => {
  const dispatch = useDispatch<any>();
  const user = useUserData();
  const [MFBodyData, setMFBodyData] = useState([]);
  const [lastDate, setLastDate] = useState([]);
  const [AIFBodyData, setAIFBodyData] = useState([]);
  const [totalFound, setTotalFound] = useState("07");
  const [totalFound1, setTotalFound1] = useState("04");
  const [filterOptions, setFilterOptions] = useState(filterOps);
  const [checked, setChecked] = React.useState([true, false]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [showScrollbar, setShowScrollbar] = useState(false);

  const [equityButtonVisible, setEquityButtonVisible] = useState(false);
  const [debtButtonVisible, setDebtButtonVisible] = useState(false);
  const [hybridButtonVisible, setHybridButtonVisible] = useState(false);
  const [solutionButtonVisible, setSolutionButtonVisible] = useState(false);
  const [othersButtonVisible, setOthersButtonVisible] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState("");
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);

  const [FD, setFD] = useState([]);
  const [AIF, setAIF] = useState([]);
  const [PMS, setPMS] = useState([]);
  const [BOND, setBOND] = useState([]);
  const [STOCK, setSTOCK] = useState([]);

  

  const handleDropdownClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl2(null);
  };

  const handleMenuItemClick = async (id: number, name: string) => {
    setSelectedMember(name);
    setAnchorEl2(null);
    fetchDataMF(id);
    fetchDataFD(id);
    fetchDataAIF(id);
    fetchDataPMS(id);
    fetchDataBOND(id);
    fetchDataSTOCK(id);
  };

  const handleMouseEnter = () => {
    setShowScrollbar(true);
  };

  const handleMouseLeave = () => {
    setShowScrollbar(false);
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    fetchDataMF("");
    fetchDataFD("");
    fetchDataAIF("");
    fetchDataPMS("");
    fetchDataBOND("");
    fetchDataSTOCK("");
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange1 = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterIndex: number
  ) => {
    const { checked } = event.target;

    // Update the isChecked property of each option
    const updatedOptions = filterOptions.map(
      (filter: { options: any[] }, index: number) => {
        if (index === filterIndex) {
          // If it's the target filter, update its options
          return {
            ...filter,
            options: filter.options.map((option: any) => ({
              ...option,
              isChecked: checked,
            })),
          };
        }
        return filter;
      }
    );

    // Check if at least one option is checked in the current filter
    const isAnyOptionChecked = updatedOptions[filterIndex].options.some(
      (option: any) => option.isChecked
    );

    // Update the visibility state of the buttons based on the filter index
    switch (filterIndex) {
      case 0: // Equity
        setEquityButtonVisible(isAnyOptionChecked);
        break;
      case 1: // Debt
        setDebtButtonVisible(isAnyOptionChecked);
        break;
      case 2: // Debt
        setHybridButtonVisible(isAnyOptionChecked);
        break;
      case 3: // Debt
        setSolutionButtonVisible(isAnyOptionChecked);
        break;
      case 4: // Debt
        setOthersButtonVisible(isAnyOptionChecked);
        break;
      // Add cases for other filters as needed
      default:
        break;
    }

    // Update the state with the modified options
    setFilterOptions(updatedOptions);
  };

  const handleChange2 = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterIndex: number,
    optionIndex: number
  ) => {
    const { checked } = event.target;
  };
  const [hoveredButton, setHoveredButton] = useState<number | null>(null);

  const fetchDataMF = async (userId: any) => {
    try {
      const response = await dispatch(
        getUnrealisedMFInfo({
          userId: userId || "",
          startDate: 1,
          endDate: 1,
          fundType: "",
          transactionType: 1,
          fetchFor: "consolidated", // aumcapital or consolidated
        })
      );

      if (response?.payload) {
        setMFBodyData(response?.payload.data);
        setLastDate(response?.payload?.lastImportDate);
        console.log(response?.payload.data, "MF");
      } else {
        console.log(response);
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const fetchDataFD = async (userId: any) => {
    try {
      const response = await dispatch(
        FDAHInfo({
          userId: userId || "",
          startDate: 0,
          endDate: 0,
          forRm: 0,
        })
      );

      if (response?.payload) {
        setFD(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataAIF = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "AIF" })
      );

      if (response?.payload) {
        setAIF(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataPMS = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "PMS" })
      );

      if (response?.payload) {
        setPMS(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataBOND = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "BOND" })
      );

      if (response?.payload) {
        setBOND(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const fetchDataSTOCK = async (userId: any) => {
    try {
      const response = await dispatch(
        HoldingsData({ userId: userId || "", holdingType: "STOCK" })
      );

      if (response?.payload) {
        setSTOCK(response?.payload);
      } else {
        console.log("Try again later");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  useEffect(() => {
    fetchDataMF("");
    fetchDataFD("");
    fetchDataAIF("");
    fetchDataPMS("");
    fetchDataBOND("");
    fetchDataSTOCK("");
  }, []);
  const fdLength = FD?.length || 0;
  const bondLength = BOND?.length || 0;
  const MFLength = MFBodyData?.length || 0;
  const aifLength = AIF?.length || 0;
  const pmsLength = PMS?.length || 0;
  const stockLength = STOCK?.length || 0;
  const breadcrumbItems = [
    { label: "Home", href: "/" },
    { label: "Investment", href: "/" }, // Breadcrumb item with link
    { label: "Asset Holdings" }, // Current page, no link
  ];

  return (
    <div>
      <div className="bg-lightBg">
        <div className="p-8 md:p-[40px] max-w-[1440px] w-full mx-auto flex flex-col gap-y-2">
          <DynamicBreadcrumbs items={breadcrumbItems} />
          <Divider sx={{ my: 2 }} />{" "}
          {/* Adds a division between the breadcrumbs and the content below */}
          <div className="flex flex-row gap-x-2 items-center w-full pb-4 justify-between">
            <div>
              <h1 className="text-3xl font-medium text-gray-900 w-[30vw]">
                Asset Holdings{selectedMember===""?"":", "+selectedMember}
              </h1>
            </div>
            {/* <div className="flex-grow"></div>{" "} */}
            {/* This ensures the button is pushed to the right */}
            <div className="flex justify-end w-full">
              {(selectedMember === "" ||
                selectedMember === user?.firstName) && (
                <button
                  className="bg-primary text-white py-2 px-6 rounded hover:bg-white hover:text-primary border border-blue-900 "
                  onClick={handleOpenModal}
                >
                  Upload Data
                </button>
              )}

              {user?.familyMembers?.length > 0 && (
                <Button
                  variant="outlined"
                  endIcon={<ArrowDropDownIcon />}
                  sx={{
                    fontSize: "16px",
                    marginLeft: "10px",
                    fontWeight: 500,
                    textTransform: "none",
                    display: "flex",
                    padding: "6px 10px",
                    borderRadius: "4px",
                    alignItems: "center",
                    justifyContent: "center",
                    border: "1px solid blue",
                    borderColor: "lightGrey",
                    gap: "2px",
                    backgroundColor: "rgb(37, 82, 136)",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "white",
                      color: "rgb(37, 82, 136)",
                    },
                  }}
                  onClick={handleDropdownClick}
                >
                  {selectedMember || "Family Members"}
                </Button>
              )}
              <Menu
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                disableScrollLock={true}
                onClose={handleMenuClose}
                sx={{
                  "& .MuiPaper-root": {
                    minWidth: anchorEl2?.offsetWidth || 0, // Match the width of the button
                  },
                }}
              >
                <MenuItem
                  onClick={() => handleMenuItemClick(user?.id, user?.firstName)}
                  sx={{
                    width: "100%", // Ensure menu items take full width of the menu
                  }}
                >
                  {user?.firstName}
                </MenuItem>
                {user?.familyMembers?.map((member: any) => (
                  <MenuItem
                    key={member.id}
                    onClick={() =>
                      handleMenuItemClick(member.id, member.firstName)
                    }
                    sx={{
                      width: "100%", // Ensure menu items take full width of the menu
                    }}
                  >
                    {member.firstName}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </div>
        </div>
        <UploadModal open={modalOpen} handleClose={handleCloseModal} />
      </div>

      <div className="p-8 md:p-[60px] max-w-[1440px] w-full mx-auto">
        <div className="">
          <div className="flex flex-col gap-y-3">
            <div className="flex gap-x-8 items-center">
              <div className="flex flex-row w-full justify-start pb-4">
                <div>
                  <h1 className="text-2xl font-semibold text-primary">
                    Mutual Funds
                  </h1>
                </div>
                <div className="pl-2">
                  <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                    {MFLength}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          // className={`${styles.overflowYAuto} ${styles.maxH96} ${
          //   showScrollbar ? styles.showScrollbar : ""
          // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
          //   styles.scrollbarTrackPrimary
          // }`}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          >
            {MFBodyData && MFBodyData?.length > 0 && (
              <MFAssetTable data={MFBodyData} lastDate={lastDate}/>
            )}
          </div>
        </div>

        <div className="mt-28">
          <div className="flex flex-col gap-y-3">
            <div className="flex gap-x-8 items-center">
              <div className="flex flex-row w-full justify-start pb-4">
                <div>
                  <h1 className="text-2xl font-semibold text-primary">AIF</h1>
                </div>
                <div className="pl-2">
                  <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                    {aifLength}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          // className={`${styles.overflowYAuto} ${styles.maxH96} ${
          //   showScrollbar ? styles.showScrollbar : ""
          // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
          //   styles.scrollbarTrackPrimary
          // }`}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          >
            {AIF && AIF?.length > 0 && <AIFAssetTable data={AIF} />}
          </div>
        </div>

        <div className="mt-28">
          <div className="flex flex-col gap-y-3">
            <div className="flex gap-x-8 items-center">
              <div className="flex flex-row w-full justify-start pb-4">
                <div>
                  <h1 className="text-2xl font-semibold text-primary">
                    Corporate FD
                  </h1>
                </div>
                <div className="pl-2">
                  <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                    {fdLength}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          // className={`${styles.overflowYAuto} ${styles.maxH96} ${
          //   showScrollbar ? styles.showScrollbar : ""
          // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
          //   styles.scrollbarTrackPrimary
          // }`}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          >
            {FD && FD?.length > 0 && <FDAssetTable data={FD} />}
          </div>
        </div>

        <div className="mt-28">
          <div className="flex flex-col gap-y-3">
            <div className="flex gap-x-8 items-center">
              <div className="flex flex-row w-full justify-start pb-4">
                <div>
                  <h1 className="text-2xl font-semibold text-primary">PMS</h1>
                </div>
                <div className="pl-2">
                  <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                    {pmsLength}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          // className={`${styles.overflowYAuto} ${styles.maxH96} ${
          //   showScrollbar ? styles.showScrollbar : ""
          // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
          //   styles.scrollbarTrackPrimary
          // }`}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          >
            {PMS && PMS?.length > 0 && <PMSAssetTable data={PMS} />}
          </div>
        </div>

        <div className="mt-28">
          <div className="flex flex-col gap-y-3">
            <div className="flex gap-x-8 items-center">
              <div className="flex flex-row w-full justify-start pb-4">
                <div>
                  <h1 className="text-2xl font-semibold text-primary">
                    Equity
                  </h1>
                </div>
                <div className="pl-2">
                  <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                    {stockLength}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          // className={`${styles.overflowYAuto} ${styles.maxH96} ${
          //   showScrollbar ? styles.showScrollbar : ""
          // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
          //   styles.scrollbarTrackPrimary
          // }`}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          >
            {STOCK && STOCK?.length > 0 && <EquityAssetTable data={STOCK} />}
          </div>
        </div>

        <div className="mt-28">
          <div className="flex flex-col gap-y-3">
            <div className="flex gap-x-8 items-center">
              <div className="flex flex-row w-full justify-start pb-4">
                <div>
                  <h1 className="text-2xl font-semibold text-primary">Bonds</h1>
                </div>
                <div className="pl-2">
                  <div className="justify-center px-7 py-1.5 text-md border border-solid border-primary rounded-[35px] max-md:max-w-full bg-white text-primary">
                    {bondLength}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
          // className={`${styles.overflowYAuto} ${styles.maxH96} ${
          //   showScrollbar ? styles.showScrollbar : ""
          // } ${styles.scrollbarThin} ${styles.scrollbarThumbPrimary} ${
          //   styles.scrollbarTrackPrimary
          // }`}
          // onMouseEnter={handleMouseEnter}
          // onMouseLeave={handleMouseLeave}
          >
            {BOND && BOND?.length > 0 && <BONDAssetTable data={BOND} />}
          </div>
        </div>

        <Menu
          id="fade-menu"
          MenuListProps={{
            "aria-labelledby": "fade-button",
          }}
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleClose}
          TransitionComponent={Fade}
        >
          <Box>
            <div className="flex items-center justify-between bg-black px-5 py-3">
              <Typography variant="subtitle2" color="white">
                Filter
              </Typography>
              <IconButton onClick={handleClose} sx={{ color: colors.white }}>
                <Close fontSize="small" />
              </IconButton>
            </div>

            <Box sx={{ padding: "1rem 2rem" }}>
              <h6 className="text-md font-bold mb-2">Fund Type</h6>
              {filterOptions.map((filter: any, index: number) => (
                <Accordion key={index}>
                  <AccordionSummary
                    expandIcon={<Add />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                  >
                    <FormControlLabel
                      label={filter.name}
                      control={
                        <Checkbox
                          checked={filter.options.every(
                            (option: any) => option.isChecked
                          )}
                          onChange={(event) => handleChange1(event, index)}
                        />
                      }
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      sx={{ display: "flex", flexDirection: "column", ml: 3 }}
                    >
                      {filter.options.map((option: any, j: number) => (
                        <FormControlLabel
                          key={j}
                          label={option.name}
                          control={
                            <Checkbox
                              checked={option.isChecked}
                              onChange={(event) =>
                                handleChange2(event, index, j)
                              }
                            />
                          }
                        />
                      ))}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              ))}

              {/* <h6 className="text-md font-bold mb-2 mt-10">Time Period</h6> */}

              {/* <AccordionDetails>
                <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
                  {timePeriodOptions.map((option, index) => (
                    <FormControlLabel
                      key={index}
                      label={option.name}
                      control={
                        <Checkbox
                          checked={option.isChecked}
                          onChange={(event) =>
                            handleChange2(event, index, index)
                          }
                        />
                      }
                    />
                  ))}
                </Box>
              </AccordionDetails> */}

              <Button
                sx={{
                  backgroundColor: colors.bgGrey,
                  borderRadius: 2,
                  textTransform: "none",
                  padding: "0.5rem 2rem",
                  color: "#000",
                  marginTop: "1rem",
                }}
              >
                Apply
              </Button>
            </Box>
          </Box>
        </Menu>
      </div>
    </div>
  );
};

export default AssetHolding;
