export enum PreferenceType {
  aif = "AIF or PMS",
  bonds = "Bonds",
  corporateFD = "Corporate FD",
  mutualFunds = "Mutual Funds",
  pms = "AIF or PMS",
  equity = "Equity",
  direct = "Direct",
  regular = "Regular",
}
