import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import MFService from "./MFService";
import fundInfoModel from '../../models/mutualFund.model'
import fundInfoById_Model from "../../models/mutualFundbyId.model";
import MFListingRequest from "../../models/MFListingRequest.model";

export const getAllMF = createAsyncThunk(
  "funds/getAll",
  async (data: MFListingRequest, thunkAPI: any) => {
    try {
      if (!data) {
        throw new Error("No refresh token provided");
      }
      // console.log("the data is ", data);

      const response = await MFService.getMutualFundInfo(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getSTPFunds = createAsyncThunk(
  "funds/getSTPFunds",
  async (data: any, thunkAPI: any) => {
    try {
      if (!data) {
        throw new Error("No refresh token provided");
      }

      const response = await MFService.getSTPFunds(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getFundInfo = createAsyncThunk(
  "funds/getInfo",
  async (data: fundInfoModel, thunkAPI: any) => {
    try {
      if (!data) {
        // console.log('no data',data);        
        throw new Error("No refresh token provided");
      }
      const response = await MFService.getindidualMutualFundInfo(data);

      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const getMFundById = createAsyncThunk(
  "/funds/getById",
  async (data: { fundId: any }, thunkAPI: any) => {
    try {
      if (!data) {
        throw new Error("No refresh token provided");
      }
      const response = await MFService.get_MF_DeatilsBy_id(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const InvestMF = createAsyncThunk(
  "funds/invest",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await MFService.investMFService(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const MFSummary = createAsyncThunk(
  "funds/getSummary",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await MFService.MFSummary(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const InvestRequest = createAsyncThunk(
  "funds/request",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      // console.log('Getting data for InvestMF ', data);

      const response = await MFService.investMFRequest(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const switchMutualFund = createAsyncThunk(
  "funds/switch",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }

      const response = await MFService.switchMutualFund(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const redeemMutualFund = createAsyncThunk(
  "funds/redeem",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }

      const response = await MFService.redeemMutualFund(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);
export const CancelSIP = createAsyncThunk(
  "funds/cancelSIP",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }

      const response = await MFService.CancelSIP(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);


export const createPayment = createAsyncThunk(
  "funds/createPayment",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }

      const response = await MFService.createPayment(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const checkPaymentStatus = createAsyncThunk(
  "funds/paymentStatus",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }

      const response = await MFService.checkPaymentStatus(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);


export const mfSendOTP = createAsyncThunk(
  "funds/sendOTP",
  async (_, thunkAPI) => {
    try {
      const response = await MFService.sendOTP();
      // console.log("return OTP", response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const mfVerifyOTP = createAsyncThunk(
  "funds/verifyOTP",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }

      const response = await MFService.verifyOTP(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

export const importFunds = createAsyncThunk(
  "funds/import",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }

      const response = await MFService.importFund(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);



const initialState = {
  funds: [],
  fundinfo: [{ 'name': 'fundInfo' }],
  MFundbyid: [],
  paymentData: {},
  InvestMF: [],
  sendOTP: [],
  verifyOTP: [],
  ImportFUnd: [],
  paymentStatus: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const MFSlice = createSlice({
  name: "mutualFund",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMF.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMF.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.funds = action.payload;
      })
      .addCase(getAllMF.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getSTPFunds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSTPFunds.fulfilled, (state, action) => {
        // console.log(action.payload, 'action payload')
        state.isLoading = false;
        state.isSuccess = true;
        state.funds = action.payload.fdList;
      })
      .addCase(getSTPFunds.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getFundInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFundInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.fundinfo = action.payload;
      })
      .addCase(getFundInfo.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(getMFundById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMFundById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.MFundbyid = action.payload;
      })
      .addCase(getMFundById.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(InvestMF.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(InvestMF.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(InvestMF.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(MFSummary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(MFSummary.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(MFSummary.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(InvestRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(InvestRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(InvestRequest.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      }).addCase(switchMutualFund.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(switchMutualFund.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(switchMutualFund.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      }).addCase(redeemMutualFund.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(redeemMutualFund.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.InvestMF = action.payload.data;
      })
      .addCase(redeemMutualFund.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      }).addCase(createPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.paymentData = action.payload.data;
      })
      .addCase(createPayment.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(checkPaymentStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(checkPaymentStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.paymentStatus = action.payload.data;
      })
      .addCase(checkPaymentStatus.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(mfSendOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(mfSendOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.sendOTP = action.payload;
      })
      .addCase(mfSendOTP.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(mfVerifyOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(mfVerifyOTP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.verifyOTP = action.payload.data;
      })
      .addCase(mfVerifyOTP.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(importFunds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(importFunds.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ImportFUnd = action.payload.data;
      })
      .addCase(importFunds.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
      .addCase(CancelSIP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(CancelSIP.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.ImportFUnd = action.payload.data;
      })
      .addCase(CancelSIP.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      });
  },
});

export const { resetState } = MFSlice.actions;
export const { reducer: MFSliceReducer } = MFSlice;
