import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrderService from "./OrderService";

export const getAllOrderHistory = createAsyncThunk(
  "user/getOrderHistory",
  async (data: any, thunkAPI) => {
    try {
      if (!data) {
        throw new Error("Invalid parameter provided");
      }
      const response = await OrderService.getAllOrderHistory(data);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data);
    }
  }
);

const initialState = {
  OrderData: [],
  paymentStatus: "",
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

export const OrderSlice = createSlice({
  name: "Order History",
  initialState: initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllOrderHistory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOrderHistory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.OrderData = action.payload.data;
      })
      .addCase(getAllOrderHistory.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.isSuccess = false;
        state.message = action.error.message || "";
      })
  },
});

export const { resetState } = OrderSlice.actions;
export const { reducer: OrderSliceReducer } = OrderSlice;
