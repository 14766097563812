import React, { FC } from "react";
import { colors } from "../../constants/colors";
import PhoneInput from "react-phone-number-input";

// interface FormInputProps {
//     label: string;
//     onChange?: any;
//     value?: any;
//     name?: string;
//     type?: string;
//     disabled?: boolean;
//     error?: string;
//     touched?: boolean;
//     id?: string;
//     className?: string;
//     suffix?: any;
//   }

type FormInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  Partial<{
    label: string;
    onChange?: any;
    value?: any;
    name?: string;
    defaultValue?: any;
    type?: string;
    disabled?: boolean;
    error?: string;
    touched?: boolean;
    hintText?: string;
    id?: string;
    className?: string;
    inputProps?: any;
    suffix?: any;
    autoComplete?: string;
  }>;

export const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  ({
    label,
    onChange,
    value,
    name,
    id,
    type,
    disabled,
    error,
    touched,
    hintText,
    className,
    suffix,
    placeholder,
    inputProps,
    defaultValue,
    autoComplete = 'off',
    ...restProps
  }) => (
    <div className={`w-full mx-auto mb-4 sm:mb-0 ${className}`}>
      {" "}
      {/* Adjusted width for small screens */}
      <div className="flex flex-col text-lg md:mb-2">
      <label
          htmlFor={label?.replace(/ /g, "").toLowerCase()}
          className="text-sm md:text-base lg:text-lg xl:text-xl"
          style={{ color: colors.darkGrey }}
        >
          {label}
        </label>
        {type === "phone" ? (
          <div className="relative">
            <PhoneInput
              type={type}
              name={name}
              defaultCountry="IN"
              onChange={onChange}
              value={value}
              disabled={disabled}
              placeholder={placeholder}
              style={{
                color: disabled ? colors.lightGrey : colors.darkGrey, //CH11/5
                border: `1px solid ${colors.darkBg}`,
              }}
              className={`w-full mt-3 bg-white rounded-lg border border-solid p-4 input-phone-number`}
            />
            <span className="absolute right-5 top-7">{suffix}</span>
          </div>
        ) : (
          <div className="relative">
            <input
              className={`w-full mt-3 bg-white rounded-lg border border-solid p-4`}
              style={{
                color: disabled ? colors.lightGrey : colors.darkGrey, //CH11/5
                border: `1px solid ${colors.darkBg}`,
              }}
              type={type ?? "text"}
              id={label?.replace(/ /g, "").toLowerCase()}
              aria-label={label}
              onChange={onChange}
              value={value}
              name={name}
              disabled={disabled}
              placeholder={placeholder}
              defaultValue={defaultValue}
              autoComplete="off"
              {...inputProps}
              {...restProps}
            />
            <span className="absolute right-5 top-7">{suffix}</span>
          </div>
        )}
        {!!error && touched ? (
          <p className="text-secondary h-1.5">{error}</p>
        ) : !!hintText ? (
          <p className="text-textGrey h-1.5 text-xs mt-1">{hintText}</p>
        ) : (
          <p className="text-secondary h-1.5"></p>
        )}
      </div>
    </div>
  )
);
