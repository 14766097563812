import React, { FC, useEffect, useRef, useState } from "react";
import FormDatePicker from "../../../components/FormDatePicker/FormDatePicker";
import {
  Checkbox,
  FormControlLabel,
  Switch,
  TextFieldProps,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import person from "../../../assets/icons/f7_person-2 blue.png";
import { colors } from "../../../constants/colors";

import { Formik } from "formik";
import * as yup from "yup";

import dayjs from "dayjs";
import styles from "../Individual.module.css";
import file from "../../../assets/icons/file.png";
import Buttons from "../../../components/Buttons/IndividualButtons/Buttons";
import { useDispatch } from "react-redux";
import { getUserById, updateUser } from "../../../redux/user/userSlice";
import PhoneInput from "react-phone-number-input";
import { E164Number } from "libphonenumber-js/core";
import { useUserData } from "../../../hooks/useUserData";
import toast from "react-hot-toast";
import { FormInput } from "../../../components/FormInput/FormInput";
import FormSelect from "../../../components/FormSelect/FormSelect";
import { City, State } from "country-state-city";
import { IndianStates, getKeyByValue } from "../../../enums/states";
import { getStatesOfCountry } from "country-state-city/lib/state";
import { Button } from "../../../components";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import { addOrganization } from "../../../redux/NonInd_GeneralDetails/generalSlice";


interface InfoSectionProps {
  icon: string;
  title: string;
  bgColor: string;
}

interface FormInputProps {
  label: string;
  onChange?: any;
  value?: any;
  name?: string;
  type?: string;
  disabled?: boolean;
}

const businessTypeOptions = [
  { label: "huf", value: "1" },
  { label: "corporate", value: "2" },
  { label: "llp", value: "3" },
  { label: "trust", value: "4" },
];

interface Country {
  label: string;
  value: string;
}

interface State {
  name: string;
}

interface City {
  name: string;
}


type SectionKey = "HUF" | "Corporate" | "LLP" | "Trust";
interface GeneralDetailsFormProps {
  onGeneralToCompany: () => void;
  activeSection: string;
  allowToProceed: () => void;
  onBusinessTypeChange: (businessType: SectionKey) => void;
  userData: any;
}

const userValues = {
  email:"",
  mobile:"",
  companyName:"",
  businessType: "",
  authSignName: "",
  designation:"",
  address1:"",
  address2: "",
  address3: "",
  city: "",
  state: "",
  country: "",
};

const userSchema = yup.object({
  companyName: yup.string().required("Company Name is required"),
  businessType: yup.string().required("Business Type is required"),
  authSignName: yup.string().required("Authorised Signatory Name is required"),
  designation:yup.string().required("Designation Signatory Name is required"),
  address1: yup.string().required("Address is required"),
  address2: yup.string().required("Address is required"),
  city: yup.string().required("City is required"),
  state: yup.string().required("State is required"),
  country: yup.string().required("Country is required"),
});

const GeneralDetailsForm: FC<GeneralDetailsFormProps> = ({
  onGeneralToCompany,
  activeSection,
  allowToProceed,
  onBusinessTypeChange,
  userData,
}) => {
  const updateUserRef = useRef<any>();
  const formikRef = useRef<any>();
  const dispatch = useDispatch<any>();
  const user = useUserData();
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const[availableData,setAvailableData]=useState(false);
  const [countries, setCountries] = useState<Country[]>([]);
  const[FormData,setFormData]=useState(false);


  const [alignment, setAlignment] = React.useState("web");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [generalData, setGeneralData] = useState<any>({
    companyName: "",
    businessType: "",
    authSignName: "",
    designation: "",
    email: "",
    mobile: "",
    address1: "",
    address2: "",
    address3: "",
    website: "",
    city:"",
    state:"",
    country:""
  });

  useEffect(() => {
    setAvailableData(userData?.mobile!=="" && userData?.gender !== "" && userData?.addressType>=0 && userData?.address1!=="" &&  userData?.address2!=="" && userData?.pincode!=="" && userData?.state!=="" && userData?.city!=="" && userData?.wealthSource!=="" && userData?.incomeSlab!=="" && userData?.occupation!=="" && userData?.politicalExposed?.toString()!=="" && userData?.isNRI?.toString()!=="");
  }, [userData]);



  const isGeneralDataComplete = () => {
    console.log(generalData, 'genearalData');
    // Iterate over the values and check if any are empty
    return Object.values(generalData).every((value) => value !== "");
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
  };

  const [selectedOption, setSelectedOption] = useState<string>("manual");
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getUserById({}));
    getStates();
    fetch("https://restcountries.com/v3.1/all")
  .then((response) => response.json())
  .then((data) => {
    // Filter only the country with the name "India"
    const countriesData = data
      .filter((country: any) => country.name.common === "India")
      .map((country: any) => ({
        label: country.name.common,
        value: country.cca2, // Country code (ISO 3166-1 alpha-2)
      }));

    // Set the filtered data to the state
    setCountries(countriesData);
  })
  .catch((error) => console.error("Error fetching country data:", error));

  }, [dispatch]);

  useEffect(() => {
    console.log(userData, "User Data");
    if (userData) {
      if (userData?.email) {
        formikRef.current?.setFieldValue("email", userData?.email);
        addGeneralData("email", userData?.email);
      }
      if (userData?.mobile) {
        formikRef.current?.setFieldValue("mobile", userData?.mobile);
        addGeneralData("mobile", userData?.mobile);
      }
      if (userData?.organization?.companyName) {
        formikRef.current?.setFieldValue(
          "companyName",
          userData?.organization?.companyName
        );
        addGeneralData("companyName",
          userData?.organization?.companyName);
      }
      if (userData?.organization?.businessType) {
        formikRef.current?.setFieldValue(
          "businessType",
          userData?.organization?.businessType
        );
        addGeneralData("companyName",
          userData?.organization?.companyName);
      }
      if (userData?.organization?.authSignName) {
        formikRef.current?.setFieldValue(
          "authSignName",
          userData?.organization?.authSignName
        );
        addGeneralData("authSignName",
          userData?.organization?.authSignName);
      }
      if (userData?.organization?.designation) {
        formikRef.current?.setFieldValue(
          "designation",
          userData?.organization?.designation
        );
        addGeneralData("designation",
          userData?.organization?.designation);
      }
      if (userData?.address1) {
        formikRef.current?.setFieldValue(
          "address1",
          userData?.address1
        );
        addGeneralData("address1",
          userData?.address1);
      }
      if (userData?.address2) {
        formikRef.current?.setFieldValue(
          "address2",
          userData?.address2
        );
        addGeneralData("address2",
          userData?.address2);
      }
      if (userData?.address3) {
        formikRef.current?.setFieldValue(
          "address3",
          userData?.address3
        );
        addGeneralData("address3",
          userData?.address3);
      }
      if (userData?.organization?.website) {
        formikRef.current?.setFieldValue(
          "website",
          userData?.organization?.website
        );
        addGeneralData("website",
          userData?.organization?.website);
      }
      if (userData?.state) {
        formikRef.current?.setFieldValue("country", "India");
        addGeneralData("country", "India"); // Default country set to India if state exists
        const state = getKeyByValue(userData?.state);
        formikRef.current?.setFieldValue(
          "state",
          state
        );
        addGeneralData("state",
          state);
          getCities(state);
      }
      if (userData?.city) {
        formikRef.current?.setFieldValue(
          "city",
          userData?.city
        );
        addGeneralData("city",
          userData?.city);
      }
    }
  }, [userData]);

  const addGeneralData = (key: string, value: string) => {
    setGeneralData((prevData: any) => ({ ...prevData, [key]: value }));
  };

  const handleRadioChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
  };

  const getStates = () => {
    setStates(
      State.getStatesOfCountry("IN").map((state) => {
        return {
          label: state.name,
          value: state.name,
          isoCode: state.isoCode,
          code: IndianStates[state.name],
        };
      })
    );
  }
  
  const getCities = (state: any) => {
    const currentState = getStatesOfCountry('IN').filter((item: any) => {
      return item.name === state;
    })[0];
    setCities(
      City.getCitiesOfState("IN", currentState.isoCode).map((x: any) => {
        return { label: x.name, value: x.name };
      })
    );
  };

  const handleModalConfirm = async () => {
    setIsModalVisible(false);

    if (formikRef.current) {
      // Trigger Formik's submission handler
      await formikRef.current.handleSubmit();

      const values = formikRef.current.values;
      console.log(values,)
      const body={
        companyName:values.companyName,
        businessType:values.businessType,
        authSignName:values.authSignName,
        designation:values.designation,
        email:values.email,
        mobile:values.mobile,
        address1:values.address1,
        address2:values.address2,
        address3:values.address3,
        city: values.city,
        state:IndianStates[values.state],
        country:values.country,
        website:values.website
      }
      console.log(body,"Body");
      try {
        const response = await dispatch(addOrganization(body));
        console.log(response,response.payload,response.payload.data,response.payload.data.data,"Response");
        if (response.payload.success === 200) {
          toast.success("Organization added successfully!");
        allowToProceed();  
          onGeneralToCompany(); // Proceed to next step
        } else {
          toast.error("Failed to add organization. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred. Please try again.");
        console.error(error);
      }
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Hide the modal if user cancels
  };

  const handleSaveAndProceed = () => {
    if (formikRef.current?.isValid && formikRef.current?.dirty) {
      console.log(formikRef.current?.values,"Form Values");
      setIsModalVisible(true);  // Show the modal if form is valid
    }
    else {
      return;
    }
  };

  const checkAlreadySubmitted = () => {
    if (isGeneralDataComplete()) {
      allowToProceed();  
      onGeneralToCompany();
    } else {
      handleSaveAndProceed();
    }
  };


  const fetchCityAndState = (pincode: string) => {
    if (!pincode || pincode.length !== 6) {
      return;
    }
    fetch(`https://api.postalpincode.in/pincode/${pincode}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "data");
        if (data[0].Status === "Success") {
          const { PostOffice } = data[0];
          if (PostOffice.length > 0) {
            formikRef.current.setFieldValue("state", PostOffice[0].State);
            getCities(PostOffice[0].State);
            // if (PostOffice[0].District === "Bangalore") {
            //   formikRef.current.setFieldValue("city", "Bengaluru");
            // }
            // formikRef.current.setFieldValue("city", PostOffice[0].District);
          } else {
            toast("No data found for this pincode");
          }
        } else {
          toast("Invalid pincode");
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    // { value: "other", label: "Other" },
  ];
  const [passingData, setPassingData] = useState({});

  const addressTypes = [
    { value: 1, label: "Residential or Business" },
    { value: 2, label: "Residential" },
    { value: 3, label: "Business" },
    { value: 4, label: "Registered Office" },
    { value: 5, label: "Prefer not to say" },
  ];

  const wealthSource = [
    { value: "01", label: "Salary" },
    { value: "02", label: "Business Incode" },
    { value: "03", label: "Gift" },
    { value: "04", label: "Ancestral Property" },
    { value: "05", label: "Rental Income" },
    { value: "06", label: "Prize Money" },
    { value: "07", label: "Royalty" },
    { value: "08", label: "Others" },
  ];

  const incomeSlab = [
    { value: "31", label: "Below 1 Lakh" },
    { value: "32", label: "1 to 5 Lacs" },
    { value: "33", label: "5 to 10 Lacs" },
    { value: "34", label: "10 to 25 Lacs" },
    { value: "35", label: "25 Lacs to 1 Crore" },
    { value: "36", label: "Above 1 Crore" },
  ];

  const occupation = [
    { value: "01", label: "Business" },
    { value: "02", label: "Service" },
    { value: "03", label: "Professional" },
    { value: "04", label: "Agriculturist" },
    { value: "05", label: "Retired" },
    { value: "06", label: "Housewife" },
    { value: "07", label: "Student" },
    { value: "08", label: "Others" },
    { value: "09", label: "Doctor" },
    { value: "41", label: "Private Sector Service" },
    { value: "42", label: "Public Sector Service" },
    { value: "43", label: "Forex Dealer" },
  ];

  return (
    <>
      <div className="flex flex-col w-full max-w-screen-xl my-8">
        <Formik
          innerRef={formikRef}
          initialValues={userValues}
          validationSchema={userSchema}
          onSubmit={async (values, { setSubmitting }) => {
            console.log("Form Values:", values);
            setSubmitting(false); // Reset the submitting state after processing
            // const payload = {
            //   ...values,
            //   state: IndianStates[values.state],
            // };
            // const response: any = await dispatch(updateUser(payload));
            // if (response?.error?.message) {
            //   toast.error("Failed to update details");
            //   return;
            // }
            // onProceed();
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
          }) => {
            const detailsPassing = ({
              field,
              value,
            }: {
              field: string;
              value: any;
            }) => {
              setPassingData((prevData) => ({ ...prevData, [field]: value }));
            };
            return (
              <>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    console.log(values, "values");
                    console.log(errors, "errors");
                    handleSubmit();
                  }}
                  className="w-full flex flex-col gap-[31px]"
                >
                  <div className="flex flex-row flex-wrap gap-8 justify-center">
                  <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Company Name *"
                        name="companyName"
                        onChange={handleChange("companyName")}
                        value={values.companyName}
                        // disabled={userData?.mobile!=""}
                        error={errors.companyName}
                        touched={touched.companyName}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      {" "}
                      {/* Adjusted width for Gender */}
                      <FormSelect
                        label="Business Type *"
                        options={businessTypeOptions}
                        onChange={handleChange("businessType")}
                        value={values.businessType}
                        error={errors.businessType}
                        touched={touched.businessType}
                        // disabled={userData?.gender!==-1}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Authorised Signatory Name *"
                        name="authSignName"
                        onChange={handleChange("authSignName")}
                        value={values.authSignName}
                        error={errors.authSignName}
                        touched={touched.authSignName}
                        // disabled={userData?.addressType!==-1}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Designation *"
                        name="designation"
                        onChange={handleChange("designation")}
                        value={values.designation}
                        // disabled={userData?.pincode!==null}
                        error={errors.designation}
                        touched={touched.designation}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                    <FormInput
                    label="Official Email ID "
                    type="text"
                    name="Email"
                    onChange={(e: any) =>
                      setFieldValue("email", e.target.value)
                    }
                    value={values.email}
                    error={errors.email}
                    touched={touched.email}
                    disabled={userData?.email!=""}
                    // suffix={<span className="text-sm text-secondary cursor-pointer font-semibold" onClick={() => setEmailDisabled(!emailDisabled)}>{emailDisabled ? "Edit" : "Save"}</span>}
                  />
                      
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                    label="Mobile Number"
                    type="phone"
                    name="mobile"
                    onChange={(e: any) => setFieldValue("mobile", e)}
                    value={values.mobile}
                    error={errors.mobile}
                    touched={touched.mobile}
                     disabled={userData?.mobile!=""}
                  />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="Country *"
                        name="country"
                        onChange={(e: any) => {
                          console.log(e.target.value, "e");
                          setFieldValue("country", e.target.value);
                        }}
                        value={values.country}
                        disabled={userData?.country}
                        error={errors.country}
                        touched={touched.country}
                        options={countries}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="State *"
                        name="state"
                        onChange={(e: any) => {
                          console.log(e.target.value, "e");
                          setFieldValue("state", e.target.value);
                          getCities(e.target.value);
                        }}
                        value={values.state}
                        disabled={userData?.state}
                        error={errors.state}
                        touched={touched.state}
                        options={states}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormSelect
                        label="City *"
                        name="city"
                        onChange={(e: any) => {
                          console.log(e.target.value, "e");
                          setFieldValue("city", e.target.value);
                        }}
                        value={values.city}
                        disabled={userData?.city}
                        error={errors.city}
                        touched={touched.city}
                        options={cities}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Address 1 *"
                        name="address1"
                        onChange={handleChange("address1")}
                        value={!values.address1?.includes("null") ? values.address1 : ""}
                        // disabled={userData?.address1!=="" && !userData.address1?.includes("null") ? userData.address1 : ""}
                        error={errors.address1}
                        touched={touched.address1}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Address 2 *"
                        name="address2"
                        onChange={handleChange("address2")}
                        value={!values.address2?.includes("null") ? values.address2 : ""}
                        // disabled={userData?.address1!=="" && !userData.address1?.includes("null") ? userData.address1 : ""}
                        error={errors.address2}
                        touched={touched.address2}
                      />
                    </div>
                    <div className="w-full max-w-[500px]">
                      <FormInput
                        label="Address 3"
                        name="address3"
                        onChange={handleChange("address3")}
                        value={!values.address3?.includes("null") ? values.address3 : ""}
                        // disabled={userData?.address1!=="" && !userData.address1?.includes("null") ? userData.address1 : ""}
                        // error={errors.address3}
                        // touched={touched.address1}
                      />
                    </div>
                    <div className="w-full max-w-[500px]"></div>
                  </div>
                  {(userData?.organization?.companyName==null ||userData?.organization?.companyName=="")?(<div className="pl-32">
                    <button
                      type="submit"
                      className={`px-8 py-4 rounded-md bg-primary text-white hover:bg-white hover:text-primary border border-primary transition-all`}
                      tabIndex={0}
                      onClick={checkAlreadySubmitted}
                      //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                      // Disable button if radio is not selected
                    >
                      Save and Proceed
                    </button>
                  </div>):(
                    <div className="pl-32">
                    <Button
                      type="button"
                      className={`px-8 py-4 rounded-md bg-darkGrey text-white hover:bg-primary transition-all max-w-[250px] w-full mt-12`}
                      tabIndex={0}
                      onClick={checkAlreadySubmitted}
                      //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                      // Disable button if radio is not selected
                    >
                      Proceed
                    </Button>
                  </div>
                  )}
                  
                  <ConfirmationModal
                    isVisible={isModalVisible} // Render conditionally based on state
                    message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
                    onConfirm={handleModalConfirm} // Confirm action and submit form
                    onCancel={handleModalCancel} // Cancel action and close modal
                  />
                </form>
              </>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default GeneralDetailsForm;
