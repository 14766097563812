import React, { useState } from 'react';
import { TableRow, IconButton, Collapse, Table, TableBody, TableCell, Container } from '@mui/material';
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { colors } from '../../constants/colors';
import TimeStamptoExp from "../../utils/timestampConverter";

const FDTableRowComponent = ({ data, getInitials, modalMenuClick }: any) => {
    const [openIndex, setOpenIndex] = useState<number | null>(null);

    const handleExpandClick = (index: number) => {
        setOpenIndex(openIndex === index ? null : index);
    };
    const isArray=Array.isArray(data);
    function formatDate(dateString:any) {
        const date = new Date(dateString);
        
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
        const year = date.getFullYear();
    
        return `${day}-${month}-${year}`;
    }
    const convertDaysToReadableFormat = (days: number,schemeName:string): string => {
      if (schemeName && schemeName?.includes("HDFC")) {
        if (days <= 90) {
          return `${days} day${days > 1 ? "s" : ""}`;
        }
      } else {
        return days<=1?days +" month":days+" months" ;// Dynamic value for 12 months, approximated as 12 * 30 = 360 days
      }
      const today = new Date();
      let currentYear = today.getFullYear();
      let currentMonth = today.getMonth(); // Month is 0-based (0 = January, 11 = December)
      let currentDay = today.getDate();
  
      // Helper function to get the number of days in a given month and year
      const daysInMonth = (month: number, year: number): number => {
        return new Date(year, month + 1, 0).getDate();
      };
  
      // Determine the month length to use based on the current day
      let monthLength = daysInMonth(currentMonth, currentYear);
      if (currentDay > 20) {
        currentMonth += 1;
        if (currentMonth > 11) {
          currentMonth = 0;
          currentYear += 1;
        }
        monthLength = daysInMonth(currentMonth, currentYear);
      }
  
      let totalMonths = 0;
      let remainingDays = days;
      let futureDate = new Date(today);
  
      // Calculate total months and remaining days
      while (remainingDays >= monthLength) {
        totalMonths += 1;
        remainingDays -= monthLength;
        currentMonth += 1;
        if (currentMonth > 11) {
          currentMonth = 0;
          currentYear += 1;
        }
        monthLength = daysInMonth(currentMonth, currentYear);
      }
  
      // Calculate the exact future date
      futureDate.setMonth(today.getMonth() + totalMonths);
      futureDate.setDate(today.getDate() + remainingDays);
  
      // Format the readable string
      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;
      let readableFormat = "";
      if (years > 0) readableFormat += `${years} yr${years > 1 ? "s" : ""} `;
      if (months > 0) readableFormat += `${months} mon `;
      if (remainingDays > 0) readableFormat += `${remainingDays} day${remainingDays > 1 ? "s" : ""} `;
  
      return readableFormat.trim();
    };
    const formatInterestRate = (interestRate: string | number | null | undefined): string => {
      if (typeof interestRate === 'string' && interestRate.trim().endsWith('%')) {
        // If the interest rate is already a string with '%', return it as is
        return interestRate;
      } else if (typeof interestRate === 'number' || !isNaN(Number(interestRate))) {
        // If it's a number or can be converted to a number, format it
        return `${Number(interestRate).toFixed(2)}%`;
      } else {
        // If the value is invalid or not provided, return a default value
        return '-';
      }
    };

    return (
        <>
            {isArray && data?.map((item: any, index: number) => {
                const Name = item?.schemeName || 'N/A';

                return (
                    <React.Fragment key={item.id}>
                        <TableRow className="border-[1px] border-lightGrey hover:bg-lightBg">
                            <TableCell component="th" scope="row" sx={{ 
                                    borderLeft: '1px solid #ccc', 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} className="min-w-[200px]">
                                <div className="flex gap-x-2 items-center">
                                    <div className="w-12 h-10 mb-3 md:mb-0 rounded-5xl flex items-center justify-center bg-gray-300 text-primary text-xl font-bold">
                                        {getInitials(Name)}
                                    </div>
                                    <div className="w-full ml-1">
                                        <span className="cursor-pointer" onClick={() => handleExpandClick(index)}>{Name}</span>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{formatDate(item?.createdAt) || '-'}</TableCell>
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{item?.investmentTenure}</TableCell>
                                <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{item?.endDate
                                    ? /^\d{2}\/\d{2}\/\d{4}$/.test(item?.endDate.toString()) // Check if the endDate is in DD/MM/YYYY format
                                      ? item.endDate // If already in correct format, display the date as is
                                      : TimeStamptoExp.formatDateFromTimestamp(Number(item?.endDate)) // Otherwise, format it
                                    : "-"}</TableCell>
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{"Rs."+Number(item?.amount).toFixed(2) || '-'}</TableCell>
                                <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{formatInterestRate(item?.interesetRate)|| '-'}</TableCell> 
                                <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{item?.investmentFrequency}</TableCell> 
                                <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                }} align="center" className="min-w-[100px]">{"Rs."+ Number(item?.returnAmount - item?.amount).toFixed(2) || '-'}</TableCell> 
                            <TableCell sx={{ 
                                    borderTop: '1px solid #ccc',
                                    borderBottom: '1px solid #ccc',
                                    borderRight: '1px solid #ccc', 
                                }} align="center" className="min-w-[100px]">{"Rs."+Number(item?.returnAmount).toFixed(2)  || '-'}</TableCell>                           
                        </TableRow>
                        <TableRow sx={{ height: "5%" }}>
                  <TableCell
                    sx={{ border: "none" }}
                    colSpan={8}
                    align="center"
                  ></TableCell>
                </TableRow>
                    </React.Fragment>
                );
            })}
        </>
    );
};


export default FDTableRowComponent;
