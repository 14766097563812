import { FC, useEffect, useRef, useState } from "react";
import Header from "../../components/Header/Header";
import Elipse from "../../assets/images/Ellipse.png";
import { colors } from "../../constants/colors";
import { Button } from "../Button";
import plus from "../../../src/assets/icons/plus-circle-outline.png";
import MfInfo from "../../components/MFInfoSection/MFInfo";
import Alert from "@mui/material/Alert";

import * as yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import investSlice, { addMFund } from "../../redux/InvestMF/investSlice";
import {
  checkPaymentStatus,
  createPayment,
  InvestMF,
  InvestRequest,
} from "../../redux/MF/MFSlice";
import toast from "react-hot-toast";
import { useUserData } from "../../hooks/useUserData";
import getInitials from "../../utils/getInitailasIMGUtils";
import MFDetailCard from "./MFDetailCard";
import { FormInput } from "../FormInput/FormInput";
import FormSelect from "../FormSelect/FormSelect";
import dayjs from "dayjs";
import FormDatePicker from "../FormDatePicker/FormDatePicker";
import { hideLoading, showLoading } from "../../redux/loader/loaderSlice";
import MFFooterCard from "./MFFooterCard";
import { getAmountInWords } from "../../utils";
import QuantityInput from "../QuantityInput/QuantityInput";
import AmountButtons from "./AmountButtons";
import {
  Button as Btn,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { fetchDashboardData } from "../../redux/Dashboard/DashboardSlice";
import { showInterest } from "../../redux/user/userSlice";
import SuccessfullModal from "../modals/SuccessfullModal";
import { BrowserPopUpModal } from "../../utils/BrowserPopUpModal";
import { useNavigate } from "react-router-dom";
import APIloader from "../AppLoader/APIloader";
import CustomDialog from "../modals/BsePaymentAutherizationModal";

interface MFProps {
  activeSection: string;
  fundId: number;
  fundInfo?: any;
  onClose?: any;
}

const SIP: FC<MFProps> = ({ fundId, fundInfo, onClose }) => {
  const sipRef = useRef<any>();
  const dispatch = useDispatch<any>();
  const navigate=useNavigate();
  const { bank } = useUserData() || { bank: [] };
  const userData=useUserData();
  const [investmentPlan, setInvestmentPlan] =
    useState<string>("New investment");
    const [loading, setLoading] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState("");
  const [sipDates, setSipDates] = useState<string[]>([]);
  const [sipEndDate, setSipEndDate] = useState<string>();
  const [sipFrequencies, setSipFrequencies] = useState([]);
  const [bankAccounts, setBankAccounts] = useState<any[]>([]);
  const [sipFrequencyData, setSipFrequencyData] = useState<any>({});
  const [openDialog, setOpenDialog] = useState(false);
  const [summaryData, setSummaryData] = useState<any>({});
  const [transactionData, setTransactionData] = useState<any>(null);
  const [isNewInvestmentEnabled, setIsNewInvestmentEnabled] = useState(true);
  const [Open, setOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [errormessage, setErrorMessage] = useState<string>("");
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [authLink, setAuthLink] = useState("");
  const [orderID, setOrderID] = useState("");
  const [button, setButton] = useState(false);
  const [loadmessage, setLoadMessage] = useState<string>("");
  const [htmlContent, setHtmlContent] = useState<string>("");
  const [success, setSuccess] = useState(false);
  const [InterestOpen, setInterestOpen] = useState(false);
  const [popupWindow, setPopupWindow] = useState<Window | null>(null);
  const [paymentWindow, setPaymentWindow] = useState<Window | null>(null);
  const [sipValues, setSipValues] = useState({
    orderType: 1, // 1 for lumpsump, 2 for sip, 3 for swp, 4 for stp
    newOrder: 1,
    fundId: 0,
    amount: 0,
    bankId: 0,
    folioNumber: "",
    dividend: "",
    startDate: "",
    frequency: 0,
    noOfInstallment: 0,
    firstOrderToday: "",
    units: 0,
    transType: "",
    STPType: "",
    buySellType: "",
    toSchemeId: 0,
    folioNo: "",
  });

  // Schema for form validation
  const [sipSchema, setSipSchema] = useState<any>(
    yup.object({
      folioNo: yup.string().required("Folio number is required"),
      orderType: yup.number().required("Order type is required"),
      amount: yup
        .number()
        .required("Amount is required")
        .when("frequency", (frequency, schema) => {
          const { minSip, maxSip } = sipFrequencyData[
            parseInt(frequency[0])
          ] || { minSip: "100", maxSip: "9999999" };
          return schema
            .min(minSip, `Amount must be greater than or equal to ${minSip}`)
            .max(maxSip, `Amount must be less than or equal to ${maxSip}`);
        }),
      startDate: yup
        .string()
        .test(
          "is-valid-start-date",
          `Invalid start date. Start date should be: ${sipDates}`,
          function (value) {
            if (!value) return true;
            const date = dayjs(value, "DD/MM/YYYY").format("D");
            if (!sipDates) return true;
            return sipDates?.includes(date);
          }
        )
        .required("Start date is required"),
      frequency: yup.number().required(),
      noOfInstallment: yup
        .number()
        .when("frequency", (frequency, schema) => {
          const f = parseInt(frequency[0]);
          if (f === 1) return schema;
          return schema.required("No. of Installment is required");
        })
        .when("frequency", (frequency, schema) => {
          const { minInstallment, maxInstallment } = sipFrequencyData[
            parseInt(frequency[0])
          ] || { minInstallment: "5", maxInstallment: "50" };
          return schema
            .min(
              minInstallment,
              `Installment must be more than or equal to ${minInstallment}`
            )
            .max(
              maxInstallment,
              `Installment must be less than or equal to ${maxInstallment}`
            );
        }),
      bankId: yup.string().required("Bank Account is required"),
    })
  );

  // Update validation schema when SIP frequencies or dates change
  useEffect(() => {
    if (sipFrequencies.length > 0) {
      setSipSchema(
        yup.object({
          folioNo: yup.string(),
          orderType: yup.number().required(),
          amount: yup
            .number()
            .required("Amount is required")
            .when("frequency", (frequency, schema) => {
              const { minSip, maxSip } = sipFrequencyData[
                parseInt(frequency[0])
              ] || { minSip: "100", maxSip: "9999999" };
              return schema
                .min(
                  minSip,
                  `Amount must be greater than or equal to ${minSip}`
                )
                .max(maxSip, `Amount must be less than or equal to ${maxSip}`);
            }),
          startDate: yup
            .string()
            .test(
              "is-valid-start-date",
              `Invalid start date. Start date should be: ${sipDates}`,
              function (value) {
                if (!value) return true;
                const date = dayjs(value, "DD/MM/YYYY").format("D");
                if (!sipDates) return true;
                return sipDates?.includes(date);
              }
            )
            .required("Start date is required"),
          frequency: yup.number().required(),
          noOfInstallment: yup
            .number()
            .when("frequency", (frequency, schema) => {
              const f = parseInt(frequency[0]);
              if (f === 1) return schema;
              return schema.required("No. of Installment is required");
            })
            .when("frequency", (frequency, schema) => {
              const { minInstallment, maxInstallment } = sipFrequencyData[
                parseInt(frequency[0])
              ] || { minInstallment: "5", maxInstallment: "50" };
              return schema
                .min(
                  minInstallment,
                  `Installment must be more than or equal to ${minInstallment}`
                )
                .max(
                  maxInstallment,
                  `Installment must be less than or equal to ${maxInstallment}`
                );
            }),
          bankId: yup.string().required("Bank Account is required"),
        })
      );
    }
  }, [sipFrequencies, sipDates]);

  // Set form values based on fund info
  useEffect(() => {
    if (fundId) {
      sipRef.current?.setFieldValue("fundId", fundId);
    }
  }, [fundId]);

  // Update SIP info based on fund info
  useEffect(() => {
    if (fundInfo) {
      sipRef.current?.setFieldValue("folioNo", fundInfo?.folioNumber[0]?.folio);
      if (fundInfo?.sipInfo?.length > 0) {
        const fqs: any = [];
        const data: any = {};
        fundInfo?.sipInfo?.forEach((info: any) => {
          data[info?.sipFrequency] = {
            minSip: info?.sipMinimumInstallmentAmount,
            maxSip: info?.sipMaximumInstallmentAmount,
            minInstallment: info?.sipMinimumInstallmentNumber,
            maxInstallment: info?.sipMaximumInstallmentNumber,
          };
          info?.sipFrequency === 1 && fqs.push({ label: "Daily", value: 1 });
          info?.sipFrequency === 2 && fqs.push({ label: "Weekly", value: 2 });
          info?.sipFrequency === 3 && fqs.push({ label: "Monthly", value: 3 });
          info?.sipFrequency === 4 &&
            fqs.push({ label: "Quarterly", value: 4 });
        });
        console.log(data, "data sip");
        setSipFrequencyData(data);
        setSipFrequencies(fqs);
      }
    }
  }, [fundInfo]);

  // Update bank account info based on user data
  useEffect(() => {
    if (Object.keys(bank).length > 0) {
      const banks = [];
      banks.push({
        label: `${bank?.bankName} - ${bank?.accountNumber}`,
        value: bank?.id,
      });
      setBankAccounts(banks);
    }
  }, [bank]);

  // Handle frequency change to update SIP dates
  const onFrequencyChange = (value: any) => {
    fundInfo?.sipInfo?.forEach((info: any) => {
      if (info?.sipFrequency == value) {
        setSipDates(info?.sipDates);
      }
    });
  };

  // Submit SIP form
  const investInSIP = async (values: any) => {
    try {
    const todayDate = new Date();
  const today = `${String(todayDate.getDate()).padStart(2, '0')}/${String(todayDate.getMonth() + 1).padStart(2, '0')}/${todayDate.getFullYear()}`; // Format today in DD/MM/YYYY
      const payload = {
        folioNo: investmentPlan === "Existing investment" ? values.folioNo : "",
        fundId: fundId.toString(),
        amount: values.amount,
        orderType: "2",
        startDate: values.startDate,
        frequency: values.frequency,
        noOfInstallment: values.noOfInstallment,
        firstOrderToday:
          values.startDate === today
            ? "Y"
            : "N",
            isDirectAllowed: userData?.preferences?.direct===1?1:0,
      };
        setLoading(true);
        setLoadMessage("Processing your request, please wait 30s to 40s...");
        handleCloseDialog();
      const res = await dispatch(InvestMF(payload));
      // Ensure response is in the expected format
      setOrderID(res?.payload?.data?.data?.orderId);
        if (res?.payload?.data?.success === 200) {
          setLoading(false);
          const authLink = res?.payload?.data?.data?.authLink;
            setAuthLink(authLink);
            setAuthModalOpen(true); // Open modal with auth link
        } else {
          setLoading(false);
          setInterestOpen(true);
          setMessage(res?.payload?.data?.message ||
            "Error in executing your order."
          );
       }
    } catch (error) {
      setOpen(true);
      setErrorMessage("Investment Failed");
    } finally {
      dispatch(hideLoading());
      handleCloseDialog();
    }
  };

  const handleAuthModalClose = async () => {
    setAuthModalOpen(false); // Close the auth link modal
      try {
        const response=await dispatch(
          createPayment({
            orderId: orderID,
            modeofpayment: "DIRECT",
            vpaid: "",
            bankid: parseInt(bank[0]?.id),
            isDirectAllowed: userData?.preferences?.direct===1?1:0,
          })
        );
        setOpen(true);
        if(response.payload.data.statusCode===200){          
          setHtmlContent(response.payload.data.data);
          setButton(response.payload.data.statusCode===200);
          setMessage("Click Ok to proceed your payment.");
           // Open the modal once data is fetched
        }
        else if(response.payload.data.statusCode===400){
          setInterestOpen(true);
          setMessage(response.payload.data.message);
        }
        else{
          setErrorMessage("Authentication is failed.");
        }
      } catch (error) {
        console.error("Authentication is failed", error);
      }
  };

  const paymentStatus = async (): Promise<boolean> => {
    try {
      const response=await dispatch(
        checkPaymentStatus({
          orderId: orderID,
          isDirectAllowed: userData?.preferences?.direct===1?1:0,
        })
      );
      setOpen(true);
      if(response.payload.data.statusCode===200){    
        console.log(response.payload.data.statusCode,"response.payload.data.statusCode");      
        return true;
      }
      else{
        setErrorMessage(response.payload.data.message);
        return false;
      }
    } catch (error) {
      console.error("Authentication is failed", error);
      return false;
    }
};

  useEffect(() => {
    // Check if the popup window is closed every 100 milliseconds
    const checkPopupClosed = setInterval(() => {
      if (popupWindow && popupWindow.closed) {
        setLoading(false);
        clearInterval(checkPopupClosed); // Stop checking once the popup is closed
        handleAuthModalClose();
      }
    }, 1000);

    // Clear the interval if the component unmounts
    return () => clearInterval(checkPopupClosed);
  }, [popupWindow]);

  useEffect(() => {
    const checkPopupClosed = setInterval(() => {
      const handlePaymentStatus = async () => {
        if (paymentWindow && paymentWindow.closed) {
          clearInterval(checkPopupClosed); // Stop checking once the popup is closed
          
          // Call the paymentStatus function and wait for the response
          const isPaymentSuccessful = await paymentStatus();
          
          if (isPaymentSuccessful) {
            setLoading(false);
            onClose();
            navigate("/mf-listing");
          } else {
            setLoading(false);
            console.error("Payment verification failed or was not successful.");
          }
        }
      };
  
      handlePaymentStatus(); // Call the async function
    }, 1000);
  
    // Clear the interval if the component unmounts
    return () => clearInterval(checkPopupClosed);
  }, [paymentWindow]);



  const openAuthLink = () => {
    setAuthModalOpen(false);
    setLoadMessage("Please authenticate your order and close the bse tab...");
    setLoading(true);
    if (authLink) {
      const newPopupWindow =  BrowserPopUpModal(authLink, "BSE Autherization",1250,550);
      setPopupWindow(newPopupWindow);
    }
  };

  const handleCloseSuccessModal =()=>{
    setSuccess(false);
  }

  const openPaymentTab = () => {
    setOpen(false);
    setLoadMessage("We are processing you payment...");
    setLoading(true);
    if (!htmlContent) return;
  
    // Create a new Blob object containing the HTML content
    const blob = new Blob([htmlContent], { type: 'text/html' });
    const url = URL.createObjectURL(blob);

    if (url) {
      const newPopupWindow =  BrowserPopUpModal(url, "Payment Window",1250,550);
      setPaymentWindow(newPopupWindow);
    }

    else {
      setInterestOpen(true);
      setMessage('Unable to open payment tab, possibly due to popup blocker.');
    }
  };
  // Calculate SIP end date based on frequency and number of installments
  const calculateEndDate = (
    frequency: any,
    startDate: any,
    numOfInstallments: any
  ) => {
    if (!frequency || !numOfInstallments || !startDate) return;
    let endDate = dayjs(startDate, "DD/MM/YYYY");
    switch (frequency) {
      case 1:
        endDate = endDate.add(numOfInstallments - 1, "day");
        break;
      case 2:
        endDate = endDate.add(numOfInstallments - 1, "week");
        break;
      case 3:
        endDate = endDate.add(numOfInstallments - 1, "month");
        break;
      case 4:
        endDate = endDate.add((numOfInstallments - 1) * 3, "month");
        break;
      default:
        break;
    }

    const endDateValue = endDate.format("DD/MM/YYYY");
    if (endDateValue) {
      setSipEndDate(endDateValue);
    }
  };

  // Render radio button for investment plan selection
  const renderRadio = (option: string) => (
    <label key={option} className="flex items-center">
      <input
        type="radio"
        checked={investmentPlan === option}
        onChange={() => setInvestmentPlan(option)}
        className="mr-2"
        disabled={option === "New investment" && !isNewInvestmentEnabled}
      />
      {option}
    </label>
  );

  const handleOpenDialog = () => {
    const values = sipRef.current?.values;
    const amount = parseFloat(values?.amount || "0");
    const currentNAV = parseFloat(fundInfo?.currentNAV || "0");
    const units = currentNAV > 0 ? (amount / currentNAV).toFixed(2) : "N/A";
    const sipDate = values?.startDate || "N/A";
    const frequency =
      values?.frequency === 3
        ? "Monthly"
        : values?.frequency === 4
        ? "Quarterly"
        : "N/A";
    const folioNo =
      investmentPlan === "Existing investment"
        ? values?.folioNo || "N/A"
        : "N/A";
    const noOfInstallments = values?.noOfInstallment || "N/A";
    const purchaseMode = investmentPlan;

    const summary = {
      Amount: amount.toFixed(2),
      Units: units,
      Date: sipDate,
      Frequency: frequency,
      PurchaseMode: purchaseMode,
      FolioNumber: folioNo,
      NoOfInstallments: noOfInstallments,
      FirstOrderToday:
        sipDate === dayjs(new Date()).format("DD-MM-YYYY") ? "Yes" : "No",
    };

    setSummaryData(summary);
    setOpenDialog(true);
  };

  useEffect(() => {
    fetchData(); // Fetch data when component mounts or dependencies change
  }, []);

  const fetchData = async () => {
    let data = { fetchFor: "aumcapital" };
    try {
      const response = await dispatch(fetchDashboardData(data));
      if (response.payload.data.data) {
        setTransactionData(response.payload.data.data);
        evaluateInvestmentStatus(response.payload.data.data);
      }
    } catch (error) {
      console.error("Fetch dashboard data failed:", error);
      setOpen(true);
      setErrorMessage("Please Refresh");
      // setLoading(false);
    }
  };
  

  const evaluateInvestmentStatus = (data: any) => {
    let newInvestmentDisabled = false;

    // Log the entire recentTransactions array for debugging
    console.log("Recent Transactions Data:", data.recentTransactions);

    // Check if any of the MF orders are of type `isBuy` 1
    data.recentTransactions.forEach(
      (transactionGroup: any[], groupIndex: number) => {
        transactionGroup.forEach((order: any, orderIndex: number) => {
          console.log(`Processing order ${orderIndex}:`, order);

          if (order.orderType === "MF" && order.isBuy === 1) {
            console.log("Found MF order with isBuy 1:", order);
            newInvestmentDisabled = true;
          }
        });
      }
    );

    // Update the state based on the evaluation
    setIsNewInvestmentEnabled(!newInvestmentDisabled);

    // Log the final state of newInvestmentDisabled
    console.log("New Investment Disabled:", newInvestmentDisabled);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirmDialog = async () => {
    const values = sipRef.current?.values;
    await investInSIP(values);
  };

  const showingInterest = async () => {
    try {
      const response = await dispatch(
        showInterest({ id: fundId, holdingType: 2 })
      );

      if (response?.payload?.statusCode === 200) {
        setInterestOpen(true);
        setMessage("Your Interest is submitted successfully");
      } else {
        setInterestOpen(true);
        setErrorMessage("Something wents wrong.Please try again later!");
        console.warn("Unexpected response structure:", response);
      }
    } catch (error) {
      setInterestOpen(true);
      setErrorMessage("Something wents wrong.Please try again later!");
      console.error("Error fetching users:", error);
    }
  };

  const handleClose = () => {
    setOpen(false);
    onClose();
  };
  
  const handleInterestClose = () =>{
    setInterestOpen(false);
  }


  return (
    <>
      <div className="bg-lightBg pb-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="">
            <div className="bg-white">
              <div className="md:px-8 py-10">
                <MFDetailCard fundInfo={fundInfo} />
                <div className="my-5">
                  <div className="flex justify-between items-center">
                    <h6
                      className="font-medium text-xl mb-5 mt-10"
                      style={{ color: colors.darkGrey }}
                    >
                      Scheme Details
                    </h6>
                  </div>
                  <div className="flex flex-col md:flex-row text-start justify-start text-sm md:text-lg gap-2 mt-5">
                    <p>Purchase mode:</p>
                    {!fundInfo?.currentValue?(["New investment"].map(renderRadio)):(["Existing investment", "New investment"].map(renderRadio))}
                  </div>

                  <Formik
                    innerRef={sipRef}
                    initialValues={sipValues}
                    validationSchema={sipSchema}
                    onSubmit={async (values) => {
                      handleOpenDialog();
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleSubmit,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit} className="mt-5">
                        {investmentPlan === "Existing investment" && (
                          <div className="flex flex-row w-full gap-5 mt-10">
                            <div className="w-full sm:w-3/4 mb-4 sm:mb-0 ">
                              <FormInput
                                label="Folio Number"
                                name="folioNo"
                                type="text"
                                onChange={handleChange("folioNo")}
                                value={values.folioNo}
                                id="folioNo"
                                disabled={true}
                              />
                            </div>
                          </div>
                        )}
                        <div className="flex justify-between items-center">
                          <h6
                            className="font-medium text-xl mb-5 mt-10"
                            style={{ color: colors.darkGrey }}
                          >
                            SIP Details
                          </h6>
                        </div>
                        <div className="flex flex-row w-full gap-5">
                          <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                            <FormSelect
                              label="SIP Frequency"
                              name="frequency"
                              options={sipFrequencies}
                              onChange={(e: any) => {
                                setFieldValue(
                                  "frequency",
                                  parseInt(e.target.value)
                                );
                                onFrequencyChange(e.target.value);
                                calculateEndDate(
                                  parseInt(e.target.value),
                                  values.startDate,
                                  values.noOfInstallment
                                );
                              }}
                              value={values.frequency}
                              error={errors.frequency}
                              touched={touched.frequency}
                            />
                          </div>
                        </div>
                        <div className="flex flex-row w-full gap-5">
                          <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                            <FormDatePicker
                              label="SIP Start Date"
                              name="startDate"
                              onChange={(date) => {
                                const formattedDate = date
                                  ? date.format("DD/MM/YYYY")
                                  : "";
                                setFieldValue("startDate", formattedDate);
                                calculateEndDate(
                                  values.frequency,
                                  formattedDate,
                                  values.noOfInstallment
                                );
                              }}
                              value={
                                values.startDate
                                  ? dayjs(values.startDate, "DD-MM-YYYY")
                                  : null
                              }
                              defaultValue={dayjs(new Date())}
                              error={errors.startDate}
                              touched={touched.startDate}
                              maxDate={dayjs().add(1, "year")}
                              hintText={
                                sipDates &&
                                `Suggested SIP start dates: ${sipDates}`
                              }
                            />
                          </div>
                          <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                            {values.frequency !== 1 && (
                              <FormInput
                                label="No. of installments"
                                name="noOfInstallment"
                                type="number"
                                onChange={(e: any) => {
                                  let value = e.target.value;
                                  // Remove leading zero when user starts typing
                                  if (
                                    value.startsWith("0") &&
                                    value.length > 1
                                  ) {
                                    value = value.replace(/^0+/, ""); // Remove leading zeros
                                  }
                                  setFieldValue(
                                    "noOfInstallment",
                                    value
                                  );
                                  calculateEndDate(
                                    values.frequency,
                                    values.startDate,
                                    parseInt(value)
                                  );
                                }}
                                value={values.noOfInstallment}
                                error={errors.noOfInstallment}
                                touched={touched.noOfInstallment}
                              />
                            )}
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <h6
                            className="font-medium text-xl mb-5 "
                            style={{ color: colors.darkGrey }}
                          >
                            OR Select recommended amount
                          </h6>
                        </div>
                        <AmountButtons
                          label="Amount"
                          setFieldValue={setFieldValue}
                          selectedAmount={selectedAmount}
                          setSelectedAmount={setSelectedAmount}
                          fieldName="amount"
                          values={values}
                          errors={errors}
                          touched={touched}
                        />
                        {/* <div className="flex flex-row w-full gap-5">
                          <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                            <h6
                              className="font-medium text-xl mt-10 mb-3"
                              style={{ color: colors.darkGrey }}
                            >
                              Mode Of Payment
                            </h6>
                            <label className="flex items-center">
                              <input
                                type="radio"
                                checked={true}
                                name="modeOfPayment"
                                className="mr-2"
                              />
                              Autopay
                            </label>
                          </div>
                          <div className="w-full sm:w-3/4 mb-4 sm:mb-0">
                            <h6
                              className="font-medium text-xl mt-10 mb-3"
                              style={{ color: colors.darkGrey }}
                            >
                              SIP Step Up (in %)
                            </h6>
                            <QuantityInput />
                          </div>
                        </div> */}
                        {/* <div className="w-full my-10">
                          <MFFooterCard
                            onBankChange={handleChange("bankId")}
                            bankValue={values.bankId}
                            bankError={errors.bankId}
                            bankTouched={touched.bankId}
                          />
                        </div> */}
                        <Alert
                          variant="outlined"
                          severity="info"
                          sx={{
                            color: colors.textGrey,
                            border: "1px solid",
                            backgroundColor: colors.bgGrey,
                          }}
                        >
                          Day of Debit to investor’s account: T day <br />
                          Day of Unit Allotment: T + 1 day <br />
                          Cut-off time: 3:00 PM
                        </Alert>

                        <section className="mt-10 mb-5 max-md:mt-5 max-md:mr-2.5 max-md:max-w-full">
                          <div className="flex gap-5 max-md:flex-col max-md:gap-0">
                            <div className="flex flex-col max-md:ml-0 max-md:w-full">
                              <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
                                <div className="flex gap-5 justify-between lg:mt-2 text-sm max-md:mt-2 lg:mb-5">
                                  <Button
                                    type="submit"
                                    className="grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary"
                                    tabIndex={0}
                                  >
                                    Invest
                                    {/* Proceed to payment */}
                                  </Button>
                                  <Button
                                    type="button"
                                    className={`grow justify-center px-8 py-4 rounded-md border-3 border-darkGrey bg-darkGrey text-white border-solid hover:bg-primary`}
                                    onClick={showingInterest}
                                  >
                                    Show Interest
                                    {/* Proceed to payment */}
                                  </Button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <APIloader loadingModal={loading} message={loadmessage} />
      <Dialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50"
                  >
                    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                      <div className="bg-white p-8 rounded-lg shadow-lg w-3/4 max-w-2xl relative flex flex-col">
                        <Typography variant="h4" className="font-bold mb-4">
                          Summary
                        </Typography>
                        <DialogContent className="flex flex-col">
                          {summaryData ? (
                            <>
                              <div className="flex justify-between mb-4">
                                <span className="font-semibold text-gray-700">
                                  Purchase Mode:
                                </span>
                                <span>{summaryData.PurchaseMode}</span>
                              </div>
                              <div className="flex justify-between mb-4">
                                <span className="font-semibold text-gray-700">
                                  Purchase Date:
                                </span>
                                <span>{summaryData.Date}</span>
                              </div>
                              <div className="flex justify-between mb-4">
                                <span className="font-semibold text-gray-700">
                                  Frequency:
                                </span>
                                <span>{summaryData.Frequency}</span>
                              </div>
                              <div className="flex justify-between mb-4">
                                <span className="font-semibold text-gray-700">
                                  Installments:
                                </span>
                                <span>{summaryData.NoOfInstallments}</span>
                              </div>
                              <div className="flex justify-between mb-4">
                                <span className="font-semibold text-gray-700">
                                  Amount:
                                </span>
                                <span>Rs. {summaryData.Amount}</span>
                              </div>
                              <div className="flex justify-between mb-4">
                                <span className="font-semibold text-gray-700">
                                  Approx. Units:
                                </span>
                                <span>{summaryData.Units}</span>
                              </div>
                            </>
                          ) : (
                            <div className="flex justify-center items-center">
                              <span>Loading...</span>
                            </div>
                          )}
                        </DialogContent>
                        <DialogActions>
                          <Btn
                            onClick={handleConfirmDialog}
                            color="primary"
                            sx={{
                              backgroundColor: colors.primary,
                              borderRadius: "40px",
                              color: colors.lightBg,
                              padding: "10px",
                              "&:hover": {
                                backgroundColor: colors.primary,
                                color: colors.lightBg,
                              },
                            }}
                          >
                            Confirm
                          </Btn>
                          <Btn
                            onClick={handleCloseDialog}
                            sx={{
                              backgroundColor: colors.primary,
                              borderRadius: "40px",
                              color: colors.lightBg,
                              padding: "10px",
                              "&:hover": {
                                backgroundColor: colors.primary,
                                color: colors.lightBg,
                              },
                            }}
                          >
                            Cancel
                          </Btn>
                        </DialogActions>
                      </div>
                    </div>
                  </Dialog>
                  <SuccessfullModal
                    open={Open}
                    message={message !== "" ? message : errormessage}
                    handleClose={handleClose}
                  />
                  <CustomDialog
        open={Open}
        errormessage={message!==""?message:errormessage}
        button={button}
        handleDialogClose={handleCloseDialog}
        handleClose={handleClose}
        openAuthLink={button ? undefined : openAuthLink}
        openPaymentTab={button ?openPaymentTab :undefined}
      />
      <SuccessfullModal open={InterestOpen} message={message!==""?message:errormessage} handleClose={handleInterestClose} />
        <Dialog open={authModalOpen} onClose={handleAuthModalClose}>
  <DialogContent>
    <Typography>
      Click below to complete your authorization on the BSE STAR MF platform and also close the tab after autherized yourself.
    </Typography>
  </DialogContent>
  <DialogActions>
    <Btn onClick={openAuthLink} sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}>
      Open Authorization Link
    </Btn>
    <Btn onClick={handleAuthModalClose} sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}>
      Close
    </Btn>
  </DialogActions>
        </Dialog>
        <Dialog open={success} onClose={handleCloseSuccessModal}>
  <DialogContent>
    <Typography>
      {message!==""?message:errormessage}
    </Typography>
  </DialogContent>
  <DialogActions>
    <Btn onClick={handleCloseSuccessModal} sx={{
                  backgroundColor: colors.primary,
                  borderRadius: "40px",
                  color: colors.lightBg,
                  padding: "10px",
                  "&:hover": {
                    backgroundColor: colors.primary,
                    color: colors.lightBg,
                  },
                }}>
      OK
    </Btn>
  </DialogActions>
        </Dialog>
    </>
  );
};

export default SIP;
