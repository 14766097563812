import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { useUserData } from "../hooks/useUserData";
import { useDispatch } from "react-redux";
import { getUserById } from "../redux/user/userSlice";
import OnboardingRoutes from "./OnboardingRoutes";
import VerificationPending from "../pages/VerificationPending/VerificationPending";
import MainNonIndividual from "../pages/NonIndividualPages/Main/MainNonIndividual";
import EntityPage from "../pages/EntityPage/EntityPage";

const PrivateRoutes = () => {
  const { accessToken, refreshToken } = useAuth();
  const location = useLocation();
  const dispatch = useDispatch<any>();
  const userData = useUserData();
  useEffect(() => {
    if (accessToken && refreshToken && !userData) {
      dispatch(getUserById({}));
    }
  }, [userData]);
  // if (accessToken && !refreshToken) {
  //   console.log(location.pathname, "pathname");
  //   if (
  //     location.pathname === "/onboarding" ||
  //     location.pathname === "/entity" ||
  //     location.pathname === "nonIndividual"
  //   ) {
  //     return <Outlet />;
  //   }
  // }
  if (!accessToken || !refreshToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  if (userData?.userType === 0) {
    return <Navigate to="/entity" state={{ from: location }} replace />;
  }
  if (userData?.onboardStatus === 1 && !userData?.registeredByAdmin && userData?.userType === 1) {
    return <OnboardingRoutes />;
  } 
  if (userData?.onboardStatus === 1 && userData?.userType === 5) {
    return <OnboardingRoutes />;
  }
  return <Outlet />;
};

export default PrivateRoutes;
