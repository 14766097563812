import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FormInput } from "../../../components/FormInput/FormInput";
import { colors } from "../../../constants/colors";
import FileInput from "../../../components/FileInput/FileInput";
import * as yup from "yup";
import FormDatePicker from "../../../components/FormDatePicker/FormDatePicker";
import dayjs from "dayjs";
import { Button } from "../../../components";
import ConfirmationModal from "../../../components/modals/ConfirmationModal2";
import styles from "../Individual.module.css";
import file from "../../../assets/icons/file.png";
import person from "../../../assets/icons/f7_person-2 blue.png";
import ZeroDocKyc from "../ZeroDocKyc/ZeroDocKyc";
import ManualKyc from "../ManualKyc/ManualKyc";
import { CheckCircle } from "@mui/icons-material";
import CancelIcon from '@mui/icons-material/Cancel';
import VerifyButton from "../../../components/VerifyButton/VerifyButton";
import { hideLoading, showLoading } from "../../../redux/loader/loaderSlice";
import {
  aadharVerification,
  aadharVerificationOTP,
} from "../../../redux/verification_aadhar&pan/verificationSlice";
import { useDispatch } from "react-redux";
import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { getUserById, updateUser } from "../../../redux/user/userSlice";
import { addKYC, skipAdhar } from "../../../redux/kyc/kycSlice";

const aadharValues = {
  aadharNumber: "",
  aadharMobileNumber: "",
  fullName: "",
};

const aadharSchema = yup.object({
  aadharNumber: yup
    .string()
    .required("Aadhar Number is required")
    .max(12, "Aadhar Number should be 12 digit")
    .min(12, "Aadhar Number should be 12 digit")
    .matches(/^([0-9_.])+$/, "Must contain only number"),
  // aadharMobileNumber: yup
  //   .string()
  //   .required("Mobile Number registered with Aadhar Number is required")
  //   .max(13, "Mobile Number should be 10 digit")
  //   .min(13, "Mobile Number should be 10 digit")
  //   .matches(/^([0-9_.+])+$/, "Must contain only number"),
  // aadharNumber: yup
  //   .string()
  //   .required("PAN Card Number is required")
  //   .matches(/^([A-Z]{5}[0-9]{4}[A-Z]{1})+$/, "Enter Valid PAN Number"),
  aadharMobileNumber: yup
    .string()
    .required("Mobile Number registered with Pan Card is required")
    .max(13, "Mobile Number should be 10 digit")
    .min(13, "Mobile Number should be 10 digit")
    .matches(/^([0-9_.+])+$/, "Must contain only number"),
  fullName: yup.string().required(),
});

const AadharVerification = ({ onProceed, userData, activeSection }: any) => {
  const dispatch = useDispatch<any>();
  const [verifiedDetails, setVerifiedDetails] = useState({
    aadharCard: "",
    aadharNumber: "",
    aadharMobileNumber: "",
    isAadharVerified: false,
  });
  const [isAadharVerified, setIsAadharVerified] = useState(false);
  const [aadharCard, setAadharCard] = useState<File>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const formikRef = useRef<any>(null);

  const [selectedOption, setSelectedOption] = useState<string>("zeroDoc");
  const [isFieldDisabled, setIsFieldDisabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [aadharDetails, setAadharDetails] = useState({
    otp: "",
    aadharReferenceId: "",
  });

  useEffect(() => {
      window.scrollTo(0, 0);
    dispatch(getUserById({}));
  }, [dispatch]);

  useEffect(() => {
    if (userData?.firstName) {
      formikRef.current?.setFieldValue("fullName", userData?.firstName);
    }
    if (Object.keys(userData?.kyc).length > 0 && userData?.kyc?.aadharCard) {
      setVerifiedDetails({
        aadharCard: userData?.kyc?.aadharCard,
        aadharNumber: userData?.kyc?.aadharNumber?.slice(-4),
        aadharMobileNumber: userData?.kyc?.aadharMobileNumber?.slice(-4),
        isAadharVerified: !!userData?.kyc?.isAadharVerified,
      });
      setIsFieldDisabled(true);
    }
  }, [userData && formikRef.current]);

  const verifyAadharOTP = async () => {
    try {
      if (!aadharDetails.otp) {
        toast.error("Please enter OTP");
        return;
      }
      const payload = {
        otp: aadharDetails.otp,
        aadharReferenceId: aadharDetails.aadharReferenceId,
        fullName: userData?.firstName,
        dateOfBirth: dayjs(userData?.dateOfBirth)
          .format("DD/MM/YYYY")
          .split("/")
          .join("-"),
      };
      dispatch(showLoading());
      // const response = await dispatch(aadharVerification(payload));
      // if (response?.error?.message) {
      //   toast.error("OTP verification failed");
      //   return;
      // }
      toast.success("OTP verified successfully");
      setIsAadharVerified(true);
      setShowModal(false);
    } catch (error) {
      console.error("Error verifying OTP:", error);
    } finally {
      dispatch(hideLoading());
    }
  };

  const boxStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const handleRadioChange = (option: string) => {
    setSelectedOption(option);
  };

  const checkAlreadySubmitted = () => {
    if (Object.keys(userData?.kyc).length > 0 && userData?.kyc?.aadharCard) {
      onProceed();
    } else {
      handleSaveAndProceed();
    }
  };

  const handleModalConfirm = () => {
    setIsModalVisible(false); // Hide the modal after confirmation
    if (formikRef.current) {
      formikRef.current.handleSubmit(); // Submit the form upon confirmation
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false); // Hide the modal if user cancels
  };

  const handleSaveAndProceed = () => {
    // generalDetailsRef.current?.handleSubmit();
    //setIsModalVisible(true); // Show the modal when the button is clicked
    // added for modal window to only come after validation -CH22/5
    if (formikRef.current) {
      formikRef.current?.handleSubmit(); // Submit the form upon confirmation
      if (formikRef.current.isValid) {
        setIsModalVisible(true); // Show the modal when the button is clicked
      }
    }
  };

  const handleVerifyAadhar = async () => {
    try {
      const aadharNumber = formikRef.current?.values?.aadharNumber;
      const aadharMobileNumber = formikRef.current?.values?.aadharMobileNumber;
      if (!aadharNumber || !aadharMobileNumber || !aadharCard) {
        toast.error("Please enter all required details");
        return;
      }
      //added validation for verify button
      //aadharSchema.isValid(formikRef).then(valid => {
      //toast.error("Please enter correct details");
      //});
      if (!formikRef.current.isValid) {
        toast.error("Please enter correct details");
        return;
      }
      await dispatch(showLoading());
      await dispatch(aadharVerificationOTP(aadharNumber))
        .then((response: any) => {
          const { aadharReferenceId } = response.payload;
          // console.log("aadharReferenceId", userDetails);
          setAadharDetails({
            ...aadharDetails,
            otp: "",
            aadharReferenceId,
          });
          setShowModal(true);
          // Reset the OTP verification state
        })
        .catch((error: any) => {
          console.error("API Error:", error);
        });
    } catch (err) {
      console.error("Error:", err);
    } finally {
      await dispatch(hideLoading());
    }
  };

  const SkipAadhar = async () => {
    try {
      let data = { kycId: userData?.kyc?.id }
      // console.log('Skip Aadhar trigger', data);

      const res = await dispatch(skipAdhar(data));
      if (res.payload?.statusCode === 200) {
        onProceed()
      } else {
        toast.error("Refresh page ")
      }
    } catch (error) {

    }
  }

  return (
    <>
      {userData?.kyc?.skipAadhaar ? (
        <div className="flex flex-col gap-5 items-center mt-8">
          <CancelIcon className="text-red-700  text-3xl" fontSize="large" />
          <p className="text-lg font-semibold font-inter">
            Aadhar verification was skipped.
          </p>
          <Button
            type="button"
            className={`px-8 py-4 rounded-md bg-darkGrey text-white hover:bg-primary transition-all max-w-[250px] w-full mt-12`}
            tabIndex={0}
            onClick={onProceed}
          //onClick={() => updateUserRef.current.click()} // Add onClick event handler
          // Disable button if radio is not selected
          >
            Proceed
          </Button>
        </div>)

        : (<div className="flex flex-col w-full max-w-screen-xl my-8">
          <div className="w-full max-w-[1440px] mx-auto ">
            {userData?.kyc?.isZeroDoc === 1 ? (
              <>
                <div className="flex flex-col gap-5 items-center">
                  <CheckCircle
                    className="text-green-600 text-3xl"
                    fontSize="large"
                  />
                  <p className="text-lg font-semibold font-inter">
                    Your ZeroKyc is already verified. Please proceed further.
                  </p>
                  <Button
                    type="button"
                    className={`px-8 py-4 rounded-md bg-darkGrey text-white hover:bg-primary transition-all max-w-[250px] w-full mt-12`}
                    tabIndex={0}
                    onClick={onProceed}
                  //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                  // Disable button if radio is not selected
                  >
                    Proceed
                  </Button>
                </div>
              </>
            ) : (
              <>
                <main
                  className={`flex flex-col lg:flex-row sm:gap-7 md:ml-10 md:mr-20 mb-24 max-md:mt-10 max-w-full ${styles.zeroManual}`}
                >
                  {/* Zero Doc KYC */}
                  <div className=" flex justify-center">
                    {" "}
                    {/* Center the radio button */}
                    <input
                      type="radio"
                      id="zeroDocKYC"
                      name="kycOption"
                      value="zeroDoc"
                      checked={selectedOption === "zeroDoc"}
                      onChange={() => handleRadioChange("zeroDoc")}
                      className="mt-9 sm:w-full mb-4"
                      disabled={isFieldDisabled}
                    />
                  </div>
                  <label className="sm:w-full max-w-[500px]" htmlFor="zeroDocKYC">
                    <div
                      className={`px-6 py-3.5 bg-white rounded-lg border border-solid border-stone-300 max-md:max-w-full flex flex-col md:flex-row gap-5 items-center`}
                      style={{ backgroundColor: colors.white }}
                    >
                      <img
                        src={`${file}`}
                        alt="KYC Verification Icon"
                        className="w-16 h-16 sm:w-auto sm:h-auto flex-shrink-0"
                        style={{ maxWidth: "4rem", maxHeight: "4rem", margin: 0 }}
                      />
                      <div className="flex flex-col flex-grow self-stretch">
                        <h2
                          className="text-base font-bold text-cyan-800 whitespace-normal"
                          style={{ color: colors.darkGrey }}
                        >
                          Zero Doc KYC
                        </h2>
                        <p
                          className={`mt-2 leading-5  sm:text-base text-xs`}
                          style={{ color: colors.darkGrey }}
                        >
                          You must have a valid Aadhaar number linked with your
                          mobile number.
                        </p>
                      </div>
                    </div>
                  </label>
                  {/* <div className="pl-24"></div>
            <div className=" flex justify-center">
              <input
                type="radio"
                id="manualKYC"
                name="kycOption"
                value="manual"
                checked={selectedOption === "manual"}
                onChange={() => handleRadioChange("manual")}
                className="mt-9 sm:w-full mb-4"
                disabled={isFieldDisabled}
              />
            </div>
            <label className="sm:w-full" htmlFor="manualKYC">
              <div
                className={`px-6 py-3.5 bg-white rounded-lg border border-solid border-stone-300 max-md:max-w-full flex flex-col md:flex-row gap-5 items-center`}
                style={{ backgroundColor: colors.white }}
              >
                <img
                  src={`${person}`}
                  alt="KYC Verification Icon"
                  className="w-16 h-16 sm:w-auto sm:h-auto flex-shrink-0"
                  style={{ maxWidth: "4rem", maxHeight: "4rem", margin: 0 }}
                />
                <div className="flex flex-col flex-grow self-stretch ">
                  <h2
                    className="text-base font-bold whitespace-nowrap"
                    style={{ color: colors.darkGrey }}
                  >
                    Manual KYC
                  </h2>
                  <p
                    className="mt-2 leading-5 sm:text-base text-xs"
                    style={{ color: colors.darkGrey }}
                  >
                    Valid ID proof options include Aadhaar and PAN card.
                  </p>
                </div>
              </div>
            </label> */}
                </main>

                {/* Render corresponding KYC form based on selected radio option */}
                {selectedOption === "zeroDoc" && (
                  <ZeroDocKyc
                    onProceed={SkipAadhar}
                    userData={userData}
                    activeSection={activeSection}
                  />
                )}
                {selectedOption === "manual" && (
                  <div className="flex flex-col text-base  sm:text-xl md:pl-2 md:pr-20 lg:pl-28 lg:pr-28">
                    {" "}
                    {/* Adjusted text size */}
                    {/* Inputs for screens above 900px */}
                    <Formik
                      innerRef={formikRef}
                      initialValues={aadharValues}
                      validationSchema={aadharSchema}
                      onSubmit={(values, { setSubmitting }) => {
                        if (!isAadharVerified) {
                          toast.error(
                            "Please verify your Aadhar Details by clicking 'Verify' in the mobile number field"
                          );
                          return;
                        }
                        if (aadharCard) {
                          const formData = new FormData();
                          console.log(values, "values");
                          formData.append("aadharNumber", values.aadharNumber);
                          formData.append(
                            "aadharMobileNumber",
                            values.aadharMobileNumber
                          );
                          formData.append("id", userData?.kyc?.id);
                          formData.append("aadhar", aadharCard);
                          formData.append("forPan", "0");
                          formData.append("isNominee", "0");
                          dispatch(addKYC(formData));
                          onProceed();
                        }
                        // }
                      }}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        isValid,

                        /* and other goodies */
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                          className="w-full flex flex-col gap-4"
                        >
                          <div className="flex flex-row flex-wrap gap-8 justify-center">
                            <div className="w-full max-w-[500px]">
                              <FormInput
                                label="Aadhaar Number *"
                                type="text"
                                name="aadharNumber"
                                onChange={(e: any) => {
                                  // setAadharData({
                                  //   ...aadharData,
                                  //   aadharNumber: e.target.value,
                                  // });
                                  setFieldValue("aadharNumber", e.target.value);
                                }}
                                value={values.aadharNumber}
                                placeholder={
                                  verifiedDetails?.aadharNumber
                                    ? `**** **** ${verifiedDetails?.aadharNumber}`
                                    : ""
                                }
                                disabled={
                                  !!verifiedDetails?.aadharNumber ||
                                  isAadharVerified
                                }
                                error={errors.aadharNumber}
                                touched={touched.aadharNumber}
                              />
                            </div>
                            {!verifiedDetails.aadharCard && (
                              <div className="w-full max-w-[500px]">
                                <FileInput
                                  label="Upload Aadhaar Copy *"
                                  onChange={(e: any) =>
                                    setAadharCard(e.target.files[0])
                                  }
                                  disabled={
                                    !!verifiedDetails?.aadharCard ||
                                    isAadharVerified
                                  }
                                  error={!aadharCard ? "Upload Aadhaar Card" : ""}
                                  touched={touched.aadharMobileNumber}
                                />
                              </div>
                            )}
                            {verifiedDetails.aadharCard && (
                              <div className="w-full max-w-[500px]"></div>
                            )}
                            <div className="w-full max-w-[500px]">
                              <FormInput
                                label="Mobile Number Registered with Aadhaar *"
                                type="phone"
                                name="aadharMobileNumber"
                                onChange={(e: any) => {
                                  setFieldValue("aadharMobileNumber", e);
                                }}
                                value={values.aadharMobileNumber}
                                suffix={
                                  <VerifyButton
                                    isVerified={
                                      verifiedDetails.isAadharVerified ||
                                      isAadharVerified
                                    }
                                    onClick={handleVerifyAadhar}
                                  />
                                }
                                placeholder={
                                  verifiedDetails?.aadharMobileNumber
                                    ? `**** **** ${verifiedDetails?.aadharMobileNumber}`
                                    : ""
                                }
                                disabled={
                                  !!verifiedDetails?.aadharMobileNumber ||
                                  isAadharVerified
                                }
                                error={errors.aadharMobileNumber}
                                touched={touched.aadharMobileNumber}
                              />
                            </div>
                            <div className="w-full max-w-[500px]">
                              <FormInput
                                label="Full Name as per Aadhar *"
                                type="text"
                                name="fullName"
                                onChange={handleChange("fullName")}
                                value={values.fullName}
                                error={errors.fullName}
                                disabled={true}
                                touched={touched.fullName}
                              />
                            </div>
                          </div>
                          <div className="pl-8">
                            <Button
                              type="button"
                              className={`px-8 py-4 rounded-md bg-darkGrey text-white hover:bg-primary transition-all max-w-[250px] w-full mt-12`}
                              tabIndex={0}
                              onClick={checkAlreadySubmitted}
                            //onClick={() => updateUserRef.current.click()} // Add onClick event handler
                            // Disable button if radio is not selected
                            >
                              Save and Proceed
                            </Button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                )}
              </>
            )}
          </div>
          <ConfirmationModal
            isVisible={isModalVisible} // Render conditionally based on state
            message="Are you sure all fields are correct and verified? After you proceed to the next screen, fields cannot be edited again."
            onConfirm={handleModalConfirm} // Confirm action and submit form
            onCancel={handleModalCancel} // Cancel action and close modal
          />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={showModal}
            onClose={() => setShowModal(false)}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{ backdrop: { timeout: 500 } }}
          >
            <Fade in={showModal}>
              <Box sx={boxStyle}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Enter Aadhar OTP
                </Typography>
                <div
                  id="transition-modal-description"
                  className=" flex gap-x-3 py-5"
                >
                  {/* Input field for OTP */}
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    className="border border-solid border-lightGrey p-3 rounded-md"
                    value={aadharDetails.otp}
                    onChange={(e) =>
                      setAadharDetails({ ...aadharDetails, otp: e.target.value })
                    }
                  />
                  {/* Button for verifying OTP */}
                  <button
                    type="button"
                    className="text-orange font-bold text-lg"
                    onClick={verifyAadharOTP}
                  >
                    Verify OTP
                  </button>
                </div>
              </Box>
            </Fade>
          </Modal>
        </div>)}
    </>
  );
};

export default AadharVerification;
