import api from "../../config/apiConfig";

const addOrganizationFatca = async (data: any) => {
    const accessToken = localStorage.getItem("accessToken");
    const response = await api.post("org/addFatca", data, {
        headers: {
            Authorization: accessToken,
        },
    });
    return response.data;
};

const companyService = { addOrganizationFatca };
export default companyService;