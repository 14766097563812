import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Fade,
  IconButton,
  ListItem,
  Menu,
  MenuItem,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { InfoOutlined, MoreVert } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { colors } from "../../constants/colors";
import { Dots } from "react-activity";
import {
  addToWatchlist,
  removeToWatchlist,
} from "../../redux/wishlists/wishlistSlice";
import toast from "react-hot-toast";
import SortingComponent from "../ShortingFN/ShortingComponent";
import { useUserData } from "../../hooks/useUserData";

const Row = ({
  row,
  navigateDetails,
  setIsinWishilist,
  isinWishilist,
  openInvestModal,
  selectedFunds,
  SelectedAssets,
}: {
  row: any;
  navigateDetails: (isin: string, id: number) => void;
  isinWishilist: any;
  setIsinWishilist: any;
  openInvestModal: any;
  fetchData: () => void;
  selectedFunds: any;
  SelectedAssets: any;
}) => {
  const labelId = `enhanced-table-checkbox-${row.id}`;
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorMD, setAnchorMD] = React.useState<null | HTMLElement>(null);

  const openMenuMD = Boolean(anchorMD);

  const modalMenuClick = (
    event: React.MouseEvent<HTMLElement>,
    isinWishilist: number,
    id: number
  ) => {
    setAnchorMD(event.currentTarget);
    let data = { status: isinWishilist, id: id };
    setIsinWishilist(data);
  };

  const dispatch = useDispatch<any>();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseMD = () => {
    setAnchorMD(null);
  };

  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(
    null
  );
  const isPopoverOpen = Boolean(popoverAnchorEl);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopoverAnchorEl(event.currentTarget); // Correct event type
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null); // Reset
  };

  // Function to get the truncated text
  const truncateText = (text: string | any[], length: number) => {
    if (text.length > length) {
      return `${text.slice(0, length)}...`;
    }
    return text;
  };

  const Watchlist = async () => {
    if (!isinWishilist) return;

    if (isinWishilist.status === 1) {
      const data = { itemId: isinWishilist.id, itemType: 2 };
      const response = await dispatch(removeToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 0 });
        toast.success("Removed from watchlist");
      } else {
        toast.error("Failed to remove from watchlist");
      }
    } else if (isinWishilist.status === 0) {
      const data = { itemId: isinWishilist.id, itemType: 2 };
      const response = await dispatch(addToWatchlist(data));
      if (response.payload) {
        setIsinWishilist({ ...isinWishilist, status: 1 });
        toast.success("Added to watchlist");
      } else {
        toast.error("Failed to add to watchlist");
      }
    }
  };

  const StringRemover = (title: string) => {
    let titleLength = title.length;
    return titleLength > 6 ? title.slice(0, titleLength - 6) : title;
  };

  const isSelected = (fundId: any) => {
    const selected = Object.values(SelectedAssets).find(
      (f: any) => f?.fund?.id === fundId
    );
    return !!selected;
  };
  const user = useUserData();
  const checkMF = user?.preferences?.mutualFunds;
  console.log(user?.preferences?.mutualFunds, "MF Details page");

  return (
    <>
      <TableRow
        key={row.id}
        sx={{ borderRadius: "50px!important" }}
        className=" border-[1px] border-lightGrey hover:border-primary hover:border-[1px] hover:bg-lightBg cursor-pointer" 
      >
        <TableCell padding="checkbox" sx={{ border: "none",fontSize:{
                    xs:"14px",
                    sm:"16px"
                  } }}>
          <Checkbox
            color="primary"
            checked={isSelected(row.id)}
            onChange={() => selectedFunds(row)}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }}>
                    <a
            href={`/mf-details/${row.isin}/${row.id}`}
            target="_self"
            style={{
              textDecoration: "none",
              color: "inherit",
              display: "flex",
              alignItems: "center",
              gap: "8px",
            }}
          >
            {row.schemeName}
          </a>
          {/* <div className="flex items-center gap-x-2 cursor-pointer" onClick={() => navigateDetails(row.isin, row.id)}>
            <span>
              {row.schemeName}
            </span>
          </div> */}
        </TableCell>
        <TableCell align="left" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          {StringRemover(row.fundSize)}
        </TableCell>
        <TableCell align="left" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          Rs. {row.currentNAV}
        </TableCell>
        <TableCell align="left" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          {row.returnRate1Yrs
            ? `${Number(row.returnRate1Yrs).toFixed(2)}  % `
            : "-"}
        </TableCell>
        <TableCell align="center" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          {row.expenseRatio ? `${Number(row.expenseRatio).toFixed(2)} %` : "-"}
        </TableCell>

        <TableCell align="right" sx={{ minWidth: 200, border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          <Tooltip title="" arrow>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: "100px",
              }}
            >
              {truncateText(row.exitLoad, 15)}
            </span>
          </Tooltip>

          <IconButton
            onClick={handlePopoverOpen}
            size="small"
            sx={{ padding: "0 5px" }}
          >
            <InfoOutlined fontSize="small" />
          </IconButton>

          <Popover
            open={isPopoverOpen}
            anchorEl={popoverAnchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
          >
            <Box p={2} sx={{ maxWidth: 300 }}>
              {row.exitLoad}
            </Box>
          </Popover>
        </TableCell>

        <TableCell align="center" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          {row.mfRank}
        </TableCell>
        <TableCell align="left" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          {row.riskLevel}
        </TableCell>
        <TableCell align="right" sx={{ border: "none",fontSize:{
                    xs:"12px",
                    sm:"16px"
                  } }} onClick={() => navigateDetails(row.isin, row.id)}>
          <IconButton
            onClick={(e) => {
              modalMenuClick(e, row?.isInWishlist, row?.id);
            }}
          >
            <MoreVert />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableCell
        className="tableCells"
        colSpan={12}
        sx={{
          padding: "5px",
          "&.MuiTableCell-root": {
            borderBottom: "none",
          },
        }}
      ></TableCell>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorMD}
        open={openMenuMD}
        onClose={handleCloseMD}
        TransitionComponent={Fade}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            backgroundColor: "#255288",
            color: "#fff",
            overflow: "hidden",
            borderRadius: "10px",
            position: "relative",
            width: "180px",
          },
        }}
        className="relative"
      >
        <div className="mx-6">
          <ListItem button onClick={handleClose} sx={{ marginLeft: "-25px" }}>
            <MenuItem className="text-xs sm:text-sm" onClick={Watchlist}>
              {isinWishilist?.status === 1
                ? "Remove from watchlist"
                : "Add to Watchlist"}
            </MenuItem>
          </ListItem>
          <Divider
            sx={{
              backgroundColor: "#fff",
            }}
          />
          <ListItem
            button
            sx={{ marginLeft: "-25px",opacity: checkMF === 1 ? 1 : 0.5,
              cursor: checkMF === 1 ? "pointer" : "not-allowed", }}
              onClick={checkMF === 1 ? openInvestModal : () => {}}
          >
            <MenuItem sx={{opacity: checkMF === 1 ? 1 : 0.5,
              cursor: checkMF === 1 ? "pointer" : "not-allowed"}}>Invest</MenuItem>
          </ListItem>
        </div>
        <div
          className="absolute bg-white"
          style={{
            width: "20px",
            height: "20px",
            transform: "rotate(315deg)",
            bottom: "calc(100% - 10px)",
            left: "calc(50% - 10px)",
          }}
        />
      </Menu>
    </>
  );
};

const MFTable = ({
  MFData,
  loader,
  openInvestModal,
  setIsinWishilist,
  isinWishilist,
  findMutualFund,
  selectedFunds,
  SelectedAssets,
}: {
  MFData: any[];
  loader: boolean;
  openInvestModal: any;
  setIsinWishilist: any;
  isinWishilist: any;
  findMutualFund: any;
  selectedFunds: any;
  SelectedAssets: any;
}) => {
  const [sortedMFData, setSortedMFData] = useState(MFData);
  const navigate = useNavigate();

  useEffect(() => {
    setSortedMFData(MFData);
  }, [MFData]);

  const navigateDetails = (isin: string, id: number) => {
    navigate(`/mf-details/${isin}/${id}`);
  };
  if (loader) {
    return <div>Loading...</div>; // Display loading state while fetching data
  }

  if (MFData.length === 0) {
    return <div>No data available</div>;
  }
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", border: "none" }}
      >
        <Table aria-label="collapsible table">
          <TableHead
            sx={{
              backgroundColor: colors.lightBg,
              marginBottom: "50px",
              border: "none",
            }}
          >
            <TableRow sx={{ border: "none" }}>
              <TableCell
                align="left"
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
              ></TableCell>
              <TableCell
                align="left"
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
              >
                <span className="w-[40%]">
                  <SortingComponent
                    title="Asset Name"
                    data={MFData}
                    keyProp="schemeName"
                    type="string"
                    setMFdata={setSortedMFData}
                  />
                </span>
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <SortingComponent
                  title="AUM  (in Cr)"
                  data={MFData}
                  keyProp="fundSize"
                  type="number"
                  setMFdata={setSortedMFData}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <SortingComponent
                  title="Current NAV"
                  data={MFData}
                  keyProp="currentNAV"
                  type="number"
                  setMFdata={setSortedMFData}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <SortingComponent
                  title="Return Rate (1Y)"
                  data={MFData}
                  keyProp="returnRate1Yrs"
                  type="number"
                  setMFdata={setSortedMFData}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <SortingComponent
                  title="Expense Ratio"
                  data={MFData}
                  keyProp="expenseRatio"
                  type="number"
                  setMFdata={setSortedMFData}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="center"
              >
                Exit Load
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                MF Rank
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                <SortingComponent
                  title="Risk Level"
                  data={MFData}
                  keyProp="riskLevel"
                  type="number"
                  setMFdata={setSortedMFData}
                />
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 600,
                  color: colors.primary,
                  fontSize:{
                    xs:"14px",
                    sm:"16px"
                  }
                }}
                align="left"
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          {loader ? (
            <div className="flex justify-center items-center h-full">
              <div className="flex flex-col justify-center items-center">
                <Dots />
              </div>
            </div>
          ) : (
            <TableBody className="" sx={{}}>
              <TableRow sx={{ height: "5%", border: "none" }}>
                <TableCell
                  colSpan={8}
                  align="center"
                  sx={{ border: "none" }}
                ></TableCell>
              </TableRow>

              {sortedMFData.length > 0 ? (
                sortedMFData.map((row, index) => (
                  <Row
                    key={index}
                    row={row}
                    navigateDetails={navigateDetails}
                    setIsinWishilist={setIsinWishilist}
                    isinWishilist={isinWishilist}
                    openInvestModal={openInvestModal}
                    fetchData={findMutualFund}
                    selectedFunds={selectedFunds}
                    SelectedAssets={SelectedAssets}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} align="center">
                    No similar plans available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

export default MFTable;
